import React, { useState, useEffect } from 'react';
import { Table, Button, Descriptions, message, Modal, List, Form, Input, Menu, Dropdown } from 'antd'; // Import Button from 'antd'
import { useNavigate, Link } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';
import config from '../../config';
import CopyToClipboardButton from '../Button/CopyToClipBoardButton';

const base_url = config.base_url;
const { TextArea } = Input;

const ReadyToShipTableVendor = (params) => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [header, setHeader] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10); // Number of items per page
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowsData, setSelectedRowsData] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalSplitVisible, setModalSplitVisible] = useState(false);
    const [splitUID, setSplitUID] = useState(null);
    const [location, setLocation] = useState([]);
    const [form] = Form.useForm();
    const [packForm] = Form.useForm();

    useEffect(() => {
        fetchData();
    }, [currentPage, pageSize, params.vendor_uid, params.purchase_order_no, params.filter_purchase_group]);

    useEffect(() => {
        params.setCount(header)
    }, [header])

    useEffect(() => {
        handlePack();
    }, [params.pack_count])

    useEffect(() => {
        fetchLocation();
    }, [])

    const fetchData = async () => {
        try {
            setLoading(true);
            const skip = (currentPage - 1) * pageSize;
            const response = await axios.get(`${base_url}/delivery-schedule/pagination`, {
                params: {
                    skip,
                    limit: pageSize,
                    vendor_uid: params.vendor_uid,
                    purchase_order_no: params.purchase_order_no,
                    purchase_group: params.filter_purchase_group,
                    status: "Commit"
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    // Add other headers if needed
                },
            });
            setData(response.data.data);
            setTotalItems(response.data.total_count);
            setHeader(response.data.header);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const fetchLocation = async () => {
        try {
            const response = await axios.get(`${base_url}/shipment/recommend-location`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    // Add other headers if needed
                },
            });
            setLocation(response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handlePurchaseOrderClick = (uid) => {
        navigate(`/purchase-order/${uid}`);
    };

    function MyDropdown() {
        // Define a function to handle option selection
        const handleOptionSelect = (selectedOption) => {
            console.log(`Selected: ${selectedOption.key}`);
            console.log(location)
            packForm.setFieldsValue({
                pickup_location: selectedOption.key
            });
            // Perform any action you want when an option is selected
        };

        // Define the options for the dropdown menu
        const menu = (
            <Menu onClick={handleOptionSelect}>
                {location.length > 0 &&
                    location.map(loc => (
                        <Menu.Item key={loc} >{loc}</Menu.Item>
                    ))}
            </Menu>
        );

        return (
            <Dropdown disabled={location.length === 0} overlay={menu} trigger={['click']}>
                <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                    Choose Recent
                </a>
            </Dropdown>
        );
    }

    const handlePack = () => {
        if (selectedRows.length > 0) {
            setModalVisible(true);
        } else {
            if (params.pack_count > 0) {
                message.error('No item selected.');
            }
        }
    };

    const selectedItemsData = selectedRows.map((selectedUid) => {
        return selectedRowsData.find((item) => item.uid === selectedUid);
    });

    const packShipment = async (values) => {
        try {
            const delivery_schedule_uids = selectedRows;
            const response = await fetch(`${base_url}/shipment/pack-shipment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({ pickup_location: values.pickup_location, vendor_remark: values.vendor_remark , delivery_schedule_uids }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            // Clear the selected rows and close the modal
            setSelectedRows([]);
            setModalVisible(false);

            // Refresh the data
            // fetchData();
            // Route to New Page
            const responseData = await response.json();
            const uid = responseData.uid;
            navigate(`/shipment/${uid}`);

            message.success('Shipment packed successfully!');
        } catch (error) {
            console.error('Error:', error);
            message.error('There was an error packing the shipment');
        }
    };

    const showSplitModal = (uid) => {
        setModalSplitVisible(true);
        setSplitUID(uid);
    };
    const candelSplitModal = () => {
        setModalSplitVisible(false);
        setSplitUID(null);
    };

    const handleSplitSubmit = async (values) => {
        // Reset the form fields to their initial state

        try {
            const response = await axios.post(`${base_url}/delivery-schedule/split`, {
                uid: splitUID,
                quantity: values.quantity,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    // Add other headers if needed
                },
            });
            if (response.status === 200) {
                message.success('Successfully Split Item');
                fetchData();
            } else {
                message.error(JSON.stringify(response.data.detail));
                console.log(response.status)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            message.error(JSON.stringify(error.response.data.detail));
        }
        // Close the modal after form submission
        form.resetFields();
        setModalSplitVisible(false)
    };

    const validateQuantity = (rule, value) => {
        if (value === undefined || value === null) {
            return Promise.resolve(); // Allow empty field
        }

        const floatValue = parseFloat(value);

        if (isNaN(floatValue) || floatValue <= 0) {
            return Promise.reject('Quantity must be a valid positive number and greater than 0.');
        }

        return Promise.resolve();
    };


    const handlePaginationChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
        // setSelectedRows([]);
    };

    // const handleRowSelection = (selectedRowKeys, selectedRowsi) => {
    //     console.log("current selection", selectedRowKeys)

    //     const newSelectedRowKeys = [...selectedRowKeys];
    //     selectedRows.forEach((uid) => {
    //             newSelectedRowKeys.push(uid)
    //     })

    //     console.log("new selected row key", newSelectedRowKeys)

    //     setSelectedRows(selectedRowKeys);
    //     setSelectedRowsData(selectedRowsi);

    // };

    const handleRowSelection = (selectedRowData, isSelect) => {
        console.log(isSelect)
        if (isSelect) {
            const newSelectedRowKeys = [selectedRowData.uid, ...selectedRows];
            const newSelectedRowData = [selectedRowData, ...selectedRowsData];
            setSelectedRows(newSelectedRowKeys);
            setSelectedRowsData(newSelectedRowData);
        }
        else {
            const deselectedRowKey = selectedRowData.uid;
            const newSelectedRowKeys = selectedRows.filter((key) => key !== deselectedRowKey);
            const newSelectedRowData = selectedRowsData.filter((row) => row.uid !== deselectedRowKey);
            setSelectedRows(newSelectedRowKeys);
            setSelectedRowsData(newSelectedRowData);
        }

    };

    const handleRowSelectionAll = (isSelect, selectedRowData, deselectedRowData) => {
        console.log("is_select", isSelect);
        if (isSelect) {
            // Select all rows, avoiding duplicates
            const newSelectedRowKeys = [...selectedRows];
            const newSelectedRowData = [...selectedRowsData];
            console.log(selectedRowData)
            for (const row of selectedRowData) {
                if (row !== undefined) {
                    if (!newSelectedRowKeys.includes(row.uid)) {
                        newSelectedRowKeys.push(row.uid);
                        newSelectedRowData.push(row);
                    }
                }
            }

            setSelectedRows(newSelectedRowKeys);
            setSelectedRowsData(newSelectedRowData);
        } else {
            // Deselect rows based on deselectedRowData uids
            const deselectedRowKeys = deselectedRowData.map((row) => row.uid);
            const newSelectedRowKeys = selectedRows.filter((key) => !deselectedRowKeys.includes(key));
            const newSelectedRowData = selectedRowsData.filter((row) => !deselectedRowKeys.includes(row.uid));
            setSelectedRows(newSelectedRowKeys);
            setSelectedRowsData(newSelectedRowData);
        }
    };


    function renderQuantity(quantity) {
        if (Number.isInteger(quantity)) {
          return quantity.toString(); // If it's an integer, no decimal places
        } else {
          return quantity.toFixed(3); // If it's a float, round to 3 decimal places
        }
      }


    const columns = [
        {
            title: 'PO. No',
            dataIndex: 'purchase_order_item',
            key: 'purchase_order_item',
            width: 120,
            render: (_, record) => (
                <Button style={{ "padding": "0px" }} type="link" >
                    <Link to={`/purchase-order/${record.purchase_order_item.uid}`}>{record.purchase_order_item.purchase_order_no}</Link>
                    <CopyToClipboardButton content={record.purchase_order_item.purchase_order_no} />
                </Button>
            ),
        },
        {
            title: 'Line No',
            dataIndex: 'purchase_order_item',
            key: 'purchase_order_item',
            width: 80,
            render: (purchase_order_item) => purchase_order_item.line_no,
        },
        {
            title: 'Material Code',
            dataIndex: 'purchase_order_item',
            key: 'purchase_order_item',
            width: 180,
            render: (purchase_order_item) => purchase_order_item.material_code,
        },
        {
            title: 'Description',
            dataIndex: 'purchase_order_item',
            key: 'purchase_order_item',
            ellipsis: true,
            width: 180,
            render: (purchase_order_item) => purchase_order_item.note,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 80,
            render: (quantity) => renderQuantity(quantity),
        },
        {
            title: 'Request Date',
            dataIndex: 'request_delivery_date',
            key: 'request_delivery_date',
            width: 120,
            render: (request_delivery_date) => request_delivery_date ? new Date(request_delivery_date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) : '-',
        },
        {
            title: 'Current Date',
            dataIndex: 'purchase_order_item',
            key: 'purchase_order_item',
            width: 120,
            render: (purchase_order_item) => new Date(purchase_order_item.request_delivery_date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }),
        },
        {
            title: 'Assign Date',
            dataIndex: 'supplier_delivery_date',
            key: 'supplier_delivery_date',
            width: 120,
            render: (date) => new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }),
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
            width: 80,
        },
        {
            title: 'Remark',
            dataIndex: 'remark',
            key: 'remark',
            width: 80,
        },
        {
            title: 'Action',
            dataIndex: 'uid',
            key: 'action',
            width: 80,
            render: (uid) => (
                <Button type='primary' size='small' onClick={() => showSplitModal(uid)} >Split</Button>
            ),
        },
    ];

    return (
        <div>
            <Table
                dataSource={data}
                columns={columns}
                loading={loading}
                size='small'
                rowKey="uid"
                pagination={{
                    current: currentPage,
                    total: totalItems,
                    pageSize,
                    onChange: handlePaginationChange,
                    onShowSizeChange: handlePaginationChange,
                    showSizeChanger: true, // Enable changing page size
                    pageSizeOptions: ['10', '20', '50', '100'], // Define available page sizes
                }}
                rowSelection={{
                    selectedRowKeys: selectedRows,
                    // onChange: handleRowSelection,
                    onSelect: handleRowSelection,
                    onSelectAll: handleRowSelectionAll,
                }}
            />
            <Modal title="Pack Shipment" visible={modalVisible} onCancel={() => { setModalVisible(false); packForm.resetFields(); }} footer={null}>
                <Form form={packForm} onFinish={packShipment}>
                    Pickup Location: <MyDropdown />
                    <Form.Item
                        name="pickup_location"
                        rules={[{ required: true, message: 'Please enter Pickup Location' }]}
                    >
                        <TextArea rows={4} placeholder='Pickup Location' />
                    </Form.Item>
                    Remark:
                    <Form.Item
                        name="vendor_remark"
                    >
                        <TextArea rows={4} placeholder='Remark' />
                    </Form.Item>
                    <Form.Item>
                        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                            <List
                                header={<div>Selected Items({selectedRows.length}):</div>}
                                bordered
                                dataSource={selectedItemsData}
                                size='small'
                                renderItem={(selectedItem) => (
                                    <List.Item>
                                        <Descriptions size="small" column={1}>
                                            <Descriptions.Item label="Purchase Order No">{selectedItem.purchase_order_item.purchase_order_no}</Descriptions.Item>
                                            <Descriptions.Item label="Line No">{selectedItem.purchase_order_item.line_no}</Descriptions.Item>
                                            <Descriptions.Item label="Material Code">{selectedItem.purchase_order_item.material_code}</Descriptions.Item>
                                            <Descriptions.Item label="Description">{selectedItem.purchase_order_item.note}</Descriptions.Item>
                                            <Descriptions.Item label="Quantity">{selectedItem.quantity}</Descriptions.Item>
                                            <Descriptions.Item label="Assign Delivery Date">
                                                {new Date(selectedItem.supplier_delivery_date).toLocaleDateString()}
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </List.Item>
                                )}
                            />
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Submit</Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Split this item"
                visible={modalSplitVisible}
                onCancel={candelSplitModal}
                footer={null}
            >
                <Form form={form} layout="vertical" onFinish={handleSplitSubmit}>
                    <Form.Item
                        label="Quantity"
                        name="quantity"
                        rules={[
                            {
                                required: true,
                                validator: validateQuantity,
                            },
                        ]}
                    >
                        <Input type="number" step="0.001" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ReadyToShipTableVendor;

