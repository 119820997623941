import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Select, Card, message } from 'antd';
import axios from 'axios';
import config from '../../config';

const base_url = config.base_url;

const { Option } = Select;
const { TextArea } = Input;

const ShipmentUpdateButton = ({ uid, shipment_detail, refresh }) => {
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();
    const [freightForwarders, setFreightForwarders] = useState([]);
    const [purchaseGroups, setPurchaseGroups] = useState([]);

    useEffect(() => {
        async function fetchFreightForwarders() {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${base_url}/freight-forwarder`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = response.data;
                setFreightForwarders(data);
            } catch (error) {
                console.error('Error fetching freight forwarders:', error);
            }
        }
        async function fetchPurchaseGroup() {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${base_url}/purchase-group`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = response.data;
                setPurchaseGroups(data);
            } catch (error) {
                console.error('Error fetching purchase groups:', error);
            }
        }
        fetchPurchaseGroup();
        fetchFreightForwarders();
    }, []);

    const showModal = () => {
        form.setFieldsValue({
            status: getNextStatusOptions(shipment_detail.status),
            shipment_type: shipment_detail.shipment_type,
            freight_forwarder_uid: shipment_detail.freight_forwarder_uid,
            air_way_bill: shipment_detail.air_way_bill,
            remark: shipment_detail.remark
          });
        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const getNextStatusOptions = (currentStatus) => {
        const nextStatusOptionsMap = {
            Packed: 'Flagged',
            Flagged: 'Pickup',
            Pickup: 'Complete'
        };
        // console.log(nextStatusOptionsMap[currentStatus])
        return nextStatusOptionsMap[currentStatus] || [];
    };

    const handleSubmit = async (values) => {
        try {
            // Prepare the data to be sent in the PUT request
            const ccsNumberWithPrefix = "CCS_" + values.ccs_number;
            const requestData = {
                status: values.status,
                shipment_type: values.shipment_type,
                freight_forwarder_uid: values.freight_forwarder_uid,
                purchase_group_uid: values.purchase_group_uid,
                air_way_bill: values.air_way_bill,
                ccs_number: ccsNumberWithPrefix,
                remark: values.remark,
            };
            // console.log(requestData)
            // Perform the PUT request using Axios
            await axios.put(`${base_url}/shipment/${uid}`, requestData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            // Close the modal and reset the form
            handleCancel();
            refresh();
            form.resetFields();
            message.success('Update Success');
        } catch (error) {
            console.error('Error updating shipment:', error);
        }
    };

    return (
        <div>
            <Button hidden={shipment_detail.status !== "Packed"} type="primary" style={{ marginRight: '12px', width: '120px' }} onClick={showModal}>
                Update
            </Button>
            <Modal
                title="Update Shipment"
                visible={visible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form form={form} onFinish={handleSubmit}>
                    <Form.Item
                        name="status"
                        label="Next Status"
                        rules={[{ required: true, message: 'Please select the next status' }]}
                    >
                        <Card size='small' style={{ textAlign: 'center' }}>
                            {getNextStatusOptions(shipment_detail.status)}
                        </Card>
                    </Form.Item>
                    <Form.Item
                        name="shipment_type"
                        label="Shipment Type"
                        rules={[{ required: true, message: 'Please enter shipment type' }]}
                    >
                        <Select>
                            <Option value="Air">Air</Option>
                            <Option value="Sea">Sea</Option>
                            <Option value="Truck">Truck</Option>
                            <Option value="Rail">Rail</Option>
                            <Option value="Hand Carry">Hand Carry</Option>
                            <Option value="Air Courier">Air Courier</Option>
                            <Option value="Others">Others</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="freight_forwarder_uid"
                        label="Freight Forwarder"
                        rules={[{ required: true, message: 'Please select freight forwarder' }]}
                    >
                        <Select>
                            {freightForwarders.length > 0 &&
                                freightForwarders.map(ff => (
                                    <Option key={ff.uid} value={ff.uid}>{ff.name}</Option>
                                ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="purchase_group_uid"
                        label="Purchase Group"
                        rules={[{ required: true, message: 'Please select freight forwarder' }]}
                    >
                        <Select>
                            {purchaseGroups.length > 0 &&
                                purchaseGroups.map(pg => (
                                    <Option key={pg.uid} value={pg.uid}>{pg.name} ({pg.purchase_group})</Option>
                                ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="ccs_number" label="CCS Number" rules={[{ required: true, message: 'Please enter ccs number' }]}>
                        <Input addonBefore="CCS_"/>
                    </Form.Item>
                    <Form.Item name="air_way_bill" label="Air Way Bill">
                        <Input />
                    </Form.Item>
                    <Form.Item name="remark" label="Remark">
                        <TextArea rows={4} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ShipmentUpdateButton;
