import React, { useState } from 'react';
import { Button, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const CopyToClipboardButton = ({ content }) => {
  const copyToClipboard = () => {
    try {
      const textArea = document.createElement('textarea');
      textArea.value = content;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      message.success('Copied to clipboard', 0.5);
    } catch (error) {
      message.error('Failed to copy to clipboard');
    }
  };

  return (
    <Button size='small' style={{"padding": "1px"}} onClick={copyToClipboard} type="link">
      <CopyOutlined />
    </Button>
  );
};

export default CopyToClipboardButton;
