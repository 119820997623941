import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Select, message, Upload, Space, Col, Row } from 'antd';
import { UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../config'
import UploadTooltip from '../../components/UploadTooltip';

const base_url = config.base_url
const { Option } = Select;
const { confirm } = Modal;

const PurchaseGroupManagementPage = () => {
  const [purchaseGroups, setPurchaseGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [form] = Form.useForm();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Number of items per page

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    fetchPurchaseGroups();
  }, [currentPage, pageSize]);

  useEffect(() => {
    fetchPurchaseGroups();
  }, []);

  const fetchPurchaseGroups = async () => {
    try {
      const token = localStorage.getItem('token');
      setLoading(true);
      const skip = (currentPage - 1) * pageSize;
      let params = {
        skip,
        limit: pageSize
      };
      const response = await axios.get(`${base_url}/purchase-group/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      });
      setPurchaseGroups(response.data.data);
      setTotalItems(response.data.total_count)
    } catch (error) {
      console.error('Error fetching purchase groups:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddPurchaseGroup = async (values) => {
    try {
      const token = localStorage.getItem('token');
      let response;

      if (isEditing) {
        response = await axios.put(`${base_url}/purchase-group/${editingData.uid}`, values, {
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        });
      } else {
        response = await axios.post(`${base_url}/purchase-group`, values, {
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        });
      }
      console.log(response.status)
      if (response.status === 200 || response.status === 201) {
        fetchPurchaseGroups();
        setVisible(false);
        setIsEditing(false);
        setEditingData(null);
        form.resetFields();
        message.success('Purchase Group added successfully!');
      } else {
        throw new Error('Error handling purchase group.');
      }
    } catch (error) {
      if (isEditing) {
        console.error('Error editing purchase group:', error);
        message.error('Error editing purchase group.');
      } else {
        console.error('Error adding purchase group:', error);
        message.error('Error adding purchase group.');
      }
    }
  };


  const handleDeletePurchaseGroup = (agentUid) => {
    confirm({
      title: 'Are you sure you want to delete this agent?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          const token = localStorage.getItem('token');
          await axios.delete(`${base_url}/purchase-group/${agentUid}`, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } });
          fetchPurchaseGroups();
          message.success('Agent deleted successfully!');
        } catch (error) {
          console.error('Error deleting purchase group:', error);
          message.error('Error deleting purchase group.');
        }
      },
    });
  };

  const handleEdit = (data) => {
    setIsEditing(true);
    setEditingData(data);
    form.setFieldsValue({
      name: data.name,
      email: data.email,
      purchase_group: data.purchase_group,
    });
    setVisible(true);
  };

  const columns = [
    { title: 'Purchase Group', dataIndex: 'purchase_group', key: 'purchase_group', ellipsis: true },
    { title: 'Name', dataIndex: 'name', key: 'name', ellipsis: true },
    { title: 'Email', dataIndex: 'email', key: 'email', ellipsis: true },
    {
      title: 'Action',
      key: 'action',
      ellipsis: true,
      render: (_, record) => (
        <span>
          <Button type="link" onClick={() => handleEdit(record)}>Edit</Button>
          <Button type="link" danger onClick={() => handleDeletePurchaseGroup(record.uid)}>Delete</Button>
        </span>
      ),
    },
  ];

  return (
    <div>
      <h2>Purchase Group</h2>
      <div style={{ marginBottom: '16px' }}>
        <Row>
          <Col span={20}>
            <Space size="middle">
              <Button type="primary" onClick={() => setVisible(true)}>
                Add Purchase Group
              </Button>
              <Upload
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                action={`${base_url}/upload/upload-purchase-group`}
                headers={{ Authorization: `Bearer ${localStorage.getItem('token')}` }}
                onChange={(info) => {
                  if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                  }
                  if (info.file.status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully`);
                    // re-fetch the agent data after a file is uploaded
                    fetchPurchaseGroups();
                  } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                  }
                }}
                showUploadList={false}
              >
                <Button icon={<UploadOutlined />}>Upload Excel</Button>
              </Upload>
              <UploadTooltip/>
            </Space>
          </Col>
          <Col span={4}>

          </Col>

        </Row>

      </div>

      <Table
        size="small"
        dataSource={purchaseGroups}
        columns={columns}
        loading={loading}
        rowKey="uid"
        pagination={{
          current: currentPage,
          total: totalItems,
          pageSize,
          onChange: handlePaginationChange,
          onShowSizeChange: handlePaginationChange,
          showSizeChanger: true, // Enable changing page size
          pageSizeOptions: ['10', '20', '50'], // Define available page sizes
        }}
      />

      <Modal
        title={isEditing ? 'Edit Purchase Group' : 'Add Purchase Group'}
        visible={visible}
        onCancel={() => {
          setVisible(false);
          setIsEditing(false);
          setEditingData(null);
          form.resetFields();
        }}
        onOk={() => form.submit()}
      >
        <Form form={form} onFinish={handleAddPurchaseGroup}>
          <Form.Item
            name="purchase_group"
            label="Purchase Group"
            rules={[{ required: true, message: 'Please enter the purchase group' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please enter the name' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: 'Please enter your email' },
              { type: 'email', message: 'Please enter a valid email' },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default PurchaseGroupManagementPage;
