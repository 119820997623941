import React, { useState, useEffect } from 'react';
import { Table, Button, Popconfirm, message, Modal, Input, DatePicker, Form, Tag, List } from 'antd'; // Import Button from 'antd'
import { useNavigate, Link } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';
import config from '../../config';
import CopyToClipboardButton from '../Button/CopyToClipBoardButton';

const base_url = config.base_url;

const UnassignTableVendor = (params) => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [header, setHeader] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10); // Number of items per page
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedUid, setSelectedUid] = useState(null);
    const [form] = Form.useForm();
    const [formData, setFormData] = useState({});
    const [visible, setVisible] = useState(false);
    const [selectedItems, setSelectedItems] = useState([])

    useEffect(() => {
        fetchData();
    }, [currentPage, pageSize, params.purchase_order_no]);

    useEffect(() => {
        params.setCount(header)
    }, [header])

    useEffect(() => {
        handleAssign();
    }, [params.assign_count])

    const fetchData = async () => {
        try {
            setLoading(true);
            const skip = (currentPage - 1) * pageSize;
            const response = await axios.get(`${base_url}/purchase-order-item/pagination`, {
                params: {
                    skip,
                    limit: pageSize,
                    vendor_uid: params.vendor_uid,
                    status: "Unassign",
                    purchase_order_no: params.purchase_order_no,
                    purchase_group: params.filter_purchase_group
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    // Add other headers if needed
                },
            });
            setData(response.data.data);
            setTotalItems(response.data.total_count);
            setHeader(response.data.header);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const handlePurchaseOrderClick = (uid) => {
        navigate(`/purchase-order/${uid}`);
    };


    const handlePaginationChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
        setSelectedItems([]);
    };

    const showModal = (uid) => {
        setSelectedUid(uid);
        setModalVisible(true);
    };

    const handleCancel = () => {
        setModalVisible(false);
        form.resetFields();
    };

    const handleAssign = () => {
        if (selectedItems.length > 0) {
            setVisible(true);
            console.log(selectedItems)
        } else {
            if (params.assign_count > 0) {
                message.error('No item selected.');
            }
        }
    };

    const validateQuantity = (rule, value) => {
        if (value === undefined || value === null) {
            return Promise.resolve(); // Allow empty field
        }

        const floatValue = parseFloat(value);

        if (isNaN(floatValue) || floatValue <= 0) {
            return Promise.reject('Quantity must be a valid positive number and greater than 0.');
        }

        return Promise.resolve();
    };

    const handleModalOk = async () => {
        try {
            const values = await form.validateFields();
            const { deliveryDate } = values;

            if (!deliveryDate) {
                message.error('Please select a delivery date');
                return;
            }
            const mappedItems = selectedItems.map(uid => data.find(dataItem => dataItem.uid === uid));
            if (mappedItems.deliverySchedules) {
                const totalDeliveryQuantity = mappedItems.delivery_schedules.reduce(
                  (acc, item) => acc + item.quantity,
                  0
                );
              
                mappedItems.unassign_item = mappedItems.quantity - totalDeliveryQuantity;
              }
              else {
                mappedItems.unassign_item = mappedItems.quantity
              }
            const assignItems = mappedItems.map((item) => ({
                purchase_order_item_uid: item.uid,
                quantity_schedule: [
                    {
                        quantity: item.unassign_item,
                        supplier_delivery_date: deliveryDate.format(),
                    },
                ],
            }));

            const response = await fetch(`${base_url}/purchase-order-item/assign-delivery-date`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ assign_item: assignItems }),
            });

            if (response.ok) {
                message.success('Delivery date assigned successfully');
                form.resetFields();
                // setSelectedItems([]);
                fetchData();
                setVisible(false);
            } else {
                message.error('Failed to assign delivery date');
            }
        } catch (error) {
            console.error('Error assigning delivery date:', error);
            message.error('An error occurred while assigning delivery date');
        }
        setSelectedItems([]);
    };

    const handleFormSubmit = async (values) => {
        // You can use the 'values' object to access the form data.
        console.log('Form Data:', values);
        console.log(values.delivery_date)
        console.log(moment(values.delivery_date?.format('YYYY-MM-DD')).add(7, 'hours').toISOString())
        console.log(selectedUid)
        // Reset the form fields to their initial state
        form.resetFields();

        try {
            const response = await axios.post(`${base_url}/purchase-order-item/assign-delivery-date-v2`, {
                purchase_order_item_uid: selectedUid,
                quantity: values.quantity,
                reason: values.reason,
                supplier_delivery_date: moment(values.delivery_date?.format('YYYY-MM-DD')).add(7, 'hours').toISOString()
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    // Add other headers if needed
                },
            });
            if (response.status === 201) {
                message.success('Successfully Assign Item');
                fetchData();
            } else {
                message.error(JSON.stringify(response.data.detail));
                console.log(response.status)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            message.error(JSON.stringify(error.response.data.detail));
        }
        // Close the modal after form submission
        setModalVisible(false);
    };
    function renderQuantity(quantity) {
        if (Number.isInteger(quantity)) {
          return quantity.toString(); // If it's an integer, no decimal places
        } else {
          return quantity.toFixed(3); // If it's a float, round to 3 decimal places
        }
      }

    const columns = [
        {
            title: 'PO. No',
            dataIndex: 'purchase_order_no',
            key: 'purchase_order_no',
            width: 120,
            render: (_, record) => (
                <Button style={{ padding: '0' }} type="link">
                    <Link to={`/purchase-order/${record.uid}`}>{record.purchase_order_no}</Link>
                    <CopyToClipboardButton content={record.purchase_order_no} />
                </Button>
            ),
        },
        {
            title: 'Line No',
            dataIndex: 'line_no',
            key: 'line_no',
            width: 80,
        },
        {
            title: 'Material Code',
            dataIndex: 'material_code',
            key: 'material_code',
            width: 180,
        },
        {
            title: 'Description',
            dataIndex: 'note',
            key: 'note',
            ellipsis: true,
            width: 330,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 80,
            render: (quantity) => renderQuantity(quantity),
        },
        {
            title: 'Remaining Quantity',
            width: 150,
            render: (data) => {
                const totalQuantity = data.delivery_schedules.reduce((total, schedule) => total + schedule.quantity, 0);
                const remainingQuantity = data.quantity - totalQuantity;
                const formattedQuantity = remainingQuantity % 1 !== 0 ? remainingQuantity.toFixed(3) : Math.floor(remainingQuantity);
                return (<div>{formattedQuantity}</div>);
            },
        },
        {
            title: 'Request Date',
            dataIndex: 'request_delivery_date',
            key: 'request_delivery_date',
            render: (date) => new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }),
        },
        {
            title: 'Action',
            dataIndex: 'uid',
            key: 'action',
            width: 80,
            render: (uid) => (
                <Button type='primary' size='small' onClick={() => showModal(uid)}>Split</Button>
            ),
        },
    ];

    return (
        <div>
            <Table
                dataSource={data}
                columns={columns}
                loading={loading}
                size='small'
                rowKey="uid"
                pagination={{
                    current: currentPage,
                    total: totalItems,
                    pageSize,
                    onChange: handlePaginationChange,
                    onShowSizeChange: handlePaginationChange,
                    showSizeChanger: true, // Enable changing page size
                    pageSizeOptions: ['10', '20', '50', '100'], // Define available page sizes
                }}
                rowSelection={{
                    selectedRowKeys: selectedItems,
                    onChange: (selectedRowKeys) => setSelectedItems(selectedRowKeys),
                }}
            />
            <Modal
                title="Split this item"
                visible={modalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
                    <Form.Item
                        label="Delivery Date"
                        name="delivery_date"
                        rules={[
                            {
                                required: true,
                                message: 'Please select the delivery date!',
                            },
                        ]}
                    >
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        label="Reason"
                        name="reason"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the reason!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Quantity"
                        name="quantity"
                        rules={[
                            {
                                required: true,
                                validator: validateQuantity,
                            },
                        ]}
                    >
                        <Input type="number" step="0.001"/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Assign Delivery Date"
                visible={visible}
                onCancel={() => setVisible(false)}
                onOk={handleModalOk}
            >
                <Form form={form}>
                    <Form.Item
                        name="deliveryDate"
                        label="Delivery Date"
                        rules={[{ required: true, message: 'Please select a delivery date' }]}
                    >
                        <DatePicker />
                    </Form.Item>
                </Form>
                {selectedItems.length > 0 && (
                    <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <List
                      dataSource={selectedItems.map(selectedUid => {
                        const selectedItem = data.find(item => item.uid === selectedUid);
                        if (selectedItem) {
                          const totalDeliveryQuantity = selectedItem.delivery_schedules.reduce(
                            (acc, item) => acc + item.quantity,
                            0
                          );
                        
                          selectedItem.unassign_item = selectedItem.quantity - totalDeliveryQuantity;
                        } else {
                          selectedItem.unassign_item = selectedItem.quantity;
                        }
                        return selectedItem;
                      })}
                      renderItem={selectedItem => (
                        <List.Item key={selectedItem.uid}>
                          <List.Item.Meta
                            title={`Purchase Order No: ${selectedItem.purchase_order_no}`}
                            description={<div>
                                Material Code: {selectedItem.material_code}
                                <br />
                                Line No: {selectedItem.line_no}
                                <br />
                                Description: {selectedItem.note}
                                <br />
                                Quantity: {selectedItem.unassign_item} {selectedItem.unit}
                              </div>}
                          />
                        </List.Item>
                      )}
                    />
                  </div>
                )}
            </Modal>
        </div>
    );
};

export default UnassignTableVendor;

