import React, { useState, useEffect } from 'react';
import { Typography, Card } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import config from '../../config'

const base_url = config.base_url
const { Title } = Typography;

const DashboardPage = () => {
  const [currentDateTime, setCurrentDateTime] = useState(moment().format('MMMM Do, YYYY, h:mm:ss A'));
  const [purchaseOrderCounts, setPurchaseOrderCounts] = useState([]);
  const [shipmentCounts, setShipmentCounts] = useState([]);
  const [agentData, setAgentData] = useState([]);

  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDateTime(moment().format('MMMM Do, YYYY, h:mm:ss A'));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const purchaseOrderResponse = await axios.get(`${base_url}/purchase-order-item/count`, {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        });
        const filteredCounts = Object.entries(purchaseOrderResponse.data).filter(([status]) => status !== 'unrelease_count' && status !== 'delay_count' && status !== 'late_count');
        const updatedCounts = Object.fromEntries(filteredCounts);
        setPurchaseOrderCounts(updatedCounts);

        const shipmentResponse = await axios.get(`${base_url}/shipment/count`, {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        });
        setShipmentCounts(shipmentResponse.data);

        const agentResponse = await axios.get(`${base_url}/agent`, {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
          },
        });
        setAgentData(agentResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const calculateProgress = (count, totalCount) => {
    return totalCount ? (count / totalCount) * 100 : 0;
  };

  const getStatusColor = (status, type) => {
    if (type === 'purchaseOrder') {
      switch (status) {
        case 'unrelease':
          return '#f5222d'; // Red color
        case 'unassign':
          return '#faad14'; // Yellow color
        case 'assign':
          return '#1890ff'; // Blue color
        case 'commit':
          return '#13c2c2'; // Teal color
        case 'packed':
          return '#52c41a'; // Green color
        case 'delay':
          return '#ff7f00'; // Orange color
        case 'late':
          return '#ff4d4f'; // Dark red color
        default:
          return '#1890ff'; // Blue color (default)
      }
    } else if (type === 'shipment') {
      switch (status) {
        case 'packed':
          return '#52c41a'; // Green color
        case 'flagged':
          return '#ff4d4f'; // Coral color
        case 'pickup':
          return '#ffa940'; // Orange color
        case 'complete':
          return '#13c2c2'; // Cyan color
        default:
          return '#1890ff'; // Default color
      }
    }
    return '#1890ff'; // Default color
  };

  const removeCountSuffix = (status) => {
    return status.replace('_count', '');
  };

  const calculateTotalCount = (counts) => {
    return counts.reduce((total, count) => total + count, 0);
  };

  const excludedKeys = ['late_count', 'delay_count'];
  const purchaseOrderTotalData = Object.fromEntries(Object.entries(purchaseOrderCounts).filter(([key]) => !excludedKeys.includes(key)));

  const purchaseOrderTotal = calculateTotalCount(Object.values(purchaseOrderTotalData));
  const shipmentTotal = calculateTotalCount(Object.values(shipmentCounts));

  return (
    <div style={{ padding: '24px' }}>
      <h2 style={{ marginBottom: '24px' }}>Dashboard</h2>
      <Title level={5}>{currentDateTime}</Title>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
        <div style={{ width: '48%' }}>
          <Title level={4}>Purchase Order Status</Title>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {Object.entries(purchaseOrderCounts).map(([status, count]) => (
              <Card
                size='small'
                key={status}
                style={{
                  flex: '0 0 30%',
                  marginBottom: '16px',
                  marginRight: '16px',
                  backgroundColor: getStatusColor(removeCountSuffix(status), 'purchaseOrder'),
                  padding: '16px',
                  borderRadius: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginBottom: '8px', textAlign: 'center' }}>
                  <span style={{ fontSize: 20, fontWeight: 'bold', color: '#000000' }}>{count}</span>
                </div>
                <div style={{ fontSize: 12, color: '#000000', marginBottom: '8px', textAlign: 'center', fontWeight: 'bold' }}>
                  {removeCountSuffix(status) === 'packed' ? (
                    removeCountSuffix(status).toUpperCase()
                  ) : (
                    <Link
                      style={{ color: 'black' }}
                      to={`/purchase-order?${removeCountSuffix(status) === 'delay' || removeCountSuffix(status) === 'late' ? 'initial' : 'tab'}=${removeCountSuffix(status)}${removeCountSuffix(status) === 'delay' || removeCountSuffix(status) === 'late' ? '&tab=unassign' : ''}`}
                    >
                      {removeCountSuffix(status).toUpperCase()}
                    </Link>
                  )}
                </div>
                <div style={{ width: '100%', height: '2px', backgroundColor: '#e8e8e8' }}></div>
                <div style={{ fontSize: 12, color: '#000000', marginTop: '8px', textAlign: 'center' }}>
                  {isNaN((count / purchaseOrderTotal) * 100) ? '0.00' : ((count / purchaseOrderTotal) * 100).toFixed(2)}% of Total
                </div>
              </Card>
            ))}
            <div style={{ width: '100%', marginTop: '16px' }}>
              <div style={{ fontSize: 16, fontWeight: 'bold', color: '#000000', marginBottom: '8px' }}>
                Total: {purchaseOrderTotal}
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: '48%' }}>
          <Title level={4}>Shipment Status</Title>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {Object.entries(shipmentCounts).map(([status, count]) => (
              <Card
                size='small'
                key={status}
                style={{
                  flex: '0 0 30%',
                  marginBottom: '16px',
                  marginRight: '16px',
                  backgroundColor: getStatusColor(removeCountSuffix(status), 'shipment'),
                  padding: '16px',
                  borderRadius: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginBottom: '8px', textAlign: 'center' }}>
                  <span style={{ fontSize: 20, fontWeight: 'bold', color: '#000000' }}>{count}</span>
                </div>
                <div style={{ fontSize: 12, color: '#000000', marginBottom: '8px', textAlign: 'center', fontWeight: 'bold' }}>
                  <Link style={{ color: 'black' }} to={`/shipment?${removeCountSuffix(status) === 'delay' || removeCountSuffix(status) === 'late' ? 'initial' : 'tab'}=${removeCountSuffix(status)}${removeCountSuffix(status) === 'delay' || removeCountSuffix(status) === 'late' ? '&tab=unassign' : ''}`}>{removeCountSuffix(status).toUpperCase()}</Link>
                </div>
                <div style={{ width: '100%', height: '2px', backgroundColor: '#e8e8e8' }}></div>
                <div style={{ fontSize: 12, color: '#000000', marginTop: '8px', textAlign: 'center' }}>
                  {isNaN((count / shipmentTotal) * 100) ? '0.00' : ((count / shipmentTotal) * 100).toFixed(2)}% of Total
                </div>
              </Card>
            ))}
            <div style={{ width: '100%', marginTop: '16px' }}>
              <div style={{ fontSize: 16, fontWeight: 'bold', color: '#000000', marginBottom: '8px' }}>
                Total: {shipmentTotal}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Card
        title="Your Information"
        style={{ width: '100%', marginBottom: '16px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)' }}
      >
        <div style={{ marginBottom: '8px' }}>
          <p style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '4px' }}>Name: {decodedToken.vendor.name}</p>
          <p style={{ fontSize: '14px', color: '#595959' }}>Code: {decodedToken.vendor.code}</p>
          <p style={{ fontSize: '14px', color: '#595959' }}>Email: {decodedToken.vendor.email}</p>
          <p style={{ fontSize: '14px', color: '#595959' }}>Address: {decodedToken.vendor.address}</p>
          <p style={{ fontSize: '14px', color: '#595959' }}>Contact Person: {decodedToken.vendor.contact_person}</p>
          <p style={{ fontSize: '14px', color: '#595959' }}>Phone No.: {decodedToken.vendor.phone_no}</p>
          <p style={{ fontSize: '14px', color: '#595959' }}>Payment Term: {decodedToken.vendor.payment_term}</p>
        </div>
        {agentData[0] ? (
          <div>
            <p style={{ fontSize: '14px', marginBottom: '4px' }}>Shipper Name:{agentData[0].shipper_name}</p>
            <p style={{ fontSize: '14px', marginBottom: '4px' }}>Pickup Locaiton: {agentData[0].pickup_location}</p>
            <p style={{ fontSize: '14px', marginBottom: '4px' }}>Agent Contract: {agentData[0].agent_contact}</p>
          </div>
        ) : (
          <p style={{ fontSize: '14px', color: 'red' }}>No agent setup contact purchaser to add agent</p>
        )}
      </Card>
    </div>
  );
};

export default DashboardPage;
