import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../config';

const base_url = config.base_url;

const ShipmentDeleteButton = ({ uid }) => {
    const navigate = useNavigate();

    const handleDelete = async () => {
        try {
            await axios.delete(`${base_url}/shipment/${uid}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            navigate(-1);
        } catch (error) {
            console.error('Error deleting shipment:', error);
        }
    };

    return (
        <Popconfirm
            title="Are you sure you want to delete this shipment?"
            onConfirm={() => handleDelete()}
            okText="Yes"
            cancelText="No"
        >
            <Button
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
                danger
            >
            </Button>
        </Popconfirm>
    );
};

export default ShipmentDeleteButton;
