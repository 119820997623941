import React, { useEffect, useState } from 'react';
import {message} from 'antd'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import PurchaseOrder from './pages/PurchaseOrderPage/PurchaseOrderPage';
import PurchaseOrderItemPage from './pages/PurchaseOrderPage/PurchaseOrderItemPage';
import LoginPage from './pages/LoginPage/LoginPage';
import UserManagementPage from './pages/userPage/UserPage';
import VendorManagementPage from './pages/VendorPage/VendorPage';
import AgentManagementPage from './pages/AgentPage/AgentPage';
import PurchaseGroupManagementPage from './pages/PurchaseGroupPage/PurchaseGroupPage';
import CalendarPage from './pages/CalendarPage/CalendarPage';
import ShipmentViewPage from './pages/ShipmentPage/ShipmentPage';
import jwtDecode from 'jwt-decode';
import PurchaserPurchaseOrderItemPage from './pages/PurchaseOrderPage/PurchaserPurchaserOrder';
import EmployeePurchaseOrderItemPage from './pages/PurchaseOrderPage/EmployeePurchaseOrder';
import ShipmentDetailPage from './pages/ShipmentPage/ShipmentDetailPage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import DashboardPageFF from './pages/DashboardPage/DashboardFF';
import DashboardVendorPage from './pages/DashboardPage/DashboardVendorPage';
import ToolsPage from './pages/ToolsPage/ToolsPage';
import AccountPage from './pages/AccountPage/AccountPage';
import FreightForwarderManagementPage from './pages/FreightForwarderPage/FreightForwarderPage';
import LogManagementPage from './pages/LogPage/LogPage';
import NotFound from './pages/NotfoundPage/NotfoundPage';
import TicketPage from './pages/TicketPage/TicketPage';
import TicketChatPage from './pages/TicketPage/TicketChatPage';
import ReportPage from './pages/ReportPage/ReportPage';


const App = () => {
  const token = localStorage.getItem('token');
  const [isAuthenticated, setIsAuthenticated] = useState(Boolean(token));
  const decodedToken = token ? jwtDecode(token) : null;

  

  return (
    <Router>
      <Routes>
        {isAuthenticated ? (
          <Route path="/*" element={(
            <div style={{ display: 'flex' }}>
              {window.location.pathname !== '/login' && <Sidebar />}
              <div style={{ flex: 1, padding: '20px' }}>
                {(decodedToken.role.name === 'Admin' ) && (
                  <Routes>
                    <Route path="/" element={<Navigate to="/dashboard" />} />
                    <Route path="/dashboard" element={<DashboardPage />} />
                    <Route path="/purchase-order" element={<PurchaserPurchaseOrderItemPage/>} />
                    <Route path="/purchase-order/:uid" element={<PurchaseOrderItemPage />} />
                    <Route path="/shipment" element={<ShipmentViewPage />} />
                    <Route path="/shipment/:uid" element={<ShipmentDetailPage />} />
                    <Route path="/calendar" element={<CalendarPage />} />
                    <Route path="/users" element={<UserManagementPage />} />
                    <Route path="/vendor" element={<VendorManagementPage />} />
                    <Route path="/freight-forwarder" element={<FreightForwarderManagementPage />} />
                    <Route path="/agent" element={<AgentManagementPage />} />
                    <Route path="/purchase-group" element={<PurchaseGroupManagementPage />} />
                    <Route path="/tools" element={<ToolsPage />} />
                    <Route path="/logs" element={<LogManagementPage />} />
                    <Route path="/account" element={<AccountPage />} />
                    <Route path="/report" element={<ReportPage />} />
                    <Route path="/ticket" element={<TicketPage />} />
                    <Route path="/ticket/:uid" element={<TicketChatPage />} />
                    <Route path="*" element={<NotFound />} />
                    {/* Add other routes and components */}
                  </Routes>
                )}
                {(decodedToken.role.name === 'Purchaser') && (
                  <Routes>
                    <Route path="/" element={<Navigate to="/dashboard" />} />
                    <Route path="/dashboard" element={<DashboardPage />} />
                    <Route path="/purchase-order" element={<PurchaserPurchaseOrderItemPage />} />
                    <Route path="/purchase-order/:uid" element={<PurchaseOrderItemPage />} />
                    <Route path="/shipment" element={<ShipmentViewPage />} />
                    <Route path="/shipment/:uid" element={<ShipmentDetailPage />} />
                    <Route path="/calendar" element={<CalendarPage />} />
                    <Route path="/vendor" element={<VendorManagementPage />} />
                    <Route path="/freight-forwarder" element={<FreightForwarderManagementPage />} />
                    <Route path="/agent" element={<AgentManagementPage />} />
                    <Route path="/account" element={<AccountPage />} />
                    <Route path="/ticket" element={<TicketPage />} />
                    <Route path="/ticket/:uid" element={<TicketChatPage />} />
                    <Route path="*" element={<NotFound />} />
                    {/* <Route path="/users" element={<UserManagementPage />} />
                    <Route path="/vendor" element={<VendorManagementPage />} />
                    <Route path="/agent" element={<AgentManagementPage />} /> */}
                    {/* Add other routes and components */}
                  </Routes>
                )}
                {(decodedToken.role.name === 'Freight Forwarder' ) && (
                  <Routes>
                    <Route path="/" element={<Navigate to="/dashboard" />} />
                    <Route path="/dashboard" element={<DashboardPageFF />} />
                    <Route path="/shipment" element={<ShipmentViewPage />} />
                    <Route path="/shipment/:uid" element={<ShipmentDetailPage />} />
                    <Route path="/account" element={<AccountPage />} />
                    <Route path="/ticket" element={<TicketPage />} />
                    <Route path="/ticket/:uid" element={<TicketChatPage />} />
                    <Route path="*" element={<NotFound />} />
                    {/* <Route path="/calendar" element={<CalendarPage />} /> */}
                    {/* Add other routes and components */}
                  </Routes>
                )}
                {(decodedToken.role.name === 'Vendor') && (
                  <Routes>
                    <Route path="/" element={<Navigate to="/dashboard" />} />
                    <Route path="/dashboard" element={<DashboardVendorPage />} />
                    <Route path="/purchase-order" element={<PurchaseOrder />} />
                    <Route path="/purchase-order/:uid" element={<PurchaseOrderItemPage />} />
                    <Route path="/shipment" element={<ShipmentViewPage />} />
                    <Route path="/shipment/:uid" element={<ShipmentDetailPage />} />
                    <Route path="/calendar" element={<CalendarPage />} />
                    <Route path="/account" element={<AccountPage />} />
                    <Route path="/ticket" element={<TicketPage />} />
                    <Route path="/ticket/:uid" element={<TicketChatPage />} />
                    <Route path="*" element={<NotFound />} />
                    {/* <Route path="/users" element={<UserManagementPage />} />
                    <Route path="/vendor" element={<VendorManagementPage />} />
                    <Route path="/agent" element={<AgentManagementPage />} /> */}
                    {/* Add other routes and components */}
                  </Routes>
                )}
                {(decodedToken.role.name === 'Employee') && (
                  <Routes>
                    <Route path="/" element={<Navigate to="/dashboard" />} />
                    <Route path="/dashboard" element={<DashboardPage />} />
                    <Route path="/purchase-order" element={<EmployeePurchaseOrderItemPage />} />
                    <Route path="/purchase-order/:uid" element={<PurchaseOrderItemPage />} />
                    <Route path="/shipment" element={<ShipmentViewPage />} />
                    <Route path="/shipment/:uid" element={<ShipmentDetailPage />} />
                    <Route path="/calendar" element={<CalendarPage />} />
                    <Route path="/account" element={<AccountPage />} />
                    <Route path="*" element={<NotFound />} />
                    {/* <Route path="/users" element={<UserManagementPage />} />
                    <Route path="/vendor" element={<VendorManagementPage />} />
                    <Route path="/agent" element={<AgentManagementPage />} /> */}
                    {/* Add other routes and components */}
                  </Routes>
                )}
              </div>
            </div>
          )} />
        ) : (
          <Route path="/*" element={<Navigate to="/login" />} />
        )}
        <Route path="/login" element={<LoginPage />} />
      </Routes>
    </Router>
  );
};

export default App;
