import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, message, Upload, Col, Row } from 'antd';
import { UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../config'
import UploadTooltip from '../../components/UploadTooltip';

const base_url = config.base_url
const { confirm } = Modal;


const FreightForwarderManagementPage = () => {
  const [freightForwarders, setFreightForwarders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingFreightForwarder, setEditingFreightForwarder] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Number of items per page

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    fetchFreightForwarders();
  }, [currentPage, pageSize]);

  const fetchFreightForwarders = async (name) => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const skip = (currentPage - 1) * pageSize;
      let params = {
        skip,
        limit: pageSize
      };
      if (name) {
        params.name = name;
      }
      const response = await axios.get(`${base_url}/freight-forwarder/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      });
      setFreightForwarders(response.data.data);
      setTotalItems(response.data.total_count)
    } catch (error) {
      console.error('Error fetching freight-forwarders:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteFreightForwarder = async (freightForwarderUid) => {
    confirm({
      title: 'Are you sure you want to delete this agent?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          await axios.delete(`${base_url}/freight-forwarder/${freightForwarderUid}`);
          setFreightForwarders((prevFreightForwarders) => prevFreightForwarders.filter((freightForwarder) => freightForwarder.uid !== freightForwarderUid));
          message.success('FreightForwarder deleted successfully!');
        } catch (error) {
          console.error('Error deleting freight forwarder:', error);
          message.error('Error deleting freight forwarder.');
        }
      }
    });
  };

  const handleAddOrEditFreightForwarder = async (values) => {
    try {
      let response;
      const token = localStorage.getItem('token');
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      if (isEditing) {
        response = await axios.put(`${base_url}/freight-forwarder/${editingFreightForwarder.uid}`, values, {headers});
        message.success('FreightForwarder edited successfully!');
      } else {
        response = await axios.post(`${base_url}/freight-forwarder`, values, {headers});
        setFreightForwarders((prevFreightForwarders) => [...prevFreightForwarders, response.data]);
        message.success('FreightForwarder added successfully!');
      }
      fetchFreightForwarders();
      setVisible(false);
      form.resetFields();
    } catch (error) {
      console.error('Error adding/editing freight forwarder:', error);
      message.error('Error adding/editing freight forwarder.');
    } finally {
      setIsEditing(false);
      setEditingFreightForwarder(null);
    }
  };

  const handleEditFreightForwarder = (freightForwarder) => {
    setIsEditing(true);
    setEditingFreightForwarder(freightForwarder);
    form.setFieldsValue({
      name: freightForwarder.name,
      code: freightForwarder.code,
      line_notify_token: freightForwarder.line_notify_token,
    });
    setVisible(true);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    fetchFreightForwarders(value);
  };

  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Code', dataIndex: 'code', key: 'code' },
    { title: 'Line Notify Token', dataIndex: 'line_notify_token', key: 'line_notify_token' },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <span>
          <Button type="link" onClick={() => handleEditFreightForwarder(record)}>Edit</Button>
          <Button type="link" danger onClick={() => handleDeleteFreightForwarder(record.uid)}>Delete</Button>
        </span>
      ),
    },
  ];

  return (
    <div>
      <h2>FreightForwarder</h2>
      <div style={{ marginBottom: '16px' }}>
        <Row>
          <Col span={20}>
            <Button style={{ marginRight: '16px' }} type="primary" onClick={() => setVisible(true)}>
              Add FreightForwarder
            </Button>
          </Col>
          <Col span={4}>
            <Input
              style={{ width: '90%' }}
              placeholder='Freight Forwarder Name'
              onChange={handleInputChange}
            >
            </Input>
          </Col>
        </Row>
      </div>
      <Table
        size="small"
        dataSource={freightForwarders}
        columns={columns}
        loading={loading}
        rowKey="uid"
        pagination={{
          current: currentPage,
          total: totalItems,
          pageSize,
          onChange: handlePaginationChange,
          onShowSizeChange: handlePaginationChange,
          showSizeChanger: true, // Enable changing page size
          pageSizeOptions: ['10', '20', '50'], // Define available page sizes
        }}
      />

      <Modal
        title={isEditing ? 'Edit Freight Forwarder' : 'Add Freight Forwarder'}
        visible={visible}
        onCancel={() => {
          setVisible(false);
          setIsEditing(false);
          setEditingFreightForwarder(null);
          form.resetFields();
        }}
        onOk={() => form.submit()}
      >
        <Form form={form} onFinish={handleAddOrEditFreightForwarder}>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please enter the freight forwarder name' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="code"
            label="Code"
            rules={[{ required: true, message: 'Please enter the freight forwarder code' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="line_notify_token"
            label="Line Notify Token"
            rules={[]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default FreightForwarderManagementPage;
