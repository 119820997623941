import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const FilterTooltip = () => {
  return (
    <Tooltip
      title={
        <>
          <p>The Percent Sign (*) Wildcard:</p>
          <p>
            Purpose: The * symbol stands for any number of letters or numbers in a word.
          </p>
          <p>
            Example: Use '83*57' to find numbers like '8300000057', '830057', and more.
          </p>
          <p>The Underscore (?) Wildcard:</p>
          <p>
            Purpose: The ? symbol represents a single letter or number in a word.
          </p>
          <p>
            Example: Use '83?57' to match '83057', '83157', '83A57', but not '8300057' or '830057000057'.
          </p>
        </>
      }
    >
      <InfoCircleOutlined style={{ fontSize: '.9rem' }} />
    </Tooltip>


  );
};

export default FilterTooltip;