import React, { useState, useEffect } from 'react';
import { Table, Button, Popconfirm, message, Modal, Tooltip, DatePicker, Form, Input } from 'antd'; // Import Button from 'antd'
import { useNavigate, Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, FormOutlined, CheckCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';
import config from '../../config';
import CopyToClipboardButton from '../Button/CopyToClipBoardButton';

const base_url = config.base_url;

const ChangesTableVendor = (params) => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [header, setHeader] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10); // Number of items per page
    const [selectedItems, setSelectedItems] = useState([])
    const [isAssignDateVisible, setIsAssignDateVisible] = useState(false);
    const [ignoreVisible, setIgnoreVisible] = useState(false);
    const [assignDateValue, setAssignDateValue] = useState(null);

    useEffect(() => {
        fetchData();
    }, [currentPage, pageSize, params.vendor_uid, params.purchase_order_no, params.filter_purchase_group]);

    useEffect(() => {
        params.setCount(header)
    }, [header])

    useEffect(() => {
        showAssignDateModal();
    }, [params.assign_count])

    useEffect(() => {
        showIgnoreModal();
    }, [params.ignore_count])

    const fetchData = async () => {
        try {
            setLoading(true);
            const skip = (currentPage - 1) * pageSize;
            const response = await axios.get(`${base_url}/delivery-schedule/pagination`, {
                params: {
                    skip,
                    limit: pageSize,
                    vendor_uid: params.vendor_uid,
                    purchase_order_no: params.purchase_order_no,
                    purchase_group: params.filter_purchase_group,
                    status: "Changes"
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    // Add other headers if needed
                },
            });
            setData(response.data.data);
            setTotalItems(response.data.total_count);
            setHeader(response.data.header);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const handlePaginationChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const showAssignDateModal = () => {
        if (selectedItems.length > 0) {
            setIsAssignDateVisible(true);
            console.log(selectedItems)
        } else {
            if (params.assign_count > 0) {
                message.error('No item selected.');
            }
        }
    };

    const handleAssignDateChange = (date) => {
        setAssignDateValue(date);
    };

    const handleAssignDateModalOk = () => {
        // Make PUT request to the API
        if (assignDateValue) {
            const formattedDate = moment(assignDateValue?.format('YYYY-MM-DD')).add(7, 'hours').toISOString()

            const body = {
                delivery_date: formattedDate,
                uids: selectedItems
            }
            axios.put(`${base_url}/delivery-schedule/multi-assign-date`, body)
                .then(() => {
                    message.success('Update assign date successfully');
                    setIsAssignDateVisible(false);
                    fetchData();
                    setSelectedItems([]);
                    setAssignDateValue(null);
                })
                .catch(error => {
                    message.error('Error update assign date');
                });
        }
    };

    const handleAssignDateModalCancel = () => {
        setIsAssignDateVisible(false);
    };

    ////

    const showIgnoreModal = () => {
        if (selectedItems.length > 0) {
            setIgnoreVisible(true);
            console.log(selectedItems)
        } else {
            if (params.ignore_count > 0) {
                message.error('No item selected.');
            }
        }
    };

    const handleIgnoreModalOk = () => {
        // Make PUT request to the API

        const body = selectedItems
        axios.put(`${base_url}/delivery-schedule/multi-ignore`, body)
            .then(() => {
                message.success('Update assign date successfully');
                setIgnoreVisible(false);
                fetchData();
                setSelectedItems([]);
            })
            .catch(error => {
                message.error('Error update assign date');
            });

    };

    const handleIgnoreModalCancel = () => {
        setIgnoreVisible(false);
    };
    ////
    function renderQuantity(quantity) {
        if (Number.isInteger(quantity)) {
          return quantity.toString(); // If it's an integer, no decimal places
        } else {
          return quantity.toFixed(3); // If it's a float, round to 3 decimal places
        }
      }
      
    const columns = [
        {
            title: 'PO. No',
            dataIndex: 'purchase_order_item',
            key: 'purchase_order_item',
            width: 120,
            render: (_, record) => (
                <Button style={{"padding": "0px"}} type="link" >
                    <Link to={`/purchase-order/${record.purchase_order_item.uid}`}>{record.purchase_order_item.purchase_order_no}</Link>
                    <CopyToClipboardButton content={record.purchase_order_item.purchase_order_no} />
                </Button>
            ),
        },
        {
            title: 'Line No',
            dataIndex: 'purchase_order_item',
            key: 'purchase_order_item',
            width: 80,
            render: (purchase_order_item) => purchase_order_item.line_no,
        },
        {
            title: 'Material Code',
            dataIndex: 'purchase_order_item',
            key: 'purchase_order_item',
            width: 180,
            render: (purchase_order_item) => purchase_order_item.material_code,
        },
        {
            title: 'Description',
            dataIndex: 'purchase_order_item',
            key: 'purchase_order_item',
            ellipsis: true,
            width: 180,
            render: (purchase_order_item) => purchase_order_item.note,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 80,
            render: (quantity) => renderQuantity(quantity),
        },
        {
            title: 'Request Date',
            dataIndex: 'request_delivery_date',
            key: 'request_delivery_date',
            width: 120,
            render: (request_delivery_date) => request_delivery_date ? new Date(request_delivery_date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) : '-',
        },
        {
            title: 'Current Date',
            dataIndex: 'purchase_order_item',
            key: 'purchase_order_item',
            width: 120,
            render: (purchase_order_item) => new Date(purchase_order_item.request_delivery_date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }),
        },
        {
            title: 'Assign Date',
            dataIndex: 'supplier_delivery_date',
            key: 'supplier_delivery_date',
            width: 120,
            render: (date) => new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }),
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
            width: 80,
        },
        {
            title: 'Remark',
            dataIndex: 'remark',
            key: 'remark',
            width: 80,
        },
        // {
        //     title: 'Action',
        //     dataIndex: 'uid',
        //     key: 'action',
        //     render: (record) => (
        //         <div>
        //         </div>

        //     ),
        // },
    ];

    return (
        <div>
            <Table
                dataSource={data}
                columns={columns}
                loading={loading}
                size='small'
                rowKey="uid"
                pagination={{
                    current: currentPage,
                    total: totalItems,
                    pageSize,
                    onChange: handlePaginationChange,
                    onShowSizeChange: handlePaginationChange,
                    showSizeChanger: true, // Enable changing page size
                    pageSizeOptions: ['10', '20', '50', '100'], // Define available page sizes
                }}
                rowSelection={{
                    selectedRowKeys: selectedItems,
                    onChange: (selectedRowKeys) => setSelectedItems(selectedRowKeys),
                }}
            />
            
            <Modal
                title="Assign Date"
                visible={isAssignDateVisible}
                onOk={handleAssignDateModalOk}
                onCancel={handleAssignDateModalCancel}
            >
                <Form>
                    <Form.Item label="Date and Time">
                        <DatePicker
                            onChange={handleAssignDateChange}
                        />
                    </Form.Item>
                </Form>
                <p>{selectedItems.length} items selected</p>
            </Modal>
            <Modal
                title="Confirm Ignore"
                visible={ignoreVisible}
                onOk={handleIgnoreModalOk}
                onCancel={handleIgnoreModalCancel}
            >
                <p>{selectedItems.length} items selected</p>
            </Modal>
        </div>
    );
};

export default ChangesTableVendor;

