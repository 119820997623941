import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const UploadTooltip = () => {
  return (
    <Tooltip
      title={
        <>
          <p>
            Only files with the extension ".xlsx" will be accepted for data uploads.
          </p>
          <p>
            Please note that the upload process is limited to creating and updating data only. It does not delete any current data in the system.
          </p>
          <p>
            To ensure proper data processing, make sure to include all the required headers in your Excel file. You can use the headers in the data table as a reference.
          </p>
        </>
      }
    >
      <InfoCircleOutlined style={{ fontSize: '1.2rem' }} />
    </Tooltip>
  );
};

export default UploadTooltip;