import React from 'react';
import axios from 'axios';
import {Button} from 'antd';
import config from '../config'

const base_url = config.base_url

class DownloadButton extends React.Component {
  handleDownload = () => {
    axios({
      url: `${base_url}/file/download-manual`,
      method: 'GET',
      responseType: 'blob', // Set the response type to blob (binary data)
    })
      .then((response) => {
        // Create a virtual anchor element to initiate the download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'manual.pdf'); // Set the desired file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        // Handle error case
        console.error('Error while downloading the file.', error);
      });
  };

  render() {
    return (
      <Button onClick={this.handleDownload} size="small">
        Download Manual
      </Button>
    );
  }
}

export default DownloadButton;
