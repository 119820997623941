import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../config';

const base_url = config.base_url;

const TicketClosedButton = ({ uid }) => {
    const navigate = useNavigate();

    const handleClose = async () => {
        try {
            await axios.put(`${base_url}/ticket/${uid}`,
                {
                    status: 'Close',
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
            navigate(-1);
        } catch (error) {
            console.error('Error updating ticket:', error);
        }
    };

    return (
        <Popconfirm
            title="Are you sure you want to close this ticket?"
            onConfirm={() => handleClose()}
            okText="Yes"
            cancelText="No"
        >
            <Button
                type="primary"
                danger
            >
                Close Ticket
            </Button>
        </Popconfirm>
    );
};

export default TicketClosedButton;
