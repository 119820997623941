// TicketChatPage.js
import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Input, Button, List, Avatar, Typography, Tag, Spin, Descriptions, message } from 'antd';
import { UserOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import config from '../../config'
import axios from 'axios';
import TicketClosedButton from '../../components/Button/TicketClosedButton';

const base_url = config.base_url

const { Text, Paragraph } = Typography;

const TicketChatPage = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [ticket, setTicket] = useState(null)
  const [ticketPost, setTicketPost] = useState([])
  const token = localStorage.getItem('token');
  const decodedToken = token ? jwtDecode(token) : null;

  useEffect(() => {
    fetchTicketItem();
    fetchPostData();
  }, [uid]);

  const fetchTicketItem = async () => {
    try {
      const itemResponse = await fetch(`${base_url}/ticket/${uid}`);
      const itemData = await itemResponse.json();
      setTicket(itemData);
      console.log(itemData)
      setLoading(false);
    } catch (error) {
      console.error('Error fetching ticket:', error);
      setLoading(false);
    }
  };

  const fetchPostData = async () => {
    try {
      const response = await axios.get(`${base_url}/ticket-post/pagination`, {
        params: {
          skip: 0,
          limit: 9999,
          ticket_uid: uid
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setTicketPost(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;

    // Assuming you have a function to send a message to the server
    // sendMessage(newMessage);

    try {
      const response = await axios.post(`${base_url}/ticket-post`, {
        text: newMessage,
        ticket_uid: uid,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          // Add other headers if needed
        },
      });
      if (response.status === 201) {
        // message.success('Successfully Split Item');
        fetchPostData();
        setNewMessage('');
      } else {
        message.error(JSON.stringify(response.data.detail));
        console.log(response.status);
        
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error(JSON.stringify(error.response.data.detail));
    }
  };
  const getTitleColor = (role) => {
    if (role === 'Admin' || role === 'Purchaser') {
      return { color: 'lightgreen' };
    }
    // Default color for other roles
    return { color: 'black' };
  }

  const goBack = () => {
    navigate(-1); // Navigate back by one step
  };
  if (loading) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Spin />
    </div>
  }
  const statusTag = <Tag color={ticket.status === 'Open' ? 'green' : 'red'}>{ticket.status}</Tag>;
  return (
    <div>
      <Row gutter={16}>
        <Col span={21}>
          <Button type="text" icon={<ArrowLeftOutlined />} onClick={goBack} style={{ marginBottom: '16px' }}>
            Back
          </Button>
        </Col>
        <Col span={3}>
          {ticket.status === "Open" && (decodedToken.role.name === "Admin" || decodedToken.role.name === "Purchaser") && (
            <TicketClosedButton uid={uid} />
          )}
        </Col>
      </Row>
      <div>
        <Descriptions title={<span>Ticket Details {statusTag}</span>} bordered size="small">
          <Descriptions.Item label="Topic" span={3}>{ticket.topic}</Descriptions.Item>
          <Descriptions.Item label="Description" >
            <Paragraph style={{ whiteSpace: 'pre-wrap', margin: 0 }}>
              {ticket.description}
            </Paragraph>
          </Descriptions.Item>

          {/* Add more ticket details as needed */}
        </Descriptions>
      </div>
      <h4>Post</h4>
      <Row gutter={16} style={{ marginTop: 20 }}>
        <Col span={24}>
          <Card style={{ height: '55vh', overflow: 'auto' }} size='small'>
            <List
              style={{ maxHeight: '60vh', overflowY: 'auto' }}
              itemLayout="horizontal"
              dataSource={ticketPost}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar icon={<UserOutlined />} />}
                    title={<p style={getTitleColor(item.role)}>{item.sender} ({item.role})</p>}
                    description={
                      <Paragraph style={{ whiteSpace: 'pre-wrap', margin: 0 }}>
                        {item.text}
                      </Paragraph>
                    }
                  />
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
      <Input.TextArea
        placeholder="Type your message..."
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        disabled={ticket.status !== "Open"}
      />
      <Button type="primary" onClick={handleSendMessage} disabled={ticket.status !== "Open"} style={{ marginTop: 10, width: "100%" }}>
        Send
      </Button>
    </div>
  );
};

export default TicketChatPage;
