import React from 'react';
import { Button, Popconfirm, message } from 'antd';
import axios from 'axios';
import config from '../../config';
import jwtDecode from 'jwt-decode';

const base_url = config.base_url;

const AttachmentDeleteButton = ({ uid , delete_role, refresh }) => {
    const token = localStorage.getItem('token');
    const decodedToken = token ? jwtDecode(token) : null;

    const handleDelete = async () => {
        try {
            await axios.delete(`${base_url}/file/${uid}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            message.success('Delete Success');
            refresh();
        } catch (error) {
            console.error('Error deleting shipment:', error);
            message.error('Delete Failed');
        }
    };

    return (
        <Popconfirm
            title="Are you sure you want to delete this file?"
            onConfirm={() => handleDelete()}
            okText="Yes"
            cancelText="No"
        >
            <Button
                type="link"
                style={{'padding': '0px'}}
                hidden={decodedToken.role.name !== "Admin" && decodedToken.role.name !== delete_role}
                danger
            >
                Delete
            </Button>
        </Popconfirm>
    );
};

export default AttachmentDeleteButton;
