import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Badge, Table, Input, Tabs, Card, Button, Modal, Popconfirm, message, Select, Row, Col } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import config from '../../config'
import UnreleaseTable from '../../components/Table/UnreleaseTable';
import StatusTableWithPurchaseName from '../../components/Table/StatusTableWithPurchaseName';

const base_url = config.base_url
const { TabPane } = Tabs;
const { Option } = Select;

const EmployeePurchaseOrderItemPage = () => {
  const token = localStorage.getItem('token');
  const [purchaseGroup, setPurchaseGroup] = useState([]);
  const [searchValuePO, setSearchValuePO] = useState(null);
  const [searchValueVendor,setSearchValueVendor] = useState(null)
  const [searchValuePG,setSearchValuePG] = useState(null)
  const [currentTab, setCurrentTab] = useState('unassign');
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedPG, setSelectedPG] = useState(null);
  const [releaseCount, setReleaseCount] = useState(0)
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState(null)
  const [unreleaseCount, setUnreleaseCount] = useState(0);
  const [unassignCount, setUnassignCount] = useState(0);
  const [assignCount, setAssignCount] = useState(0);
  const [commitCount, setCommitCount] = useState(0);
  const [shippedCount, setShippedCount] = useState(0);

  useEffect(() => {
    fetchPurchaseGroup();
    fetchVendors();
  }, []);
  
  const handleRelease = () => {
    setReleaseCount(releaseCount + 1)
  };

  const fetchPurchaseGroup = async () => {
    try {
      const response = await axios.get(`${base_url}/purchase-group`, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        }
      });
      setPurchaseGroup(response.data);
    } catch (error) {
      console.error('Error fetching purchase group:', error);
      // Handle fetch error here
    }
  };

  const fetchVendors = async (searchValuePO) => {
    try {
      const response = await axios.get(`${base_url}/vendor`, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
        params: {
          name: searchValuePO,
        },
      });
      setVendors(response.data);
    } catch (error) {
      console.error('Error fetching vendors:', error);
    }
  };

  const setUnrelease = (value) => {
    setUnreleaseCount(value);
  };
  const setUnassign = (value) => {
    setUnassignCount(value);
  };
  const setAssign = (value) => {
    setAssignCount(value);
  };
  const setCommit = (value) => {
    setCommitCount(value);
  };
  const setShipped = (value) => {
    setShippedCount(value);
  };

  const handleSearch = (value) => {
    setSearchValuePO(value);
  };

  const handleTabChange = (key) => {
    setCurrentTab(key);
  };

  const handleVendorChange = (value) => {
    setSearchValueVendor(value);
  };

  const handleVendorClear = () => {
    setSearchValueVendor(null);
  };

  const handlePurchaseGroupChange = (value) => {
    setSearchValuePG(value);
  };

  const handlePurchaseGroupClear = () => {
    setSearchValuePG(null);
  };

  const handleApplyFilters = () => {
    setPurchaseOrderNumber(searchValuePO);
    setSelectedVendor(searchValueVendor);
    setSelectedPG(searchValuePG);
  };

  const releaseButton = <Button
    type="primary"
    hidden={currentTab !== 'unrelease'}
    onClick={() => handleRelease()}
    style={{ marginLeft: 'auto' }}
  >
    Release
  </Button>

  return (
    <div>
      <h2>Purchase Order Items</h2>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>

        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '16px' }}>

          <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '16px' }}>
            <Input
              placeholder="Purchase Order Number"
              onChange={(e) => handleSearch(e.target.value)}
              style={{ width: '200px' }}
            />
            <Select
              placeholder="Select Vendor"
              onSelect={handleVendorChange}
              onClear={handleVendorClear}
              style={{ width: '200px' }}
              allowClear
              onSearch={fetchVendors}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {vendors.map((vendor) => (
                <Option key={vendor.uid} value={vendor.uid} label={vendor.name}>
                  {vendor.name}
                </Option>
              ))}
            </Select>

            <Select
              placeholder="Select Purchase Group"
              onSelect={handlePurchaseGroupChange}
              onClear={handlePurchaseGroupClear}
              style={{ width: '200px' }}
              allowClear
              showSearch
            >
              {purchaseGroup.map((pg) => (
                <Option key={pg.uid} value={pg.purchase_group} label={pg.name}>
                  {pg.name}
                </Option>
              ))}
            </Select>

            <Button type="primary" onClick={handleApplyFilters}>
              Apply Filters
            </Button>
          </div>

        </div>

      </div>

      <Tabs destroyInactiveTabPane={true} activeKey={currentTab} onChange={handleTabChange} tabBarExtraContent={releaseButton}>
        <TabPane tab={<span>Unassign <Badge count={unassignCount} style={{ backgroundColor: '#40a9ff' }} /></span>} key="unassign">
          <StatusTableWithPurchaseName vendor_uid={selectedVendor} purchase_group={purchaseGroup} release_count={releaseCount} purchase_order_no={purchaseOrderNumber} filter_purchase_group={selectedPG} status='Unassign' setCount={setUnassign}/>
        </TabPane>
        <TabPane tab={<span>Assign <Badge count={assignCount} style={{ backgroundColor: '#40a9ff' }} /></span>} key="assign">
        <StatusTableWithPurchaseName vendor_uid={selectedVendor} purchase_group={purchaseGroup} release_count={releaseCount} purchase_order_no={purchaseOrderNumber} filter_purchase_group={selectedPG} status='Assign' setCount={setAssign}/>
        </TabPane>
        <TabPane tab={<span>Commit <Badge count={commitCount} style={{ backgroundColor: '#40a9ff' }} /></span>} key="commit">
        <StatusTableWithPurchaseName vendor_uid={selectedVendor} purchase_group={purchaseGroup} release_count={releaseCount} purchase_order_no={purchaseOrderNumber} filter_purchase_group={selectedPG} status='Commit' setCount={setCommit}/>
        </TabPane>
        <TabPane tab={<span>Shipped <Badge count={shippedCount} style={{ backgroundColor: '#40a9ff' }} /></span>} key="shipped">
        <StatusTableWithPurchaseName vendor_uid={selectedVendor} purchase_group={purchaseGroup} release_count={releaseCount} purchase_order_no={purchaseOrderNumber} filter_purchase_group={selectedPG} status='Shipped' setCount={setShipped}/>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default EmployeePurchaseOrderItemPage;



