import React from 'react';
import { Form, Input, Button, message } from 'antd';
import config from '../../config'

const base_url = config.base_url

const AccountPage = () => {
  const [form] = Form.useForm();
  const token = localStorage.getItem('token');

  const onFinish = (values) => {
    const newPassword = values.newPassword;

  fetch(`${base_url}/user/change-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(newPassword ),
  })
    .then((response) => {
      if (response.ok) {
        // Password change successful
        form.resetFields();
        message.success('Password has been changed successfully');
      } else {
        // Password change failed
        message.error('Failed to change password');
      }
    })
    .catch((error) => {
      // Error occurred
      console.error('Error changing password:', error);
      message.error('An error occurred while changing password');
    });
  };

  return (
    <div>
      <h2>Reset Password</h2>
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name="newPassword"
          label="New Password"
          rules={[
            { required: true, message: 'Please enter a new password' },
            { min: 6, message: 'Password must be at least 6 characters long' },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          label="Confirm Password"
          dependencies={['newPassword']}
          rules={[
            { required: true, message: 'Please confirm your new password' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  'The passwords you entered do not match'
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Reset Password
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AccountPage;
