import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Button, Table, Tag, Spin, Descriptions, Row, Col } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import config from '../../config'
import PurchaseItemFileListCard from '../../components/Card/PurchaseItemFileListCard';

const base_url = config.base_url

const PurchaseOrderItemPage = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const [purchaseOrderItem, setPurchaseOrderItem] = useState(null);
  const [purchaseOrderHeader, setPurchaseOrderHeader] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deliverySchedule, setDeliverySchedule] = useState([]);

  useEffect(() => {
    const fetchPurchaseOrderItem = async () => {
      try {
        const itemResponse = await fetch(`${base_url}/purchase-order-item/${uid}`);
        const itemData = await itemResponse.json();
        setPurchaseOrderItem(itemData);

        const headerResponse = await fetch(`${base_url}/purchase-order-header/${itemData.purchase_order_header_uid}`);
        const headerData = await headerResponse.json();
        setPurchaseOrderHeader(headerData);

        const deliveryResponse = await fetch(`${base_url}/delivery-schedule?purchase_order_item_uid=${itemData.uid}`);
        const deliveryData = await deliveryResponse.json();
        setDeliverySchedule(deliveryData);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching purchase order item:', error);
        setLoading(false);
      }
    };

    fetchPurchaseOrderItem();
  }, [uid]);

  const goBack = () => {
    navigate(-1); // Navigate back by one step
  };

  if (loading) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Spin />
    </div>
  }

  if (!purchaseOrderItem) {
    return <div>Item not found.</div>;
  }

  const formatDateString = (dateString) => {
    return moment(dateString).format('DD-MM-YYYY');
  };
  function renderQuantity(quantity) {
    if (Number.isInteger(quantity)) {
      return quantity.toString(); // If it's an integer, no decimal places
    } else {
      return quantity.toFixed(3); // If it's a float, round to 3 decimal places
    }
  }

  const deliveryScheduleColumns = [
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (quantity) => renderQuantity(quantity),
    },
    {
      title: 'Supplier Delivery Date',
      dataIndex: 'supplier_delivery_date',
      key: 'supplier_delivery_date',
      render: text => moment(text).format('DD-MM-YYYY'),
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      render: (text) => text || '-',
    },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
      render: (text) => text || '-',
    },
    {
      title: 'Commit',
      dataIndex: 'confirmation_type',
      key: 'confirmation_type',
      render: (text) => text ? 'Yes' : 'No',
    },
    {
      title: 'Shipped',
      dataIndex: 'shipment_uid',
      key: 'shipment_uid',
      render: (text) => text ? 'Yes' : 'No',
    }
  ];

  return (
    <div>
      <Button type="text" icon={<ArrowLeftOutlined />} onClick={goBack} style={{ marginBottom: '16px' }}>Back</Button> {/* Add Back Button */}
      <h1><Tag color='geekblue'>Purchase Order No: {purchaseOrderHeader?.purchase_order_no}</Tag> <Tag>{purchaseOrderItem.status}</Tag></h1>
      <div style={{ display: 'flex', marginBottom: '24px' }}>
        <Row>
          <Col span={12}>
            <div style={{ padding: '8px' }}>
              <Card title={`Purchase Order`}>
                <Descriptions size='small' layout="vertical">
                  <Descriptions.Item label="Vendor Name">{purchaseOrderHeader?.vendor_name}</Descriptions.Item>
                  <Descriptions.Item label="Vendor Code">{purchaseOrderHeader?.vendor_code}</Descriptions.Item>
                  <Descriptions.Item label="Purchase Order Date">{formatDateString(purchaseOrderHeader?.purchase_order_date)}</Descriptions.Item>
                  <Descriptions.Item label="Payment Term">{purchaseOrderHeader?.payment_term}</Descriptions.Item>
                  <Descriptions.Item label="Purchase Group">{purchaseOrderHeader?.purchase_group}</Descriptions.Item>
                  <Descriptions.Item label="Incoterm">{purchaseOrderHeader?.incoterm}</Descriptions.Item>
                  <Descriptions.Item label="Original Location">{purchaseOrderHeader?.incoterm_description}</Descriptions.Item>
                </Descriptions>
              </Card>
            </div>
          </Col>
          <Col span={12}>
            <div style={{ padding: '8px' }}>
              <Card title="Details">
                <Descriptions size='small' layout="vertical">
                  <Descriptions.Item label="Line No">{purchaseOrderItem.line_no}</Descriptions.Item>
                  <Descriptions.Item label="Material Code">{purchaseOrderItem.material_code}</Descriptions.Item>
                  <Descriptions.Item label="Quantity">{purchaseOrderItem.quantity}</Descriptions.Item>
                  <Descriptions.Item label="Unit">{purchaseOrderItem.unit}</Descriptions.Item>
                  {/* <Descriptions.Item label="Unit Price">{purchaseOrderItem.unit_price || '-'}</Descriptions.Item> */}
                  <Descriptions.Item label="Request Delivery Date">{formatDateString(purchaseOrderItem?.request_delivery_date)}</Descriptions.Item>
                  <Descriptions.Item label="Description">{purchaseOrderItem.note}</Descriptions.Item>
                  {/* Display other purchase order item details */}
                </Descriptions>
              </Card>
            </div>
          </Col>
          <Col span={12}>
            <div style={{ padding: '8px' }}>
              <PurchaseItemFileListCard purchase_order_header_uid={purchaseOrderItem.purchase_order_header_uid} />
            </div>
          </Col>
          <Col span={12}>
            <div style={{ padding: '8px' }}>
              <Card title="Delivery Schedule">
                {deliverySchedule.length === 0 ? (
                  <p>No delivery schedule available</p>
                ) : (
                  <Table dataSource={deliverySchedule} columns={deliveryScheduleColumns} rowKey="uid" pagination={false} size='small' />
                )}
              </Card>
            </div>
          </Col>
        </Row>
      </div>
      {/* Upload File Modal */}
    </div>
  );
};

export default PurchaseOrderItemPage;
