import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Button, Modal, Form, Input, Upload, message, Tag, Descriptions, Select, Row, Col, Spin } from 'antd';
import jwtDecode from 'jwt-decode';
import { UploadOutlined, DownloadOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import config from '../../config'
import ShipmentUpdateButton from '../../components/Button/ShipmentUpdateButton';
import ShipmentUpdateButton2 from '../../components/Button/ShipmentUpdateButton2';
import ShipmentUpdateButton3 from '../../components/Button/ShipmentUpdateButton3';
import ShipmentDeleteButton from '../../components/Button/ShipmentDeleteButton';
import DeliveryScheduleCard from '../../components/Card/DeliveryScheduleCard';
import ShipmentFileListCard from '../../components/Card/ShipmentFileListCard';

const base_url = config.base_url
const { Option } = Select;

const ShipmentDetailPage = () => {
    const { uid } = useParams();
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [shipmentDetail, setShipmentDetail] = useState(null);
    const [loading, setLoading] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalStatusVisible, setModalStatusVisible] = useState(false);
    const [uploadForm] = Form.useForm();
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [airWayBill, setAirWayBill] = useState('');
    const [remark, setRemark] = useState('');
    const [shipmentType, setShipmentType] = useState('');
    const decodedToken = token ? jwtDecode(token) : null;

    // Fetch shipment detail and delivery schedule
    useEffect(() => {
        fetchShipmentDetail();
    }, []);

    const fetchShipmentDetail = async () => {
        try {
            const detailResponse = await fetch(`${base_url}/shipment/${uid}`);
            const detailData = await detailResponse.json();
            setShipmentDetail(detailData);

            setLoading(false);
        } catch (error) {
            console.error('Error fetching shipment detail:', error);
            setLoading(false);
        }
    };

    const goBack = () => {
        navigate(-1); // Navigate back by one step
    };

    // Function to handle form validation
    const handleFormValidation = async () => {
        try {
            const values = await uploadForm.validateFields();
            const { detail, file_type, file: fileList } = values;

            const file = fileList[0].originFileObj; // Get the first file in the array
            if (!file) {
                throw new Error("No file selected for upload");
            }

            return { detail, file_type, file, fileName: fileList[0].name };
        } catch (error) {
            console.error(error);
            message.error('Please fill in all the required fields');
        }
    };
    // Function to upload the file to the server
    const handleFileUpload = async ({ detail, file_type, file, fileName }) => {
        const queryParams = new URLSearchParams();
        queryParams.append('attach_type', 'Shipment');
        queryParams.append('detail', detail ? detail : '');
        queryParams.append('file_type', file_type);

        const url = `${base_url}/file/attach-file/${shipmentDetail.uid}?${queryParams.toString()}`;
        const formData = new FormData();

        formData.append('file', file, fileName);

        try {
            const response = await fetch(url, { method: 'POST', body: formData });
            if (!response.ok) {
                throw new Error('Failed to attach file');
            }
            message.success('File attached successfully');
        } catch (error) {
            console.error('Error attaching file:', error);
            message.error('An error occurred while attaching file');
        }
    };

    const handleEditSubmit = () => {
        // Prepare the request body
        const requestBody = {};

        if (remark !== null && remark !== '') {
            requestBody.remark = remark;
        }

        if (airWayBill !== null && airWayBill !== '') {
            requestBody.air_way_bill = airWayBill;
        }

        if (shipmentType !== null && shipmentType !== '') {
            requestBody.shipment_type = shipmentType;
        }

        const token = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };

        // Send the PUT request
        fetch(`${base_url}/shipment/${uid}`, {
            method: 'PUT',
            headers,
            body: JSON.stringify(requestBody),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to update shipment');
                }
                message.success('Shipment updated successfully');
                setEditModalVisible(false); // Hide the modal
                fetchShipmentDetail(); // Refetch the shipment data
            })
            .catch((error) => {
                console.error('Error updating shipment:', error);
                message.error('An error occurred while updating shipment');
            });
    };

    const handleExport = async () => {
        // create URL
        const url = `${base_url}/utils/export-shipment/${uid}`;
        const token = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };
        // request for file
        fetch(url, {
            method: 'POST',
            headers: headers,
        })
            .then((response) => {
                if (!response.ok) throw new Error(response.status);
                return response.blob();
            })
            .then((blob) => {
                // create a downloadable link for the blob
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = "shipment_export.xlsx"; // use the filename from the record
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((error) => console.error('Error:', error));
    }

    const handleModalSubmit = async () => {
        const fileData = await handleFormValidation();
        if (!fileData) return;
        setLoadingUpload(true); // Set loading state to true during file upload
        await handleFileUpload(fileData);

        setModalVisible(false);
        uploadForm.resetFields();

        setLoadingUpload(false);
    };

    const normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };


    const handleStatusSubmit = () => {
        const nextStatus = getNextStatusOptions(shipmentDetail.status);
        if (nextStatus) {
            if (nextStatus === 'Pickup') {
                uploadForm.validateFields(['remark', 'air_way_bill']).then((values) => {
                    const { remark, air_way_bill } = values;
                    const requestBody = {
                        status: nextStatus,
                        remark,
                        air_way_bill,
                    };

                    const token = localStorage.getItem('token');
                    const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    };

                    fetch(`${base_url}/shipment/status/${uid}`, {
                        method: 'PUT',
                        headers,
                        body: JSON.stringify(requestBody),
                    })
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error('Failed to update status');
                            }
                            message.success(`Status updated to ${nextStatus}`);
                            setModalStatusVisible(false);
                            fetchShipmentDetail(); // Refetch the shipment data
                        })
                        .catch((error) => {
                            console.error('Error updating status:', error);
                            message.error('An error occurred while updating status');
                        });
                }).catch((error) => {
                    console.error(error);
                    message.error('Please fill in all the required fields');
                });
            } else {
                const requestBody = {
                    status: nextStatus,
                };

                const token = localStorage.getItem('token');
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                };

                fetch(`${base_url}/shipment/status/${uid}`, {
                    method: 'PUT',
                    headers,
                    body: JSON.stringify(requestBody),
                })
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error('Failed to update status');
                        }
                        message.success(`Status updated to ${nextStatus}`);
                        setModalStatusVisible(false);
                        fetchShipmentDetail(); // Refetch the shipment data
                    })
                    .catch((error) => {
                        console.error('Error updating status:', error);
                        message.error('An error occurred while updating status');
                    });
            }
        } else {
            message.error('Please select a status');
        }
    };

    if (loading) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Spin />
        </div>
    }

    if (!shipmentDetail) {
        return <div>Shipment detail not found.</div>;
    }


    const getNextStatusOptions = (currentStatus) => {
        const nextStatusOptionsMap = {
            Packed: 'Flagged',
            Flagged: 'Pickup',
            Pickup: 'Complete'
        };
        // console.log(nextStatusOptionsMap[currentStatus])
        return nextStatusOptionsMap[currentStatus] || [];
    };

    const isAdminOrPurchaser = decodedToken.role.name === 'Admin' || decodedToken.role.name === 'Purchaser';
    const isPackAdminVendorPurchaser = (shipmentDetail.status === 'Packed' && (decodedToken.role.name === 'Purchaser' || decodedToken.role.name === 'Vendor')) || decodedToken.role.name === 'Admin';
    const isFreightForwarder = decodedToken.role.name === 'Freight Forwarder';

    return (
        <div>
            <Button type="text" icon={<ArrowLeftOutlined />} onClick={goBack} style={{ marginBottom: '16px' }}>
                Back
            </Button>
            <h1 style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span>
                    <Tag color='geekblue'>Shipment No: {shipmentDetail.number}</Tag>
                    {shipmentDetail.ccs_number ? (
                        <Tag color='green'>{shipmentDetail.ccs_number}</Tag>
                    ) : null}
                    <Tag>{shipmentDetail.status}</Tag>
                </span>
                {/* <Button hidden={getHiddenEdit} onClick={() => setEditModalVisible(true)} type="primary" style={{ marginRight: '24px', width: '120px' }}>Edit</Button> */}
                {/* <Button hidden={getHiddenUpdate} onClick={handleUpdateStatus} type="primary" style={{ marginRight: '24px' }}>Update Status</Button> */}
                <div>
                    <div>
                        {isAdminOrPurchaser && (
                            <>
                                {shipmentDetail.status === 'Packed' && (
                                    <ShipmentUpdateButton uid={uid} shipment_detail={shipmentDetail} refresh={fetchShipmentDetail} />
                                )}
                                {shipmentDetail.status === 'Flagged' && (
                                    <ShipmentUpdateButton2 uid={uid} shipment_detail={shipmentDetail} refresh={fetchShipmentDetail} is_ff={false} />
                                )}
                                {shipmentDetail.status === 'Pickup' && (
                                    <ShipmentUpdateButton3 uid={uid} shipment_detail={shipmentDetail} refresh={fetchShipmentDetail} />
                                )}
                            </>
                        )}
                    </div>

                    {isFreightForwarder && (
                        <ShipmentUpdateButton2 uid={uid} shipment_detail={shipmentDetail} refresh={fetchShipmentDetail} is_ff={true} />
                    )}
                </div>
            </h1>

            <Row>
                <Col span={24}>
                    <div style={{ padding: '8px' }}>
                        <Card title={
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <span>Shipment Details</span>
                                <div><Button onClick={handleExport} type='primary' icon={<DownloadOutlined />} shape="circle" style={{ marginRight: '12px' }}></Button>{isPackAdminVendorPurchaser ? <ShipmentDeleteButton uid={uid} /> : null}</div>
                            </div>
                        } >
                            <Descriptions size="small" layout="vertical" >
                                <Descriptions.Item label="Vendor Name">{shipmentDetail.vendor.name || '-'}</Descriptions.Item>
                                <Descriptions.Item label="Vendor Code">{shipmentDetail.vendor.code || '-'}</Descriptions.Item>
                                <Descriptions.Item label="Pickup Location">{shipmentDetail.pickup_location || '-'}</Descriptions.Item>
                                <Descriptions.Item label="Agent Contact">{shipmentDetail.agent_contact || '-'}</Descriptions.Item>
                                <Descriptions.Item label="Freight Forwarder">{shipmentDetail.freight_forwarder && shipmentDetail.freight_forwarder.name
                                    ? shipmentDetail.freight_forwarder.name
                                    : '-'}</Descriptions.Item>
                                <Descriptions.Item label="Shipment Type">{shipmentDetail.shipment_type || '-'}</Descriptions.Item>
                                <Descriptions.Item label="Air Way Bill">{shipmentDetail.air_way_bill || '-'}</Descriptions.Item>
                                <Descriptions.Item label="CCS Remark">{shipmentDetail.remark || '-'}</Descriptions.Item>
                                <Descriptions.Item label="Vendor Remark">{shipmentDetail.vendor_remark || '-'}</Descriptions.Item>
                                {/* Display other shipment details */}
                            </Descriptions>
                        </Card>
                    </div>
                </Col>
                <Col span={8}>
                    <div style={{ padding: '8px' }}>
                        <ShipmentFileListCard shipment_uid={uid} />
                    </div>
                </Col>
                <Col span={16}>
                    <div style={{ padding: '8px' }}>
                        <DeliveryScheduleCard shipment_uid={uid} editable={isAdminOrPurchaser} />
                    </div>
                </Col>

            </Row>
            {/* Upload File Modal */}
            <Modal
                title="Upload File"
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                onOk={handleModalSubmit}
                confirmLoading={loadingUpload}
            >
                <Form form={uploadForm} layout="vertical">
                    <Form.Item
                        name="file"
                        label="File"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        multiple={false}
                        rules={[{ required: true, message: 'Please select a file' }]}
                    >
                        <Upload
                            beforeUpload={() => false} // Prevent the file from being uploaded automatically
                            accept=".pdf,.doc,.docx"
                            maxCount={1} // This prop restricts the user to select only one file at a time
                        >
                            <Button icon={<UploadOutlined />}>Select File</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        name="file_type"
                        label="File Type"
                        rules={[{ required: true, message: 'Please enter a file type' }]}
                    >
                        <Select>
                            <Option value="Packing List">Packing List</Option>
                            <Option value="Certificate">Certificate</Option>
                            <Option value="Inspection Report">Inspection Report</Option>
                            <Option value="Invoice">Invoice</Option>
                            <Option value="Others">Others</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="detail"
                        label="Detail"
                    // rules={[{ required: true, message: 'Please enter a detail' }]}
                    >
                        <Input.TextArea rows={4} />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Update Status"
                visible={modalStatusVisible}
                onCancel={() => setModalStatusVisible(false)}
                onOk={handleStatusSubmit}
                confirmLoading={loadingUpload}
            >
                <Form form={uploadForm} layout="vertical">
                    <Form.Item
                        name="status"
                        label="Next Status"
                        rules={[{ required: true, message: 'Please select the next status' }]}
                    >
                        <Card size='small' style={{ textAlign: 'center' }}>
                            {getNextStatusOptions(shipmentDetail.status)}
                        </Card>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="Edit Shipment"
                visible={editModalVisible}
                onCancel={() => {
                    setEditModalVisible(false);
                }}
                onOk={handleEditSubmit}
            >
                <Form layout="vertical">
                    <Form.Item
                        name="shipmentType"
                        label="Shipment Type"
                    // rules={[{ required: true, message: 'Please enter the air way bill' }]}
                    >
                        <Select value={shipmentType} onChange={setShipmentType}>
                            <Option value="Air">Air</Option>
                            <Option value="Sea">Sea</Option>
                            <Option value="Truck">Truck</Option>
                            <Option value="Rail">Rail</Option>
                            <Option value="Hand Carry">Hand Carry</Option>
                            <Option value="Air Courier">Air Courier</Option>
                            <Option value="Others">Others</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="airWayBill"
                        label="Air Way Bill"
                    // rules={[{ required: true, message: 'Please enter the air way bill' }]}
                    >
                        <Input value={airWayBill} onChange={e => setAirWayBill(e.target.value)} />
                    </Form.Item>
                    <Form.Item
                        name="remark"
                        label="Remark"
                    >
                        <Input.TextArea value={remark} onChange={e => setRemark(e.target.value)} rows={4} />
                    </Form.Item>
                </Form>
            </Modal>

        </div>
    );
};

export default ShipmentDetailPage;
