import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message, Modal, Select, Radio } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import config from '../../config';

const base_url = config.base_url;

const TicketCreateButton = ({ refresh }) => {
  const [form] = Form.useForm();
  const [isFormVisible, setFormVisible] = useState(false);
  const [searchVendor, setSearchVendor] = useState('');
  const [searchFreightForwarder, setSearchFreightForwarder] = useState('');
  const [selectedOption, setSelectedOption] = useState(1);
  const [vendors, setVendors] = useState([]);
  const [freightForwarders, setFreightForwarders] = useState([]);
  const token = localStorage.getItem('token');
  const decodedToken = token ? jwtDecode(token) : null;

  useEffect(() => {
    if (decodedToken.role.name === 'Admin' || decodedToken.role.name === 'Purchaser'){
    fetchVendors();
  }
  }, [searchVendor]);

  useEffect(() => {
    if (decodedToken.role.name === 'Admin' || decodedToken.role.name === 'Purchaser'){
    fetchFreightForwarders();
  }
  }, [searchFreightForwarder]);

  const fetchVendors = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get(`${base_url}/vendor`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        params: {
          name: searchVendor,
        },
      });
      setVendors(response.data);
    } catch (error) {
      console.error('Error fetching vendors:', error);
    }
  };

  const fetchFreightForwarders = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get(`${base_url}/freight-forwarder`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        params: {
          name: searchFreightForwarder,
        },
      });
      setFreightForwarders(response.data);
    } catch (error) {
      console.error('Error fetching vendors:', error);
    }
  };

  const openForm = () => {
    setFormVisible(true);
  };

  const closeForm = () => {
    setFormVisible(false);
  };

  const onFinish = async (values) => {
    // Send the data to your API here
    console.log('Form data:', values);
    // You can use a library like Axios to send data to your API
    const owner = selectedOption === 1 ? vendors.find((vendor) => vendor.uid === values.vendor_uid) : freightForwarders.find((freight_forwarder) => freight_forwarder.uid === values.freight_forwarder_uid);
    console.log(owner)
    try {
      const requestData =
        decodedToken.role.name === 'Admin' || decodedToken.role.name === 'Purchaser'
          ? {
            topic: values.topic,
            description: values.description,
            owner_name: owner.name,
            owner_uid: owner.uid,
            owner_role: selectedOption,
            status: 'Open'
          }
          : {
            topic: values.topic,
            description: values.description,
            status: 'Open'
          };

      await axios.post(`${base_url}/ticket`, requestData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      closeForm();
      refresh();
      form.resetFields();
      message.success('Ticket Created');
    } catch (error) {
      console.error('Error create ticket:', error);
    }
  };

  return (
    <div>
      <Button type='primary' onClick={openForm} style={{ width: '90%' }} icon={<PlusOutlined />}>New</Button>
      <Modal
        title="Create Ticket"
        visible={isFormVisible}
        onOk={closeForm}
        onCancel={closeForm}
        footer={null}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item
            label="Topic"
            name="topic"
            rules={[{ required: true, message: 'Please enter the topic' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: 'Please enter the description' }]}
          >
            <Input.TextArea />
          </Form.Item>
          {((decodedToken.role.name === "Purchaser" || decodedToken.role.name === "Admin")) && (
            <Radio.Group onChange={(e) => { setSelectedOption(e.target.value); }} value={selectedOption}>
              <Radio value={"Vendor"}>Vendor</Radio>
              <Radio value={"Freight Forwarder"}>Freight Forwarder</Radio>
            </Radio.Group>
          )}
          {(selectedOption === "Vendor" && (decodedToken.role.name === "Purchaser" || decodedToken.role.name === "Admin")) && (
            <Form.Item
              name="vendor_uid"
              label="Vendor"
              rules={[{ required: true, message: 'Please select Vendor' }]}
            >
              <Select
                onSearch={(v) => setSearchVendor(v)}
                showSearch
                value={searchVendor}
                filterOption={(input, option) =>
                  option.label && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {vendors.length > 0 &&
                  vendors.map((v) => (
                    <Select.Option key={v.uid} value={v.uid} label={v.name}>
                      {v.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          )}
          {(selectedOption === "Freight Forwarder" && (decodedToken.role.name === "Purchaser" || decodedToken.role.name === "Admin")) && (
            <Form.Item
              name="freight_forwarder_uid"
              label="Freight Forwarder"
              rules={[{ required: true, message: 'Please select Vendor' }]}
            >
              <Select
                onSearch={(v) => setSearchFreightForwarder(v)}
                showSearch
                value={searchFreightForwarder}
                filterOption={(input, option) =>
                  option.label && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {freightForwarders.length > 0 &&
                  freightForwarders.map((f) => (
                    <Select.Option key={f.uid} value={f.uid} label={f.name}>
                      {f.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TicketCreateButton;
