import React, { useState, useEffect } from 'react';
import { Table, Button, Popconfirm, message, Modal, Tooltip, DatePicker, Form, Input, List, Descriptions } from 'antd'; // Import Button from 'antd'
import { useNavigate, Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, FormOutlined, CheckCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';
import config from '../../config';
import CopyToClipboardButton from '../Button/CopyToClipBoardButton';

const base_url = config.base_url;

const AssignTableVendor = (params) => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [header, setHeader] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10); // Number of items per page
    const [selectedItems, setSelectedItems] = useState([])
    const [form] = Form.useForm();
    const [editedRecord, setEditedRecord] = useState(null);
    const [editedRemark, setEditedRemark] = useState(null);
    const [remark, setRemark] = useState('');
    const [isAssignDateVisible, setIsAssignDateVisible] = useState(false);
    const [assignDateValue, setAssignDateValue] = useState(null);
    const [remarkModalVisible, setRemarkModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [commitModalVisible, setCommitModalVisible] = useState(false);

    useEffect(() => {
        fetchData();
    }, [currentPage, pageSize, params.vendor_uid, params.purchase_order_no, params.filter_purchase_group]);

    useEffect(() => {
        params.setCount(header)
    }, [header])

    useEffect(() => {
        showAssignDateModal();
    }, [params.assign_count])

    useEffect(() => {
        showCommitModal();
    }, [params.commit_count])

    const fetchData = async () => {
        try {
            setLoading(true);
            const skip = (currentPage - 1) * pageSize;
            const response = await axios.get(`${base_url}/delivery-schedule/pagination`, {
                params: {
                    skip,
                    limit: pageSize,
                    vendor_uid: params.vendor_uid,
                    purchase_order_no: params.purchase_order_no,
                    purchase_group: params.filter_purchase_group,
                    status: "Assign"
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    // Add other headers if needed
                },
            });
            setData(response.data.data);
            setTotalItems(response.data.total_count);
            setHeader(response.data.header);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const showAssignDateModal = () => {
        if (selectedItems.length > 0) {
            setIsAssignDateVisible(true);
            console.log(selectedItems)
        } else {
            if (params.assign_count > 0) {
                message.error('No item selected.');
            }
        }
    };

    const handleAssignDateChange = (date) => {
        setAssignDateValue(date);
    };

    const handleAssignDateModalOk = () => {
        // Make PUT request to the API
        if (assignDateValue) {
            const formattedDate = moment(assignDateValue?.format('YYYY-MM-DD')).add(7, 'hours').toISOString()

            const body = {
                delivery_date: formattedDate,
                uids: selectedItems
            }
            axios.put(`${base_url}/delivery-schedule/multi-assign-date`, body)
                .then(() => {
                    message.success('Update assign date successfully');
                    setIsAssignDateVisible(false);
                    fetchData();
                    setSelectedItems([]);
                    setAssignDateValue(null);
                })
                .catch(error => {
                    message.error('Error update assign date');
                });
        }
    };

    const handleAssignDateModalCancel = () => {
        setIsAssignDateVisible(false);
    };

    ///
    const showCommitModal = () => {
        if (selectedItems.length > 0) {
            setCommitModalVisible(true);
            console.log(selectedItems)
        } else {
            if (params.commit_count > 0) {
                message.error('No item selected.');
            }
        }
    };

    const handleCommitModalOk = () => {
        const body = selectedItems
        axios.put(`${base_url}/delivery-schedule/multi-commit`, body)
            .then(() => {
                message.success('Commit successfully');
                setCommitModalVisible(false);
                fetchData();
                setSelectedItems([]);
            })
            .catch(error => {
                message.error('Error Commiting');
            });

    };

    const handleCommitModalCancel = () => {
        setCommitModalVisible(false);
    };
    ///

    const commitAssignDate = (record) => {
        const { uid } = record;
        // console.log(record)
        Modal.confirm({
            title: 'Commit Assign Date',
            content: 'Are you sure you want to commit the assign date?',
            onOk: () => {
                fetch(`${base_url}/delivery-schedule/commit/${record}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                    .then((response) => {
                        if (response.ok) {
                            message.success('Assign date committed successfully');
                            fetchData();
                            // Refresh the data if needed
                        } else {
                            message.error('Failed to commit assign date');
                        }
                    })
                    .catch((error) => {
                        console.error('Error committing assign date:', error);
                        message.error('An error occurred while committing assign date');
                    });
            },
            onCancel: () => {
                // Do nothing if cancel is clicked
            },
        });
    };

    const handleOk = async () => {
        try {
            // Make an API call to update the remark
            const response = await fetch(`${base_url}/delivery-schedule/${editedRemark}`, {
                method: 'PUT',
                body: JSON.stringify({ remark }),
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            // Handle the response as per your requirements
            if (response.ok) {
                message.success('Update remark successful');
                console.log('Remark updated successfully');
                fetchData();
                setRemark('');
            } else {
                message.error('Failed to update remark');
                console.log('Error updating the remark');
            }
        } catch (error) {
            console.log('Error:', error);
        }

        setRemarkModalVisible(false);
    };

    const handleCancel = () => {
        setEditedRemark(null);
        setRemarkModalVisible(false);
        setRemark('');
    };

    const deleteDeliverySchedule = async (uid) => {
        try {
            console.log(uid)
            const response = await axios.delete(`${base_url}/delivery-schedule/${uid}`);
            console.log('Delete response:', response.data);
            message.success('Succesfully unassign');
            fetchData();
            // Handle success or further actions here
        } catch (error) {
            console.error('Error deleting delivery schedule:', error);
            message.error('Error at unassign');
            // Handle error or display error message here
        }
    };

    const handleEditModalOk = () => {
        form
            .validateFields()
            .then(async (values) => {
                const { supplier_delivery_date } = values;

                const requestBody = {
                    delivery_date: moment(supplier_delivery_date?.format('YYYY-MM-DD')).add(7, 'hours').toISOString(),
                    uids: [editedRecord]
                };

                try {
                    const response = await fetch(`${base_url}/delivery-schedule/multi-assign-date`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(requestBody),
                    });

                    if (response.ok) {
                        message.success('Supplier delivery date updated successfully');
                        fetchData();
                        setEditModalVisible(false);
                    } else {
                        message.error('Failed to update supplier delivery date');
                    }
                } catch (error) {
                    console.error('Error updating supplier delivery date:', error);
                    message.error('An error occurred while updating supplier delivery date');
                }
            })
            .catch(() => {
                message.error('Please enter a valid date');
            });
    };

    const editRecord = (record) => {
        setEditedRecord(record);
        setEditModalVisible(true);
    };

    const showRemarkModal = (record) => {
        setEditedRemark(record);
        setRemarkModalVisible(true);
    };

    const handlePaginationChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const selectedItemsData = selectedItems.map((selectedUid) => {
        return data.find((item) => item.uid === selectedUid);
    });

    function renderQuantity(quantity) {
        if (Number.isInteger(quantity)) {
          return quantity.toString(); // If it's an integer, no decimal places
        } else {
          return quantity.toFixed(3); // If it's a float, round to 3 decimal places
        }
      }

    const columns = [
        {
            title: 'PO. No',
            dataIndex: 'purchase_order_item',
            key: 'purchase_order_item',
            width: 120,
            render: (_, record) => (
                <Button style={{"padding": "0px"}} type="link" >
                    <Link to={`/purchase-order/${record.purchase_order_item.uid}`}>{record.purchase_order_item.purchase_order_no}</Link>
                    <CopyToClipboardButton content={record.purchase_order_item.purchase_order_no} />
                </Button>
            ),
        },
        {
            title: 'Line No',
            dataIndex: 'purchase_order_item',
            key: 'purchase_order_item',
            width: 70,
            render: (purchase_order_item) => purchase_order_item.line_no,
        },
        {
            title: 'Material Code',
            dataIndex: 'purchase_order_item',
            key: 'purchase_order_item',
            width: 180,
            render: (purchase_order_item) => purchase_order_item.material_code,
        },
        {
            title: 'Description',
            dataIndex: 'purchase_order_item',
            key: 'purchase_order_item',
            ellipsis: true,
            width: 180,
            render: (purchase_order_item) => purchase_order_item.note,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 80,
            render: (quantity) => renderQuantity(quantity),
        },
        {
            title: 'Request Date',
            dataIndex: 'request_delivery_date',
            key: 'request_delivery_date',
            width: 107,
            render: (request_delivery_date) => request_delivery_date ? new Date(request_delivery_date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }): '-',
        },
        {
            title: 'Current Date',
            dataIndex: 'purchase_order_item',
            key: 'purchase_order_item',
            width: 105,
            render: (purchase_order_item) => new Date(purchase_order_item.request_delivery_date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }),
        },
        {
            title: 'Assign Date',
            dataIndex: 'supplier_delivery_date',
            key: 'supplier_delivery_date',
            width: 100,
            render: (date) =>new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }),
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
            width: 75,
        },
        {
            title: 'Remark',
            dataIndex: 'remark',
            key: 'remark',
            width: 75,
        },
        {
            title: 'Action',
            dataIndex: 'uid',
            key: 'action',
            width: 140,
            fixed: 'right',
            render: (record) => (
                <div>
                    <Tooltip title="Commit Assign Date" >
                        <Button
                            style={{ marginRight: '5px' }}
                            type="link"
                            icon={<CheckCircleOutlined />}
                            onClick={() => commitAssignDate(record)}
                            size="small"
                        />
                    </Tooltip>
                    <Tooltip title="Edit Date">
                        <Button
                            style={{ marginRight: '5px' }}
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => editRecord(record)}
                            size="small"
                        />
                    </Tooltip>
                    <Tooltip title="Set Remark">
                        <Button
                            style={{ marginRight: '5px' }}
                            type="link"
                            icon={<FormOutlined />}
                            onClick={() => showRemarkModal(record)}
                            size="small"
                        />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure you want to unassign this item?"
                        onConfirm={() => deleteDeliverySchedule(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Tooltip title="Unassign">
                            <Button type="link" danger icon={<DeleteOutlined />} />
                        </Tooltip>

                    </Popconfirm>
                </div>

            ),
        },
    ];

    return (
        <div>
            <Table
                dataSource={data}
                columns={columns}
                loading={loading}
                size='small'
                rowKey="uid"
                pagination={{
                    current: currentPage,
                    total: totalItems,
                    pageSize,
                    onChange: handlePaginationChange,
                    onShowSizeChange: handlePaginationChange,
                    showSizeChanger: true, // Enable changing page size
                    pageSizeOptions: ['10', '20', '50', '100'], // Define available page sizes
                }}
                rowSelection={{
                    selectedRowKeys: selectedItems,
                    onChange: (selectedRowKeys) => setSelectedItems(selectedRowKeys),
                }}
            />
            <Modal
                title="Edit Record"
                visible={editModalVisible}
                onCancel={() => {
                    form.resetFields();
                    setEditModalVisible(false);
                }}
                onOk={handleEditModalOk}
            >
                <Form form={form}>
                    <Form.Item
                        name="supplier_delivery_date"
                        label="Assign Delivery Date"
                        rules={[
                            { required: true, message: 'Please select a supplier delivery date' },
                        ]}
                    >
                        <DatePicker />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Set Remark"
                visible={remarkModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Input value={remark} onChange={(e) => setRemark(e.target.value)} />
            </Modal>
            <Modal
                title="Edit Assign Date"
                visible={isAssignDateVisible}
                onOk={handleAssignDateModalOk}
                onCancel={handleAssignDateModalCancel}
            >
                <Form>
                    <Form.Item label="Date and Time">
                        <DatePicker
                            onChange={handleAssignDateChange}
                        />
                    </Form.Item>
                </Form>
                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <List
                        header={<div>Selected Items:</div>}
                        bordered
                        dataSource={selectedItemsData}
                        size='small'
                        renderItem={(selectedItem) => (
                            <List.Item>
                                <Descriptions size="small" column={1}>
                                    <Descriptions.Item label="Purchase Order No">{selectedItem.purchase_order_item.purchase_order_no}</Descriptions.Item>
                                    <Descriptions.Item label="Line No">{selectedItem.purchase_order_item.line_no}</Descriptions.Item>
                                    <Descriptions.Item label="Material Code">{selectedItem.purchase_order_item.material_code}</Descriptions.Item>
                                    <Descriptions.Item label="Description">{selectedItem.purchase_order_item.note}</Descriptions.Item>
                                    <Descriptions.Item label="Quantity">{selectedItem.quantity}</Descriptions.Item>
                                    <Descriptions.Item label="Assign Delivery Date">
                                        {new Date(selectedItem.supplier_delivery_date).toLocaleDateString()}
                                    </Descriptions.Item>
                                </Descriptions>
                            </List.Item>
                        )}
                    />
                </div>
            </Modal>
            <Modal
                title="Commit"
                visible={commitModalVisible}
                onOk={handleCommitModalOk}
                onCancel={handleCommitModalCancel}
            >
                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <List
                        header={<div>Selected Items:</div>}
                        bordered
                        dataSource={selectedItemsData}
                        size='small'
                        renderItem={(selectedItem) => (
                            <List.Item>
                                <Descriptions size="small" column={1}>
                                    <Descriptions.Item label="Purchase Order No">{selectedItem.purchase_order_item.purchase_order_no}</Descriptions.Item>
                                    <Descriptions.Item label="Line No">{selectedItem.purchase_order_item.line_no}</Descriptions.Item>
                                    <Descriptions.Item label="Material Code">{selectedItem.purchase_order_item.material_code}</Descriptions.Item>
                                    <Descriptions.Item label="Quantity">{selectedItem.quantity}</Descriptions.Item>
                                    <Descriptions.Item label="Assign Delivery Date">
                                        {new Date(selectedItem.supplier_delivery_date).toLocaleDateString()}
                                    </Descriptions.Item>
                                </Descriptions>
                            </List.Item>
                        )}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default AssignTableVendor;

