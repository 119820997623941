import React from 'react';
import LoginComponent from '../../components/Login';
import config from '../../config'

const base_url = config.base_url
const LoginPage = () => {
  return (
    <div>
      <LoginComponent></LoginComponent>
    </div>
  );
};

export default LoginPage;
