import React from 'react';
import { Layout, Menu, Button, Typography } from 'antd';
import { UserOutlined, CarryOutOutlined, LogoutOutlined, ShoppingCartOutlined, CarOutlined, DashboardOutlined, CalendarOutlined, TeamOutlined, ShopOutlined, ToolOutlined, TagOutlined, SettingOutlined, HistoryOutlined, TableOutlined, TagsOutlined, LineChartOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import logo from '../../src/logo_hr.png'

const { Sider } = Layout;
const { Text } = Typography;

const Sidebar = () => {
  const token = localStorage.getItem('token');
  const decodedToken = token ? jwtDecode(token) : null;
  const username = decodedToken ? decodedToken.username : '-';
  const rolename = decodedToken ? decodedToken.role.name : '-';
  const name = decodedToken ? decodedToken.name : '-';
  const vendor = decodedToken.vendor ? decodedToken.vendor.name : '';

  const location = useLocation();
  const currentPath = location.pathname;

  const MenuItem = ({ icon, title, to, onClick }) => (
    <Menu.Item
      key={title}
      icon={React.cloneElement(icon, { style: { color: currentPath === to ? '#FFA500' : '#FFFFFF' } })}
      onClick={onClick}
      style={{ backgroundColor: 'transparent' }}
    >
      <Link to={to} style={{ color: currentPath === to ? '#FFA500' : '#FFFFFF', textDecoration: 'none' }}>
        {title}
      </Link>
    </Menu.Item>
  );

  const SubMenuItem = ({ icon, title, to, onClick }) => (
    <Menu.Item
      key={title}
      icon={React.cloneElement(icon, { style: { color: currentPath === to ? '#FFA500' : '#000000' } })}
      onClick={onClick}
      style={{ backgroundColor: 'transparent' }}
    >
      <Link to={to} style={{ color: currentPath === to ? '#FFA500' : '#000000', textDecoration: 'none' }}>
        {title}
      </Link>
    </Menu.Item>
  );


  const getSidebarMenu = () => {
    const menuItems = {
      "Admin": [
        { icon: <DashboardOutlined />, title: 'Dashboard', to: '/dashboard', submenu: [] },
        { icon: <ShoppingCartOutlined />, title: 'Purchase Order', to: '/purchase-order', submenu: [] },
        { icon: <CarOutlined />, title: 'Shipment', to: '/shipment', submenu: [] },
        { icon: <CalendarOutlined />, title: 'Calendar', to: '/calendar', submenu: [] },
        { icon: <TeamOutlined />, title: 'Users', to: '/users', submenu: [] },
        { icon: <TableOutlined /> , title: 'Master Data', to: '/', submenu: [
          { icon: <ShopOutlined />, title: 'Vendor', to: '/vendor', submenu: [] },
          { icon: <CarryOutOutlined />, title: 'Freight Forwarder', to: '/freight-forwarder', submenu: [] },
          { icon: <UserOutlined />, title: 'Agent', to: '/agent', submenu: [] },
          { icon: <TagOutlined />, title: 'Purchase Group', to: '/purchase-group', submenu: [] },
        ]},
        { icon: <ToolOutlined />, title: 'Tools', to: '/tools', submenu: [] },
        { icon: <HistoryOutlined />, title: 'Logs', to: '/logs', submenu: [] },
        { icon: <LineChartOutlined />, title: 'Report', to: '/report', submenu: []},
        { icon: <TagsOutlined />, title: 'Ticket', to: '/ticket', submenu: [] },
        { icon: <SettingOutlined />, title: 'Account', to: '/account', submenu: [] },
        { icon: <LogoutOutlined />, title: 'Logout', to: '/', onClick: handleLogout, submenu: [] },
      ],
      "Purchaser": [
        { icon: <DashboardOutlined />, title: 'Dashboard', to: '/dashboard' , submenu: []},
        { icon: <ShoppingCartOutlined />, title: 'Purchase Order', to: '/purchase-order', submenu: [] },
        { icon: <CarOutlined />, title: 'Shipment', to: '/shipment', submenu: [] },
        { icon: <CalendarOutlined />, title: 'Calendar', to: '/calendar', submenu: [] },
        { icon: <TableOutlined /> , title: 'Master Data', to: '/', submenu: [
          { icon: <ShopOutlined />, title: 'Vendor', to: '/vendor', submenu: [] },
          { icon: <CarryOutOutlined />, title: 'Freight Forwarder', to: '/freight-forwarder', submenu: [] },
          { icon: <UserOutlined />, title: 'Agent', to: '/agent', submenu: [] },
        ]},
        { icon: <TagsOutlined />, title: 'Ticket', to: '/ticket', submenu: [] },
        { icon: <SettingOutlined />, title: 'Account', to: '/account', submenu: [] },
        { icon: <LogoutOutlined />, title: 'Logout', to: '/', onClick: handleLogout, submenu: [] },
      ],
      "Freight Forwarder": [
        { icon: <DashboardOutlined />, title: 'Dashboard', to: '/dashboard', submenu: [] },
        { icon: <CarOutlined />, title: 'Shipment', to: '/shipment', submenu: [] },
        { icon: <TagsOutlined />, title: 'Ticket', to: '/ticket', submenu: [] },
        { icon: <SettingOutlined />, title: 'Account', to: '/account', submenu: [] },
        // { icon: <CalendarOutlined />, title: 'Calendar', to: '/calendar' },
        { icon: <LogoutOutlined />, title: 'Logout', to: '/', onClick: handleLogout, submenu: [] },
      ],
      "Vendor": [
        { icon: <DashboardOutlined />, title: 'Dashboard', to: '/dashboard', submenu: [] },
        { icon: <ShoppingCartOutlined />, title: 'Purchase Order', to: '/purchase-order', submenu: [] },
        { icon: <CarOutlined />, title: 'Shipment', to: '/shipment', submenu: [] },
        { icon: <CalendarOutlined />, title: 'Calendar', to: '/calendar', submenu: [] },
        { icon: <TagsOutlined />, title: 'Ticket', to: '/ticket', submenu: [] },
        { icon: <SettingOutlined />, title: 'Account', to: '/account', submenu: [] },
        { icon: <LogoutOutlined />, title: 'Logout', to: '/', onClick: handleLogout, submenu: [] },
      ],
      "Employee": [
        { icon: <DashboardOutlined />, title: 'Dashboard', to: '/dashboard', submenu: [] },
        { icon: <ShoppingCartOutlined />, title: 'Purchase Order', to: '/purchase-order', submenu: [] },
        { icon: <CarOutlined />, title: 'Shipment', to: '/shipment', submenu: [] },
        { icon: <CalendarOutlined />, title: 'Calendar', to: '/calendar', submenu: [] },
        { icon: <SettingOutlined />, title: 'Account', to: '/account', submenu: [] },
        { icon: <LogoutOutlined />, title: 'Logout', to: '/', onClick: handleLogout, submenu: [] },
      ],
    };

    const menuItemsByRole = menuItems[decodedToken.role.name] || [];

    return (
      <Menu mode="vertical" theme='dark' style={{ backgroundColor: '#222222'}} selectedKeys={[currentPath]}>
        {menuItemsByRole.map(({ icon, title, to, onClick, submenu }) => (
          submenu.length === 0?
            <MenuItem key={title} icon={icon} title={title} to={to} onClick={onClick} theme='dark' />
            :
            <Menu.SubMenu key={title} title={title} icon={icon} theme='light' style={{color:'white'}}>
              {submenu.map(({ icon, title, to, onClick }) => (
                <SubMenuItem key={title} icon={icon} title={title} to={to} onClick={onClick} />
              ))}
            </Menu.SubMenu>
        )
        )}
      </Menu>
    );
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: '#222222', boxShadow: '6px 0 6px rgba(0, 0, 0, 0.1)', marginTop: '4px', marginRight: '10px', marginBottom: '10px', borderRadius: '10px' }}>
      <Sider width={200} theme="light" style={{ borderRadius: '10px' }}>
        <div style={{ padding: '16px 0', textAlign: 'center' }}>
          <img src={logo} alt="Logo" style={{ width: '70px', margin: 'auto', display: 'block', padding: '16px 0' }} />
          <Button icon={<UserOutlined />} size="large" style={{ backgroundColor: '#222222', color: '#FFFFFF', marginBottom: '8px' }} />
          <div>
            <Text style={{ color: '#FFFFFF' }} paragraph>
              Role: {rolename}
            </Text>
            <br></br>
            <Text style={{ color: '#FFFFFF' }} paragraph>
              User: {username}
            </Text>
            <br></br>
            {name && (
              <div>
                <Text style={{ color: '#FFFFFF' }} paragraph>
                  Name: {name}
                </Text>
                <br></br>
              </div>
            )}
            {vendor && (
              <div>
                <Text style={{ color: '#FFFFFF' }} paragraph>
                  Vendor: {vendor}
                </Text>
                <br></br>
              </div>
            )}
          </div>
        </div>
        {getSidebarMenu()}
      </Sider>
    </div>
  );
};

export default Sidebar;
