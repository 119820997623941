import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Select, Card, DatePicker, TimePicker, message } from 'antd';
import axios from 'axios';
import config from '../../config';

const base_url = config.base_url;

const { Option } = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const ShipmentUpdateButton3 = ({ uid, shipment_detail, refresh }) => {
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();

    const showModal = () => {
        form.setFieldsValue({
            status: getNextStatusOptions(shipment_detail.status)
        });
        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const getNextStatusOptions = (currentStatus) => {
        const nextStatusOptionsMap = {
            Packed: 'Flagged',
            Flagged: 'Pickup',
            Pickup: 'Complete'
        };
        // console.log(nextStatusOptionsMap[currentStatus])
        return nextStatusOptionsMap[currentStatus] || [];
    };

    const handleSubmit = async (values) => {
        try {
            // Prepare the data to be sent in the PUT request
            const selectedDate = values.shipped_date.format('YYYY-MM-DD');
            const middayTime = '12:00:00';
            const combinedDateTime = `${selectedDate}T${middayTime}+07:00`;
            const requestData = {
                status: values.status,
                shipped_date: combinedDateTime,
            };
            // console.log(requestData)
            // Perform the PUT request using Axios
            await axios.put(`${base_url}/shipment/${uid}`, requestData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            // Close the modal and reset the form
            handleCancel();
            refresh();
            form.resetFields();
            message.success('Update Success');
        } catch (error) {
            console.error('Error updating shipment:', error);
        }
    };

    return (
        <div>
            <Button hidden={shipment_detail.status != "Pickup"} type="primary" style={{ marginRight: '12px', width: '120px' }} onClick={showModal}>
                Update
            </Button>
            <Modal
                title="Update Shipment"
                visible={visible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form form={form} onFinish={handleSubmit}>
                    <Form.Item
                        name="status"
                        label="Next Status"
                        rules={[{ required: true, message: 'Please select the next status' }]}
                    >
                        <Card size='small' style={{ textAlign: 'center' }}>
                            {getNextStatusOptions(shipment_detail.status)}
                        </Card>
                    </Form.Item>
                    <Form.Item
                        name="shipped_date"
                        label="Shipped Date"
                        rules={[{ required: true, message: 'Please select the shipped date and time' }]}
                    >
                        <DatePicker format="YYYY-MM-DD" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ShipmentUpdateButton3;
