import React, { useState, useEffect, useRef } from 'react';
import { Table, Button} from 'antd'; // Import Button from 'antd'
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import jwtDecode from 'jwt-decode';
import CopyToClipboardButton from '../Button/CopyToClipBoardButton';

const base_url = config.base_url;

const ShipmentTable = (params) => {
    const token = localStorage.getItem('token');
    const decodedToken = token ? jwtDecode(token) : null;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [header, setHeader] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10); // Number of items per page
    const [purchaseGroup, setPurchaseGroup] = useState([])
    // const unmount = useRef(true);

    useEffect(() => {
        // if (unmount.current) {
        //     unmount.current = false;
        // }
        // else{
            
        // }
        fetchData();
    }, [currentPage, pageSize, params.vendor_uid, params.start_shipped_date, params.end_shipped_date, params.purchase_group_uid]);

    useEffect(() => {
        params.setCount(header)
    }, [header])

    const fetchData = async () => {
        try {
            setLoading(true);
            const skip = (currentPage - 1) * pageSize;
            const response = await axios.get(`${base_url}/shipment/pagination`, {
                params: {
                    skip,
                    limit: pageSize,
                    vendor_uid: params.vendor_uid,
                    purchase_group_uid: params.purchase_group_uid,
                    status: params.status,
                    start_shipped_date: params.start_shipped_date,
                    end_shipped_date: params.end_shipped_date,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    // Add other headers if needed
                },
            });
            setData(response.data.data);
            setTotalItems(response.data.total_count);
            setHeader(response.data.header);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const formatPurchaseGroupName = (name) => {
        if (name) {
            const nameParts = name.split(' ');
            if (nameParts.length >= 2) {
                const firstName = nameParts[0];
                const lastName = nameParts.slice(1).join(' ');
                return `${firstName} ${lastName.charAt(0)}.`;
            } else {
                return name; // Return the original name if it doesn't have a last name.
            }
        } else {
            return '-';
        }
    };
    

    const handlePaginationChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const columns = [
        {
            title: 'No.',
            dataIndex: 'number',
            key: 'number',
            render: (_, record) => (
                <Button style={{"padding": "0px"}} type="link" >
                    <Link to={`/shipment/${record.uid}`}>{record.number}</Link>
                    <CopyToClipboardButton content={record.number} />
                </Button>
            ),
        },
        {
            title: 'Vendor',
            dataIndex: 'vendor',
            key: 'vendor',
            render: (vendor) => vendor ? vendor.name : '-',
          },
          {
            title: 'Pickup Location',
            dataIndex: 'pickup_location',
            key: 'pickup_location',
            ellipsis: true,
            render: (pickup_location) => pickup_location ? pickup_location : '-',
          },
          {
            title: 'Type',
            dataIndex: 'shipment_type',
            key: 'shipment_type',
            width: 80,
            render: (shipment_type) => shipment_type ? shipment_type : '-',
          },
          {
            title: 'Freight Forwarder',
            dataIndex: 'freight_forwarder',
            key: 'freight_forwarder',
            render: (freight_forwarder) => freight_forwarder ? freight_forwarder.name : '-',
          },
          {
            title: 'Agent Contact',
            dataIndex: 'agent_contact',
            key: 'agent_contact',
            ellipsis: true,
            render: (agent_contact) => agent_contact ? agent_contact : '-',
          },
          {
            title: 'Air Way Bill',
            dataIndex: 'air_way_bill',
            key: 'air_way_bill',
            render: (air_way_bill) => air_way_bill ? air_way_bill: '-',
          },
          {
            title: 'CCS Number',
            dataIndex: 'ccs_number',
            key: 'ccs_number',
            render: (ccs_number) => ccs_number ? ccs_number: '-',
          },
          {
            title: 'Shipped Date',
            dataIndex: 'shipped_date',
            key: 'shipped_date',
            render: (shipped_date) => shipped_date ? new Date(shipped_date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }): '-'
          },
          
        // {
        //     title: 'Action',
        //     dataIndex: 'uid',
        //     key: 'action',
        //     render: (uid) => (
        //         <Popconfirm
        //             title="Are you sure you want to delete this item?"
        //             onConfirm={() => handleDelete(uid)}
        //             okText="Yes"
        //             cancelText="No"
        //         >
        //             <Button type="link" danger icon={<DeleteOutlined />} />
        //         </Popconfirm>
        //     ),
        // },
    ];

    const columnsAdmin = columns.concat({
        title: 'Assignee',
        dataIndex: 'purchase_group',
        key: 'purchase_group',
        width: 130,
        render: (purchase_group) =>  purchase_group ? `${formatPurchaseGroupName(purchase_group.name)} (${purchase_group.purchase_group})` : '-',
    })

    return (
        <div>
            <Table
                dataSource={data}
                columns={decodedToken.role.name === 'Admin' || decodedToken.role.name === 'Purchaser' ? columnsAdmin : columns}
                loading={loading}
                size='small'
                rowKey="uid"
                pagination={{
                    current: currentPage,
                    total: totalItems,
                    pageSize,
                    onChange: handlePaginationChange,
                    onShowSizeChange: handlePaginationChange,
                    showSizeChanger: true, // Enable changing page size
                    pageSizeOptions: ['10', '20', '50', '100'], // Define available page sizes
                }}
            />
        </div>
    );
};

export default ShipmentTable;

