import React, { useEffect, useState } from 'react';
import { Calendar, Badge, Tag } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import config from '../../config'

const base_url = config.base_url
const CalendarPage = () => {
  const token = localStorage.getItem('token');
  const [events, setEvents] = useState([]);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${base_url}/purchase-order-item`, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      });
      const eventData = response.data.map((item) => ({
        uid: item.uid,
        title: item.purchase_order_no,
        date: moment(item.request_delivery_date).format('YYYY-MM-DD'),
      }));
      setEvents(eventData);
    } catch (error) {
      console.log('Error fetching events:', error);
    }
  };
  

  const handleEventClick = (event) => {
    console.log(event.title);
  };

  const dateCellRender = (value) => {
    const date = value.format('YYYY-MM-DD');
    const event = events.find((event) => event.date === date);
    
    if (event && event.uid) {
      return (
        <div style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <a href={`/purchase-order/${event.uid}`}>
            <Tag color="#1890ff" style={{ width: '100%', textAlign: 'center' }} title={`Request Date ${event.title}`}>
              {`PO Request: ${event.title}`}
            </Tag>
          </a>
        </div>
      );
    }
    
    return null;
  };

  return (
    <div>
      <h2>Calendar</h2>
      <div style={{ height: '400px', width: '100%', margin: '0 auto' }}>
        <Calendar
          mode="month"
          dateCellRender={dateCellRender}
          onSelect={handleEventClick}
        />
      </div>
    </div>
  );
};

export default CalendarPage;
