import React, { useState } from 'react';
import { Card, Form, Input, Button, Typography, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import DownloadButton from './DownloadManual';
import logo from '../../src/logo_with_name_hd.jpeg'
import config from '../config'
import jwtDecode from 'jwt-decode';

const base_url = config.base_url
const { Title, Text } = Typography;

const LoginComponent = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    // Make a request to the server to login the user.
    fetch(`${base_url}/user/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Login failed');
        }
        return response.json();
      })
      .then((data) => {
        // Check if the response contains an error message
        if (data.error) {
          console.log(data);
          message.error(data.error); // Display the error message
        } else {
          // Set the token and navigate to the purchase-order page after the token is saved.
          localStorage.setItem('token', data.token);
          let token = jwtDecode(data.token)
          if (token.require_reset){
            window.location.href = `/account`;
          } else{
            window.location.href = `/dashboard`;
          }
        }
      })
      .catch((error) => {
        console.error(error);
        message.error('Login failed. Please try again.'); // Display the error message
      });
  };

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#424242',
    }}>
      <div>
        <Card style={{ width: '700px', margin: 'auto', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}>
          <img src={logo} alt="Logo" style={{ width: '80%', margin: 'auto', display: 'block' }} />
          {/* <Title level={3} style={{ textAlign: 'center', color: '#000000' }}>CCS Portail</Title> */}
          <br></br>
          <Form onFinish={handleLogin}>
            <Form.Item label="Username">
              <Input placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
            </Form.Item>
            <Form.Item label="Password">
              <Input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </Form.Item>
            <Form.Item style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button type="primary" htmlType="submit">Login</Button>
            </Form.Item>
          </Form>
          <hr></hr>
          <Text type="secondary">If your have any issue, please send email to CCS_PURCHASE@CCSADVANCETECH.CO.TH</Text>
          <br></br>
          <DownloadButton />
        </Card>
      </div>
    </div>
  );
};

export default LoginComponent;
