import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Badge, Switch, Input, Tabs, Button, Select } from 'antd';
import config from '../../config'
import UnreleaseTable from '../../components/Table/UnreleaseTable';
import StatusTableWithPurchaseName from '../../components/Table/StatusTableWithPurchaseName';
import FilterTooltip from '../../components/FilterTooltip';

const base_url = config.base_url
const { TabPane } = Tabs;
const { Option } = Select;

const PurchaserPurchaseOrderItemPage = () => {
  const token = localStorage.getItem('token');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initial = queryParams.get('initial');
  const tab = queryParams.get('tab');
  const [purchaseGroup, setPurchaseGroup] = useState([]);
  const [searchValuePO, setSearchValuePO] = useState(null);
  const [searchValueVendor, setSearchValueVendor] = useState(null)
  const [searchValuePG, setSearchValuePG] = useState(null)
  const [currentTab, setCurrentTab] = useState(tab? tab: 'unrelease');
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedPG, setSelectedPG] = useState(null);
  const [delayState, setDelayState] = useState(false);
  const [lateState, setLateState] = useState(false);
  const [delayPG, setDelayPG] = useState(initial === 'delay' ? true : null);
  const [latePG, setLatePG] = useState(initial === 'late' ? true : null);
  const [releaseCount, setReleaseCount] = useState(0)
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState(null)
  const [unreleaseCount, setUnreleaseCount] = useState(0);
  const [unassignCount, setUnassignCount] = useState(0);
  const [assignCount, setAssignCount] = useState(0);
  const [commitCount, setCommitCount] = useState(0);
  const [shippedCount, setShippedCount] = useState(0);

  useEffect(() => {
    console.log(initial)
    fetchPurchaseGroup();
    fetchVendors();
  }, []);

  const handleRelease = () => {
    setReleaseCount(releaseCount + 1)
  };

  const fetchPurchaseGroup = async () => {
    try {
      const response = await axios.get(`${base_url}/purchase-group`, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        }
      });
      setPurchaseGroup(response.data);
    } catch (error) {
      console.error('Error fetching purchase group:', error);
      // Handle fetch error here
    }
  };

  const fetchVendors = async (searchValuePO) => {
    try {
      const response = await axios.get(`${base_url}/vendor`, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
        params: {
          name: searchValuePO,
        },
      });
      setVendors(response.data);
    } catch (error) {
      console.error('Error fetching vendors:', error);
    }
  };

  const setHeader = (value)  => {
    setUnreleaseCount(value.unrelease_count);
    setUnassignCount(value.unassign_count);
    setAssignCount(value.assign_count);
    setCommitCount(value.commit_count);
    setShippedCount(value.packed_count);
  };

  const handleSearch = (value) => {
    setSearchValuePO(value);
  };

  const handleTabChange = (key) => {
    setCurrentTab(key);
  };

  const handleVendorChange = (value) => {
    setSearchValueVendor(value);
  };

  const handleVendorClear = () => {
    setSearchValueVendor(null);
  };

  const handlePurchaseGroupChange = (value) => {
    setSearchValuePG(value);
  };

  const handlePurchaseGroupClear = () => {
    setSearchValuePG(null);
  };

  const handleApplyFilters = () => {
    setPurchaseOrderNumber(searchValuePO);
    setSelectedVendor(searchValueVendor);
    setSelectedPG(searchValuePG);
    setDelayPG(delayState?true:null)
    setLatePG(lateState?true:null)
  };

  const releaseButton = <Button
    type="primary"
    hidden={currentTab !== 'unrelease'}
    onClick={() => handleRelease()}
    style={{ marginLeft: 'auto' }}
  >
    Release
  </Button>

  return (
    <div>
      <h2>Purchase Order Items</h2>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>

        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '16px' }}>

          <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '16px' }}>
            <Input
              placeholder="Purchase Order Number"
              onChange={(e) => handleSearch(e.target.value)}
              style={{ width: '200px' }}
            />
            <FilterTooltip/>
            <Select
              placeholder="Select Vendor"
              onSelect={handleVendorChange}
              onClear={handleVendorClear}
              style={{ width: '200px' }}
              allowClear
              onSearch={fetchVendors}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {vendors.map((vendor) => (
                <Option key={vendor.uid} value={vendor.uid} label={vendor.name}>
                  {vendor.name}
                </Option>
              ))}
            </Select>

            <Select
              placeholder="Select Purchase Group"
              onSelect={handlePurchaseGroupChange}
              onClear={handlePurchaseGroupClear}
              style={{ width: '200px' }}
              allowClear
              showSearch
            >
              {purchaseGroup.map((pg) => (
                <Option key={pg.uid} value={pg.purchase_group} label={pg.name}>
                  {pg.name}
                </Option>
              ))}
            </Select>
            <div>
              <div>
                <Switch size='small' defaultChecked={initial==='delay'} onChange={(checked) => {setDelayState(checked);}} checkedChildren="On" unCheckedChildren="Off" />
              </div>
              <div style={{ fontFamily: 'Ant Design Font, sans-serif',  fontSize: '13px', color: 'grey', textAlign: 'center'}}>
                Delay
              </div>
            </div>
            <div>
              <div>
                <Switch size='small' defaultChecked={initial==='late'} onChange={(checked) => {setLateState(checked);}} checkedChildren="On" unCheckedChildren="Off" />
              </div>
              <div style={{ fontFamily: 'Ant Design Font, sans-serif',  fontSize: '13px', color: 'grey', textAlign: 'center'}}>
                Late
              </div>
            </div>
            <Button type="primary" onClick={handleApplyFilters}>
              Apply Filters
            </Button>
            
          </div>
          
        </div>

      </div>

      <Tabs destroyInactiveTabPane={true} activeKey={currentTab} onChange={handleTabChange} tabBarExtraContent={releaseButton}>
        <TabPane tab={<span>Unrelease <Badge count={unreleaseCount} style={{ backgroundColor: '#40a9ff' }} /></span>} key="unrelease">
          <UnreleaseTable vendor_uid={selectedVendor} purchase_group={purchaseGroup} release_count={releaseCount} purchase_order_no={purchaseOrderNumber} filter_purchase_group={selectedPG} setCount={setHeader} />
        </TabPane>
        <TabPane tab={<span>Unassign <Badge count={unassignCount} style={{ backgroundColor: '#40a9ff' }} /></span>} key="unassign">
          <StatusTableWithPurchaseName vendor_uid={selectedVendor} purchase_group={purchaseGroup} release_count={releaseCount} purchase_order_no={purchaseOrderNumber} filter_purchase_group={selectedPG} status='Unassign' is_delay={delayPG} is_late={latePG} setCount={setHeader} />
        </TabPane>
        <TabPane tab={<span>Assign <Badge count={assignCount} style={{ backgroundColor: '#40a9ff' }} /></span>} key="assign">
          <StatusTableWithPurchaseName vendor_uid={selectedVendor} purchase_group={purchaseGroup} release_count={releaseCount} purchase_order_no={purchaseOrderNumber} filter_purchase_group={selectedPG} status='Assign' is_delay={delayPG} is_late={latePG} setCount={setHeader} />
        </TabPane>
        <TabPane tab={<span>Commit <Badge count={commitCount} style={{ backgroundColor: '#40a9ff' }} /></span>} key="commit">
          <StatusTableWithPurchaseName vendor_uid={selectedVendor} purchase_group={purchaseGroup} release_count={releaseCount} purchase_order_no={purchaseOrderNumber} filter_purchase_group={selectedPG} status='Commit' is_delay={delayPG} is_late={latePG} setCount={setHeader} />
        </TabPane>
        <TabPane tab={<span>Packed <Badge count={shippedCount} style={{ backgroundColor: '#40a9ff' }} /></span>} key="packed">
          <StatusTableWithPurchaseName vendor_uid={selectedVendor} purchase_group={purchaseGroup} release_count={releaseCount} purchase_order_no={purchaseOrderNumber} filter_purchase_group={selectedPG} status='Packed' is_delay={delayPG} is_late={latePG} setCount={setHeader} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default PurchaserPurchaseOrderItemPage;
