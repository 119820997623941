import React, { useState, useEffect } from 'react';
import { Table, Button, Popconfirm, message, Modal, Tag } from 'antd'; // Import Button from 'antd'
import { useNavigate, Link } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../config';
import CopyToClipboardButton from '../Button/CopyToClipBoardButton';

const base_url = config.base_url;

const UnreleaseTable = React.forwardRef((params, ref) => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [header, setHeader] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10); // Number of items per page
    const [purchaseGroup, setPurchaseGroup] = useState([])
    const [selectedItems, setSelectedItems] = useState([])
    const [releaseModalVisible, setReleaseModalVisible] = useState(false);

    useEffect(() => {
        fetchData();
    }, [currentPage, pageSize, params.vendor_uid, params.purchase_order_no, params.filter_purchase_group]);

    useEffect(() => {
        handleRelease();
    }, [params.release_count])

    useEffect(() => {
        setPurchaseGroup(params.purchase_group)
    }, [params.purchase_group])

    useEffect(() => {
        params.setCount(header)
    }, [header])

    const fetchData = async () => {
        try {
            setLoading(true);
            const skip = (currentPage - 1) * pageSize;
            const response = await axios.get(`${base_url}/purchase-order-item/pagination`, {
                params: {
                    skip,
                    limit: pageSize,
                    vendor_uid: params.vendor_uid,
                    status: 'Unrelease',
                    purchase_order_no: params.purchase_order_no,
                    purchase_group: params.filter_purchase_group
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    // Add other headers if needed
                },
            });
            setData(response.data.data);
            setTotalItems(response.data.total_count);
            setHeader(response.data.header);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };


    const handlePurchaseOrderClick = (uid) => {
        navigate(`/purchase-order/${uid}`);
    };

    const handleDelete = (uid) => {
        axios
            .delete(`${base_url}/purchase-order-item/${uid}`)
            .then((response) => {
                if (response.status === 200) {
                    fetchData();
                    message.success('Item deleted successfully');
                    // Perform any necessary operations after successful deletion
                } else {
                    message.error('Failed to delete the record');
                    // Handle error case if required
                }
            })
            .catch((error) => {
                message.error('An error occurred while deleting the record');
                // Handle error case if required
            });
    };

    const handleReleaseModalOk = async () => {

        const data = {
            purchase_order_item_uids: selectedItems,
        };

        try {
            const response = await axios.post(`${base_url}/purchase-order-item/release-item`, data);
            // console.log('Release response:', response.data);
            message.success('Items released successfully.');
            // Refetch the table data
            fetchData();
        } catch (error) {
            console.error('Error releasing items:', error);
            message.error('Failed to release items.');
        }

        // Close the release modal and reset the selected items and date
        setReleaseModalVisible(false);
        setSelectedItems([]);
    };

    const handleReleaseModalCancel = () => {
        setReleaseModalVisible(false);
        setSelectedItems([]);
    };

    const handleRelease = () => {
        if (selectedItems.length > 0) {
            setReleaseModalVisible(true);
        } else {
            if (params.release_count > 0) {
                message.error('No item selected.');
            }
        }
    };

    const handlePaginationChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
        setSelectedItems([]);
    };

    const mapPurchaseGroupName = (purchaseGroupData, purchaseGroup) => {
        const matchingGroup = purchaseGroupData.find(group => group.purchase_group === purchaseGroup);
        if (matchingGroup) {
            const nameParts = matchingGroup.name.split(' ');
            if (nameParts.length >= 2) {
                const firstName = nameParts[0];
                const lastName = nameParts.slice(1).join(' ');
                return `${firstName} ${lastName.charAt(0)}.`;
            } else {
                return matchingGroup.name; // Return the original name if it doesn't have a last name.
            }
        } else {
            return '-';
        }
    };
    function renderQuantity(quantity) {
        if (Number.isInteger(quantity)) {
          return quantity.toString(); // If it's an integer, no decimal places
        } else {
          return quantity.toFixed(3); // If it's a float, round to 3 decimal places
        }
      }
    const columns = [
        {
            title: 'PO. No',
            dataIndex: 'purchase_order_no',
            key: 'purchase_order_no',
            width: 120,
            render: (_, record) => (
                <Button type="link" style={{ padding: '0' }}>
                    <Link to={`/purchase-order/${record.uid}`}>{record.purchase_order_no}</Link>
                    <CopyToClipboardButton content={record.purchase_order_no} />
                </Button>
            ),
        },
        {
            title: 'Line No',
            dataIndex: 'line_no',
            key: 'line_no',
            width: 70,
        },
        {
            title: 'Material Code',
            dataIndex: 'material_code',
            key: 'material_code',
            width: 180,
        },
        {
            title: 'Description',
            dataIndex: 'note',
            ellipsis: true,
            key: 'note',
            width: 120,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 80,
            render: (quantity) => renderQuantity(quantity),
        },
        {
            title: 'Request Date',
            dataIndex: 'request_delivery_date',
            key: 'request_delivery_date',
            width: 120,
            render: (date) => new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }),
        },
        {
            title: 'Vendor',
            dataIndex: 'vendor',
            key: 'vendor',
            ellipsis: true,
            width: 150,
            render: (vendor) => `${vendor.code} ${vendor.name}`,
        },
        {
            title: 'Assignee',
            dataIndex: 'purchase_order_header',
            key: 'purchase_order_header',
            width: 130,
            render: (purchase_order_header) => `${mapPurchaseGroupName(purchaseGroup, purchase_order_header.purchase_group)} (${purchase_order_header.purchase_group})`,
        },
        {
            title: 'Approve',
            dataIndex: 'purchase_order_header',
            key: 'purchase_order_header',
            width: 80,
            render: (purchase_order_header) => purchase_order_header.is_approved ? <Tag color='green'>Yes</Tag> : <Tag color='red'>No</Tag>,
        },
        {
            title: 'Action',
            dataIndex: 'uid',
            key: 'action',
            width: 80,
            render: (uid) => (
                <Popconfirm
                    title="Are you sure you want to delete this item?"
                    onConfirm={() => handleDelete(uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="link" danger icon={<DeleteOutlined />} />
                </Popconfirm>
            ),
        },
    ];

    return (
        <div ref={ref}>
            <Table
                dataSource={data}
                columns={columns}
                loading={loading}
                size='small'
                rowKey="uid"
                pagination={{
                    current: currentPage,
                    total: totalItems,
                    pageSize,
                    onChange: handlePaginationChange,
                    onShowSizeChange: handlePaginationChange,
                    showSizeChanger: true, // Enable changing page size
                    pageSizeOptions: ['10', '20', '50', '100'], // Define available page sizes
                }}
                rowSelection={{
                    selectedRowKeys: selectedItems,
                    onChange: (selectedRowKeys) => setSelectedItems(selectedRowKeys),
                }}
            />
            <Modal
                title="Release Items"
                visible={releaseModalVisible}
                onOk={handleReleaseModalOk}
                onCancel={handleReleaseModalCancel}
            >
                <div>
                    <p>Selected Items:</p>
                    {selectedItems.map((itemUid) => {
                        const selectedItem = data.find((item) => item.uid === itemUid);
                        return (
                            <div key={itemUid}>
                                <p>Purchase Order No: {selectedItem.purchase_order_no}</p>
                                <p>Line No: {selectedItem.line_no}</p>
                                <p>Material Code: {selectedItem.material_code}</p>
                                <p>Description: {selectedItem.note}</p>
                                <hr />
                            </div>
                        );
                    })}
                </div>
            </Modal>
        </div>
    );
});

export default UnreleaseTable;

