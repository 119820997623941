import React, { useEffect, useState } from 'react';
import { Table, Button, Popover, Col, Row, DatePicker, Input, Select } from 'antd';
import axios from 'axios';
import config from '../../config'

const base_url = config.base_url
const { RangePicker } = DatePicker;
const { Option } = Select;


const LogManagementPage = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Number of items per page
  const [searchDateRange, setSearchDateRange] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [remarkValue, setRemarkValue] = useState(null);
  const [poValue, setPoValue] = useState(null);

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    fetchLogs();
  }, [currentPage, pageSize, searchDateRange, selectedValue, remarkValue, poValue]);

  const fetchLogs = async (name) => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const skip = (currentPage - 1) * pageSize;
      let params = {
        skip,
        limit: pageSize,
        remark: remarkValue,
        action: selectedValue,
        po_no: poValue,
        start_created_date: searchDateRange.length > 1 ? searchDateRange[0].toISOString().split('T')[0] + 'T00:00:00.0000+00:00' : null,
        end_created_date: searchDateRange.length > 1 ? searchDateRange[1].toISOString().split('T')[0] + 'T23:59:00.0000+00:00' : null,
      };
      if (name) {
        params.name = name;
      }
      const response = await axios.get(`${base_url}/log/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      });
      setLogs(response.data.data);
      setTotalItems(response.data.total_count)
    } catch (error) {
      console.error('Error fetching logs:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (dates, dateStrings) => {
    if (dates === null) {
      setSearchDateRange([]);
    } else {
      setSearchDateRange(dates);
    }
  };

  function handleChange(value) {
    setSelectedValue(value);
  }

  function handleRemarkChange(event) {
    setRemarkValue(event.target.value);
  }

  function handlePOChange(event) {
    setPoValue(event.target.value);
  }

  const columns = [
    { title: 'Created Date', dataIndex: 'created_date', key: 'created_date', render: (created_date) => new Date(created_date).toLocaleDateString() },
    {
      title: 'PO. No',
      dataIndex: 'details',
      key: 'details',
      render: (details) => details.purchase_order_no,
    },
    {
      title: 'Line No',
      dataIndex: 'details',
      key: 'details',
      render: (details) => details.line_no,
    },
    {
      title: 'Material Code',
      dataIndex: 'details',
      key: 'details',
      render: (details) => details.material_code,
    },
    {
      title: 'Description',
      dataIndex: 'details',
      key: 'details',
      render: (details) => details.note,
    },
    { title: 'Action', dataIndex: 'action', key: 'action' },
    { title: 'Remark', dataIndex: 'remark', key: 'remark' },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      render: (details) =>
        <Popover content={Object.keys(details).map(key => (
          <p key={key}>
            <strong>{key}:</strong> {details[key]}
          </p>
        ))} title="Raw Data" overlayStyle={{ maxWidth: '500px' }} style={{ maxWidth: '500px', overflowX: 'auto' }}>
          <Button type="primary">View Raw</Button>
        </Popover>
    },
  ];

  return (
    <div>
      <h2>Logs</h2>
      <div style={{ marginBottom: '16px' }}>
        <Row>
          <Col span={6}>
          </Col>
          <Col span={4}>
            <Input 
              style={{ width: "90%" }} 
              placeholder="PO Number" 
              allowClear 
              value={poValue}
              onChange={handlePOChange}
            />
          </Col>
          <Col span={4}>
            <Input 
              style={{ width: "90%" }} 
              placeholder="Remark" 
              allowClear 
              value={remarkValue}
              onChange={handleRemarkChange}
            />
          </Col>
          <Col span={4}>
            <Select
              style={{ width: "90%" }}
              value={selectedValue}
              onChange={handleChange}
              placeholder="Action"
              allowClear
            >
              <Option value="CREATE">CREATE</Option>
              <Option value="UPDATE">UPDATE</Option>
              <Option value="DELETE">DELETE</Option>
            </Select>
          </Col>
          <Col span={6}>
            <RangePicker
              style={{ width: '90%' }}
              placeholder={['Start Date/Time', 'End Date/Time']}
              value={searchDateRange}
              onChange={handleDateChange}
            />
          </Col>
        </Row>
      </div>
      <Table
        size="small"
        dataSource={logs}
        columns={columns}
        loading={loading}
        rowKey="uid"
        pagination={{
          current: currentPage,
          total: totalItems,
          pageSize,
          onChange: handlePaginationChange,
          onShowSizeChange: handlePaginationChange,
          showSizeChanger: true, // Enable changing page size
          pageSizeOptions: ['10', '20', '50'], // Define available page sizes
        }}
      />
    </div>
  );
};

export default LogManagementPage;
