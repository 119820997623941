import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Select, Card, message } from 'antd';
import axios from 'axios';
import config from '../../config';

const base_url = config.base_url;

const { Option } = Select;
const { TextArea } = Input;

const ShipmentUpdateButton2 = ({ uid, shipment_detail, refresh, is_ff }) => {
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();
    const [agents, setAgents] = useState(null);

    useEffect(() => {
        async function fetchAgents() {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${base_url}/agent/recommend?vendor_uid=${shipment_detail.vendor_uid}&freight_forwarder_uid=${shipment_detail.freight_forwarder_uid}&ship_type=${shipment_detail.shipment_type}&pickup_location=${shipment_detail.pickup_location}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = response.data;
                setAgents(data);
            } catch (error) {
                console.error('Error fetching agents:', error);
            }
        }
        fetchAgents();
    }, []);

    const showModal = () => {
        if (agents) {
            form.setFieldsValue({
                status: getNextStatusOptions(shipment_detail.status),
                // status: shipment_detail.status,
                shipper_name: shipment_detail.vendor.name,
                agent_contact: agents.agent_contact,
                air_way_bill: shipment_detail.air_way_bill,
                remark: shipment_detail.remark,
            });
        }
        else {
            form.setFieldsValue({
                status: getNextStatusOptions(shipment_detail.status),
                shipper_name: shipment_detail.vendor.name,
                air_way_bill: shipment_detail.air_way_bill,
                remark: shipment_detail.remark,
            });
        }
        setVisible(true);

    };

    const handleChangeAgent = (uid) => {
        const agent = agents.find((agent) => agent.uid === uid);
        const { shipper_name, agent_contact } = agent;
        form.setFieldsValue({
            shipper_name: shipper_name,
            agent_contact: agent_contact
        });
        // setVisible(true);
        console.log(uid)
    };

    const handleCancel = () => {
        form.resetFields();
        setVisible(false);
    };

    const getNextStatusOptions = (currentStatus) => {
        const nextStatusOptionsMap = {
            Packed: 'Flagged',
            Flagged: 'Pickup',
            Pickup: 'Complete'
        };
        // console.log(nextStatusOptionsMap[currentStatus])
        return nextStatusOptionsMap[currentStatus] || [];
    };

    const handleSubmit = async (values) => {
        try {
            // Prepare the data to be sent in the PUT request
            const requestData = {
                status: values.status,
                shipper_name: values.shipper_name,
                agent_contact: values.agent_contact,
                air_way_bill: values.air_way_bill,
                remark: values.remark,
            };
            // console.log(requestData)
            // Perform the PUT request using Axios
            await axios.put(`${base_url}/shipment/${uid}`, requestData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });

            // Close the modal and reset the form
            handleCancel();
            refresh();
            form.resetFields();
            message.success('Update Success');
        } catch (error) {
            console.error('Error updating shipment:', error);
        }
    };

    return (
        <div>
            <Button hidden={shipment_detail.status != "Flagged"} type="primary" style={{ marginRight: '12px', width: '120px' }} onClick={showModal}>
                Update
            </Button>
            <Modal
                title="Update Shipment"
                visible={visible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form form={form} onFinish={handleSubmit}>
                    <Form.Item
                        name="status"
                        label="Next Status"
                        rules={[{ required: true, message: 'Please select the next status' }]}
                    >
                        <Card size='small' style={{ textAlign: 'center' }}>
                            {getNextStatusOptions(shipment_detail.status)}
                        </Card>
                    </Form.Item>
                    {/* <Form.Item
                        name="agent_uid"
                        label="Agent"
                        rules={[{ required: true, message: 'Please select agent' }]}
                    >
                        <Select onChange={handleChangeAgent}>
                            {agents.length > 0 &&
                                agents.map(a => (
                                    <Option key={a.uid} value={a.uid}>{a.shipper_name} - {a.pickup_location}</Option>
                                ))}
                        </Select>
                    </Form.Item> */}
                    <Form.Item
                        name="shipper_name"
                        label="Shipper Name"
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        name="agent_contact"
                        label="Agent Contact"
                        rules={
                            is_ff
                                ? [{ required: true, message: 'Please enter agent contact' }]
                                : undefined
                        }
                    >
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    <Form.Item name="air_way_bill" label="Air Way Bill" rules={[{ required: true, message: 'Please enter air way bill' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="remark" label="Remark">
                        <TextArea rows={4} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ShipmentUpdateButton2;
