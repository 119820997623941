import moment from 'moment';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Typography, Tabs, Tag, Badge, Tooltip, Button, Modal, Form, DatePicker, message, Input, InputNumber, List, Descriptions, Select } from 'antd';
import { EditOutlined, FormOutlined, CheckCircleOutlined, SaveOutlined, ScissorOutlined, DeleteOutlined } from '@ant-design/icons';
import config from '../../config'
import UnassignTableVendor from '../../components/Table/UnassignTableVendor';
import AssignTableVendor from '../../components/Table/AssignTableVendor';
import ReadyToShipTableVendor from '../../components/Table/ReadyToShipTableVendor';
import ChangesTableVendor from '../../components/Table/ChangeTableVendor';

const base_url = config.base_url
const { TabPane } = Tabs;
const { Text } = Typography;
const { Option } = Select;

const PurchaseOrderPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get('tab');
  const [currentTab, setCurrentTab] = useState(tab?tab:'unassign');
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState('');
  const [searchValuePO, setSearchValuePO] = useState(null);
  const [unassignCount, setUnassignCount] = useState(0);
  const [assignTotal, setAssignTotal] = useState(0);
  const [commitTotal, setCommitTotal] = useState(0);
  const [assignCount, setAssignCount] = useState(0);
  const [assignCount2, setAssignCount2] = useState(0);
  const [assignCount3, setAssignCount3] = useState(0);
  const [ignoreCount, setIgnoreCount] = useState(0);
  const [commitCount, setCommitCount] = useState(0);
  const [changesTotal, setChangesTotal] = useState(0);
  const [packCount, setPackCount] = useState(0);


  const setHeader = (value) => {
    setUnassignCount(value.unassign_count);
    setAssignTotal(value.assign_count);
    setChangesTotal(value.changes_count);
    setCommitTotal(value.commit_count);
  };

  const handleTabChange = (key) => {
    setCurrentTab(key);
    setAssignCount(0);
    setAssignCount2(0);
    setAssignCount3(0);
    setIgnoreCount(0);
    setCommitCount(0);
    setPackCount(0);
  };

  const handleSearch = (value) => {
    setSearchValuePO(value);
  };

  const handleApplyFilters = () => {
    setPurchaseOrderNumber(searchValuePO);
  };

  const handleAssign = () => {
    setAssignCount(assignCount + 1);
  };

  const handleAssign2 = () => {
    setAssignCount2(assignCount2 + 1);
  };

  const handleAssign3 = () => {
    setAssignCount3(assignCount3 + 1);
  };

  const handleCommit = () => {
    setCommitCount(commitCount + 1);
  };

  const handleIgnore = () => {
    setIgnoreCount(ignoreCount + 1);
  };

  const handlePack = () => {
    setPackCount(packCount + 1);
  };

  const tabButton = <div>
    <Button
    type="primary"
    hidden={currentTab !== 'unassign'}
    onClick={() => handleAssign()}
    style={{ marginLeft: 'auto', backgroundColor: '#04AA6D' }}
  >
    Assign
  </Button>
  <Button
    type="primary"
    hidden={currentTab !== 'assign'}
    onClick={() => handleCommit()}
    style={{ marginLeft: 'auto', marginRight: '16px', backgroundColor: '#04AA6D' }}
  >
    Commit
  </Button>
  <Button
    type="primary"
    hidden={currentTab !== 'assign'}
    onClick={() => handleAssign2()}
    style={{ marginLeft: 'auto'}}
  >
    Edit Date
  </Button>
  <Button
    type="primary"
    hidden={currentTab !== 'changes'}
    onClick={() => handleAssign3()}
    style={{ marginLeft: 'auto', marginRight: '16px'}}
  >
    Change Date
  </Button>
  <Button
    type="primary"
    danger
    hidden={currentTab !== 'changes'}
    onClick={() => handleIgnore()}
    style={{ marginLeft: 'auto' }}
  >
    Ignore
  </Button>
    <Button
      type="primary"
      hidden={currentTab !== 'commit'}
      onClick={() => handlePack()}
      style={{ marginLeft: 'auto', backgroundColor: '#04AA6D' }}
    >
      Pack Shipment
    </Button>
  </div>

  return (
    <div>
      <h2>Purchase Order Items</h2>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>

        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '16px' }}>

          <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '16px' }}>
            <Input
              placeholder="Purchase Order Number"
              onChange={(e) => handleSearch(e.target.value)}
              style={{ width: '200px' }}
            />
            <Button type="primary" onClick={handleApplyFilters}>
              Apply Filters
            </Button>
          </div>

        </div>

      </div>
      <Tabs destroyInactiveTabPane={true} activeKey={currentTab} onChange={handleTabChange} tabBarExtraContent={tabButton}>
        <TabPane tab={<span>Unassign <Badge count={unassignCount} style={{ backgroundColor: '#40a9ff' }} /></span>} key="unassign">
          <UnassignTableVendor purchase_order_no={purchaseOrderNumber} setCount={setHeader} assign_count={assignCount} />
        </TabPane>
        <TabPane tab={<span>Assign <Badge count={assignTotal} style={{ backgroundColor: '#40a9ff' }} /></span>} key="assign">
          <AssignTableVendor purchase_order_no={purchaseOrderNumber} setCount={setHeader} assign_count={assignCount2} commit_count={commitCount} />
        </TabPane>
        <TabPane tab={<span>Changes <Badge count={changesTotal} style={{ backgroundColor: 'orange' }} /></span>} key="changes">
          <ChangesTableVendor purchase_order_no={purchaseOrderNumber} setCount={setHeader} assign_count={assignCount3} ignore_count={ignoreCount}/>
        </TabPane>
        <TabPane tab={<span>Ready to Pack <Badge count={commitTotal} style={{ backgroundColor: '#40a9ff' }} /></span>} key="commit">
          <ReadyToShipTableVendor purchase_order_no={purchaseOrderNumber} setCount={setHeader} pack_count={packCount} />
        </TabPane>
      </Tabs>
    </div>
  );

};

export default PurchaseOrderPage;
