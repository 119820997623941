import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DatePicker, Tabs, Tag, Badge, Button, Select } from 'antd';
import config from '../../config'
import ShipmentTable from '../../components/Table/ShipmentTable';
import jwtDecode from 'jwt-decode';
import axios from 'axios';

const base_url = config.base_url
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Option } = Select;

const ShipmentViewPage = () => {
  const token = localStorage.getItem('token');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get('tab');
  const [shippedCount, setShippedCount] = useState(0);
  const [flaggedCount, setFlaggedCount] = useState(0);
  const [pickupCount, setPickupCount] = useState(0);
  const [completeCount, setCompleteCount] = useState(0);
  const [vendors, setVendors] = useState([]);
  const [purchaseGroups, setPurchaseGroups] = useState([]);
  const [searchValueVendor, setSearchValueVendor] = useState(null)
  const [searchValuePurchaseGroup,setSearchValuePurchaseGroup] = useState(null)
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedPurchaseGroup, setSelectedPurchaseGroup] = useState(null);
  const [filters, setFilters] = useState({
    start_shipped_date: undefined,
    end_shipped_date: undefined,
  });
  const [selectedFilter, setSelectedFilter] = useState({
    start_shipped_date: undefined,
    end_shipped_date: undefined,
  });
  const decodedToken = token ? jwtDecode(token) : null;
  //
  const [currentTab, setCurrentTab] = useState(tab? tab: decodedToken.role.name === 'Freight Forwarder' ? 'flagged' : 'packed');

  useEffect(() => {
    fetchVendors();
    fetchPurchaseGroup();
  }, []);

  const fetchVendors = async (searchValuePO) => {
    try {
      const response = await axios.get(`${base_url}/vendor`, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
        params: {
          name: searchValuePO,
        },
      });
      setVendors(response.data);
    } catch (error) {
      console.error('Error fetching vendors:', error);
    }
  };

  const fetchPurchaseGroup = async () => {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${base_url}/purchase-group`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        const data = response.data;
        setPurchaseGroups(data);
    } catch (error) {
        console.error('Error fetching purchase groups:', error);
    }
}

  const handleVendorChange = (value) => {
    setSearchValueVendor(value);
  };

  const handleVendorClear = () => {
    setSearchValueVendor(null);
  };

  const handlePurchaseGroupChange = (value) => {
    setSearchValuePurchaseGroup(value);
  };

  const handlePurchaseGroupClear = () => {
    setSearchValuePurchaseGroup(null);
  };

  const setHeader = (value) => {
    setShippedCount(value.packed_count);
    setFlaggedCount(value.flagged_count);
    setPickupCount(value.pickup_count);
    setCompleteCount(value.complete_count);
  };

  const handleTabChange = (key) => {
    setCurrentTab(key);
  };

  const handleFiltersChange = (field, value) => {
    console.log('value', value)
    setFilters((prevFilters) => ({ ...prevFilters, [field]: value }));
  };
  const handleClearFilter = () => {
    console.log(filters)
    setFilters({
      start_shipped_date: undefined,
      end_shipped_date: undefined,
    });
  };

  const handleApplyFilters = () => {
    setSelectedVendor(searchValueVendor);
    setSelectedFilter(filters);
    setSelectedPurchaseGroup(searchValuePurchaseGroup);
  };

  return (
    <div>
      <h2>Shipments</h2>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>

        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '16px' }}>
          {(decodedToken.role.name !== 'Vendor') && (
            <Select
              placeholder="Select Vendor"
              hide
              onSelect={handleVendorChange}
              onClear={handleVendorClear}
              style={{ width: '200px' }}
              allowClear
              onSearch={fetchVendors}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {vendors.map((vendor) => (
                <Option key={vendor.uid} value={vendor.uid} label={vendor.name}>
                  {vendor.name}
                </Option>
              ))}
            </Select>
          )}
          {(decodedToken.role.name === 'Purchase' || decodedToken.role.name === 'Admin') && (
            <Select
              placeholder="Select Purchase Group"
              hide
              onSelect={handlePurchaseGroupChange}
              onClear={handlePurchaseGroupClear}
              style={{ width: '200px' }}
              allowClear
            >
              {purchaseGroups.map((pg) => (
                <Option key={pg.uid} value={pg.uid} label={pg.name}>
                  {pg.name}
                </Option>
              ))}
            </Select>
          )}
          <RangePicker
            style={{ marginRight: '16px' }}
            placeholder={['Start shipped date', 'End shipped date']}
            onClear={handleClearFilter}
            value={[
              filters.start_shipped_date ? moment(filters.start_shipped_date) : null,
              filters.end_shipped_date ? moment(filters.end_shipped_date) : null,
            ]}
            onChange={(dates) => {
              const startDateValue = dates && dates.length > 0 ? dates[0]?.toISOString() : null;
              handleFiltersChange('start_shipped_date', startDateValue)
            }}
            onCalendarChange={(dates) => {
              const endDateValue = dates && dates.length > 0 ? dates[1]?.toISOString() : null;
              handleFiltersChange('end_shipped_date', endDateValue)
            }}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '16px' }}>
            <Button type="primary" onClick={handleApplyFilters}>
              Apply Filters
            </Button>
          </div>

        </div>

      </div>
      <Tabs destroyInactiveTabPane={true} activeKey={currentTab} onChange={handleTabChange}>
      {(decodedToken.role.name !== 'Freight Forwarder') && (
        <TabPane tab={<span>Packed <Badge count={shippedCount} style={{ backgroundColor: '#40a9ff' }} /></span>} key="packed">
          <ShipmentTable setCount={setHeader} status={'Packed'} vendor_uid={selectedVendor} start_shipped_date={selectedFilter.start_shipped_date} end_shipped_date={selectedFilter.end_shipped_date} />
        </TabPane>
      )}
        <TabPane tab={<span>Flagged <Badge count={flaggedCount} style={{ backgroundColor: '#40a9ff' }} /></span>} key="flagged">
          <ShipmentTable setCount={setHeader} status={'Flagged'} vendor_uid={selectedVendor} purchase_group_uid={selectedPurchaseGroup} start_shipped_date={selectedFilter.start_shipped_date} end_shipped_date={selectedFilter.end_shipped_date}/>
        </TabPane>
        <TabPane tab={<span>Pickup <Badge count={pickupCount} style={{ backgroundColor: '#40a9ff' }} /></span>} key="pickup">
          <ShipmentTable setCount={setHeader} status={'Pickup'} vendor_uid={selectedVendor} purchase_group_uid={selectedPurchaseGroup} start_shipped_date={selectedFilter.start_shipped_date} end_shipped_date={selectedFilter.end_shipped_date}/>
        </TabPane>
        <TabPane tab={<span>Complete <Badge count={completeCount} style={{ backgroundColor: '#40a9ff' }} /></span>} key="complete">
          <ShipmentTable setCount={setHeader} status={'Complete'} vendor_uid={selectedVendor} purchase_group_uid={selectedPurchaseGroup} start_shipped_date={selectedFilter.start_shipped_date} end_shipped_date={selectedFilter.end_shipped_date}/>
        </TabPane>
      </Tabs>
    </div>
  );

};

export default ShipmentViewPage;
