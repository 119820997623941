import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Select, message, Upload, Space, Col, Row } from 'antd';
import { UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../config'
import UploadTooltip from '../../components/UploadTooltip';

const base_url = config.base_url
const { Option } = Select;
const { confirm } = Modal;
const { TextArea } = Input;

const AgentManagementPage = () => {
  const [agents, setAgents] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [freightForwarders, setFreightForwarders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [form] = Form.useForm();

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Number of items per page

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    fetchAgents();
  }, [currentPage, pageSize]);

  useEffect(() => {
    fetchAgents();
    fetchVendors();
    fetchFreightForwarders();
  }, []);

  const fetchAgents = async (shipperName) => {
    try {
      const token = localStorage.getItem('token');
      setLoading(true);
      const skip = (currentPage - 1) * pageSize;
      let params = {
        skip,
        limit: pageSize
      };
      if (shipperName) {
        params.shipper_name = shipperName;
      }
      const response = await axios.get(`${base_url}/agent/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      });
      setAgents(response.data.data);
      setTotalItems(response.data.total_count)
    } catch (error) {
      console.error('Error fetching agents:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    fetchAgents(value);
  };

  const fetchVendors = async (searchValue) => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get(`${base_url}/vendor`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        params: {
          name: searchValue,
        },
      });
      setVendors(response.data);
    } catch (error) {
      console.error('Error fetching vendors:', error);
    }
  };

  const fetchFreightForwarders = async (searchValue) => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get(`${base_url}/freight-forwarder`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        params: {
          name: searchValue,
        },
      });
      setFreightForwarders(response.data);
    } catch (error) {
      console.error('Error fetching vendors:', error);
    }
  };

  const handleAddAgent = async (values) => {
    try {
      const token = localStorage.getItem('token');
      let response;
      if (isEditing) {
        response = await axios.put(`${base_url}/agent/${editingData.uid}`, values, {
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        });
      } else {
        response = await axios.post(`${base_url}/agent`, values, {
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        });
      }
      if (response.status === 200 || response.status === 201) {
        fetchAgents();
        setVisible(false);
        setIsEditing(false);
        setEditingData(null);
        form.resetFields();
        message.success('Agent added successfully!');
      } else {
        throw new Error('Error handling purchase group.');
      }
    } catch (error) {
      if (isEditing) {
        console.error('Error editing agent:', error);
        message.error('Error editing agent.');
      } else {
        console.error('Error adding agent:', error);
        message.error('Error adding agent.');
      }
    }
  };

  const handleDeleteAgent = (agentUid) => {
    confirm({
      title: 'Are you sure you want to delete this agent?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          const token = localStorage.getItem('token');
          await axios.delete(`${base_url}/agent/${agentUid}`, { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } });
          setAgents((prevAgents) => prevAgents.filter((agent) => agent.uid !== agentUid));
          message.success('Agent deleted successfully!');
        } catch (error) {
          console.error('Error deleting agent:', error);
          message.error('Error deleting agent.');
        }
      },
    });
  };

  const handleEdit = (data) => {
    setIsEditing(true);
    setEditingData(data);
    form.setFieldsValue({
      freight_forwarder_uid: data.freight_forwarder_uid,
      agent_contact: data.agent_contact,
      vendor_uid: data.vendor_uid,
      ship_type: data.ship_type,
      pickup_location: data.pickup_location
    });
    setVisible(true);
  };

  const columns = [
    {
      title: 'Vendor Name',
      dataIndex: 'vendor',
      key: 'vendor',
      render: (vendor) => vendor ? vendor.name : '-',
    },
    {
      title: 'Ship Type',
      dataIndex: 'ship_type',
      key: 'ship_type',
      render: (ship_type) => ship_type ? ship_type : '-',
    },
    {
      title: 'Freight Name',
      dataIndex: 'freight_forwarder',
      key: 'freight_forwarder',
      render: (freight_forwarder) => freight_forwarder ? freight_forwarder.name : '-',
    },
    {
      title: 'Pickup Location',
      dataIndex: 'pickup_location',
      key: 'pickup_location',
      render: (pickup_location) => pickup_location ? pickup_location : '-',
    },
    { 
      title: 'Agent Contact', 
      dataIndex: 'agent_contact', 
      key: 'agent_contact', 
      ellipsis: true 
    },
    {
      title: 'Action',
      key: 'action',
      ellipsis: true,
      render: (_, record) => (
        <span>
          <Button type="link" onClick={() => handleEdit(record)}>Edit</Button>
          <Button type="link" danger onClick={() => handleDeleteAgent(record.uid)}>Delete</Button>
        </span>
      ),
    },
  ];

  return (
    <div>
      <h2>Agent</h2>
      <div style={{ marginBottom: '16px' }}>
        <Row>
          <Col span={20}>
            <Space size="middle">
              <Button type="primary" onClick={() => setVisible(true)}>
                Add Agent
              </Button>
            </Space>
          </Col>
          <Col span={4}>
            {/* <Input
              style={{ width: '200px' }}
              placeholder='Shipper Name'
              onChange={handleInputChange}
            /> */}
          </Col>

        </Row>

      </div>

      <Table
        size="small"
        dataSource={agents}
        columns={columns}
        loading={loading}
        rowKey="uid"
        pagination={{
          current: currentPage,
          total: totalItems,
          pageSize,
          onChange: handlePaginationChange,
          onShowSizeChange: handlePaginationChange,
          showSizeChanger: true, // Enable changing page size
          pageSizeOptions: ['10', '20', '50'], // Define available page sizes
        }}
      />

      <Modal
        title={isEditing ? 'Edit Agent' : 'Add Agent'}
        visible={visible}
        onCancel={() => {
          setVisible(false);
          setIsEditing(false);
          setEditingData(null);
          form.resetFields();
        }}
        onOk={() => form.submit()}
      >
        <Form form={form} onFinish={handleAddAgent}>
          <Form.Item
            name="vendor_uid"
            label="Vendor"
            rules={[{ required: true, message: 'Please select a vendor' }]}
          >
            <Select
              placeholder="Select a vendor"
              onSearch={fetchVendors}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {vendors.map((vendor) => (
                <Option key={vendor.uid} value={vendor.uid} label={vendor.name}>
                  {vendor.name}
                </Option>
              ))}
            </Select>
            </Form.Item>
            <Form.Item
                  name="ship_type"
                  label="Ship Type"
                  rules={[{ required: true, message: 'Please enter ship type' }]}
              >
                  <Select>
                      <Option value="Air">Air</Option>
                      <Option value="Sea">Sea</Option>
                      <Option value="Truck">Truck</Option>
                      <Option value="Rail">Rail</Option>
                      <Option value="Hand Carry">Hand Carry</Option>
                      <Option value="Air Courier">Air Courier</Option>
                      <Option value="Others">Others</Option>
                  </Select>
              </Form.Item>
            <Form.Item
            name="freight_forwarder_uid"
            label="Freight Forwarder"
            rules={[{ required: true, message: 'Please select a vendor' }]}
          >
            <Select
              placeholder="Select a freight forwarder"
              onSearch={fetchFreightForwarders}
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {freightForwarders.map((ff) => (
                <Option key={ff.uid} value={ff.uid} label={ff.name}>
                  {ff.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="pickup_location"
            label="Pickup Location"
            rules={[
              { 
                required: true, 
                message: 'Please enter the pickup location' 
              }
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item
            name="agent_contact"
            label="Agent Contact"
            rules={[{ required: true, message: 'Please enter the agent contact' }]}
          >
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AgentManagementPage;
