import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, message, Upload, Col, Row } from 'antd';
import { UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../config'
import UploadTooltip from '../../components/UploadTooltip';

const base_url = config.base_url
const { confirm } = Modal;


const VendorManagementPage = () => {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingVendor, setEditingVendor] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Number of items per page

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    fetchVendors();
  }, [currentPage, pageSize]);

  const fetchVendors = async (name) => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const skip = (currentPage - 1) * pageSize;
      let params = {
        skip,
        limit: pageSize
      };
      if (name) {
        params.name = name;
      }
      const response = await axios.get(`${base_url}/vendor/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      });
      setVendors(response.data.data);
      setTotalItems(response.data.total_count)
    } catch (error) {
      console.error('Error fetching vendors:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteVendor = async (vendorUid) => {
    confirm({
      title: 'Are you sure you want to delete this agent?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          await axios.delete(`${base_url}/vendor/${vendorUid}`);
          setVendors((prevVendors) => prevVendors.filter((vendor) => vendor.uid !== vendorUid));
          message.success('Vendor deleted successfully!');
        } catch (error) {
          console.error('Error deleting vendor:', error);
          message.error('Error deleting vendor.');
        }
      }
    });
  };

  const handleAddOrEditVendor = async (values) => {
    try {
      let response;
      if (isEditing) {
        response = await axios.put(`${base_url}/vendor/${editingVendor.uid}`, values);
        message.success('Vendor edited successfully!');
      } else {
        response = await axios.post(`${base_url}/vendor`, values);
        setVendors((prevVendors) => [...prevVendors, response.data]);
        message.success('Vendor added successfully!');
      }
      fetchVendors();
      setVisible(false);
      form.resetFields();
    } catch (error) {
      console.error('Error adding/editing vendor:', error);
      message.error('Error adding/editing vendor.');
    } finally {
      setIsEditing(false);
      setEditingVendor(null);
    }
  };

  const handleEditVendor = (vendor) => {
    setIsEditing(true);
    setEditingVendor(vendor);
    form.setFieldsValue({
      name: vendor.name,
      code: vendor.code,
      email: vendor.email,
      address: vendor.address,
      contact_person: vendor.contact_person,
      phone_no: vendor.phone_no,
      payment_term: vendor.payment_term,
    });
    setVisible(true);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    fetchVendors(value);
  };

  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Code', dataIndex: 'code', key: 'code' },
    { title: 'Email', dataIndex: 'email', key: 'email', render: (email) => email ? email : '-' },
    { title: 'Address', dataIndex: 'address', key: 'address', render: (address) => address ? address : '-' },
    { title: 'Contact Person', dataIndex: 'contact_person', key: 'contact_person', render: (contact_person) => contact_person ? contact_person : '-' },
    { title: 'Phone No.', dataIndex: 'phone_no', key: 'phone_no', render: (phone_no) => phone_no ? phone_no : '-' },
    { title: 'Payment Term', dataIndex: 'payment_term', key: 'payment_term', render: (payment_term) => payment_term ? payment_term : '-' },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <span>
          <Button type="link" onClick={() => handleEditVendor(record)}>Edit</Button>
          <Button type="link" danger onClick={() => handleDeleteVendor(record.uid)}>Delete</Button>
        </span>
      ),
    },
  ];

  return (
    <div>
      <h2>Vendor</h2>
      <div style={{ marginBottom: '16px' }}>
        <Row>
          <Col span={20}>
            <Button style={{ marginRight: '16px' }} type="primary" onClick={() => setVisible(true)}>
              Add Vendor
            </Button>
            <Upload
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              action={`${base_url}/upload/upload-vendor`}
              headers={{ Authorization: `Bearer ${localStorage.getItem('token')}` }}
              beforeUpload={() => {
                setIsUploading(true); // Set isUploading to true when the upload starts
                return true; // Return true to allow the upload to proceed
              }}
              onChange={(info) => {
                if (info.file.status !== 'uploading') {
                  console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                  message.success(`${info.file.name} file uploaded successfully`);
                  // re-fetch the agent data after a file is uploaded
                  fetchVendors();
                } else if (info.file.status === 'error') {
                  message.error(`${info.file.name} file upload failed.`);
                }
                setIsUploading(false);
              }}
              showUploadList={false}
            >
              <Button loading={isUploading} icon={<UploadOutlined />} style={{ marginRight: '16px' }}>Upload Excel </Button>
            </Upload>
            <UploadTooltip/>
          </Col>
          <Col span={4}>
            <Input
              style={{ width: '90%' }}
              placeholder='Vendor Name'
              onChange={handleInputChange}
            >
            </Input>
          </Col>
        </Row>
      </div>
      <Table
        size="small"
        dataSource={vendors}
        columns={columns}
        loading={loading}
        rowKey="uid"
        pagination={{
          current: currentPage,
          total: totalItems,
          pageSize,
          onChange: handlePaginationChange,
          onShowSizeChange: handlePaginationChange,
          showSizeChanger: true, // Enable changing page size
          pageSizeOptions: ['10', '20', '50'], // Define available page sizes
        }}
      />

      <Modal
        title={isEditing ? 'Edit Vendor' : 'Add Vendor'}
        visible={visible}
        onCancel={() => {
          setVisible(false);
          setIsEditing(false);
          setEditingVendor(null);
          form.resetFields();
        }}
        onOk={() => form.submit()}
      >
        <Form form={form} onFinish={handleAddOrEditVendor}>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please enter the vendor name' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="code"
            label="Code"
            rules={[{ required: true, message: 'Please enter the vendor code' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              // { required: true, message: 'Please enter your email' },
              { type: 'email', message: 'Please enter a valid email' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="address"
            label="Address"
          // rules={[{ required: true, message: 'Please enter the address' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="contact_person"
            label="Contact Person"
          // rules={[{ required: true, message: 'Please enter the contact person' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phone_no"
            label="Phone No."
          // rules={[{ required: true, message: 'Please enter the phone number' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="payment_term"
            label="Payment Term"
          // rules={[{ required: true, message: 'Please enter the Payment Term' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default VendorManagementPage;
