import React, { useEffect, useState } from 'react';
import { Card, Button, Modal, Form, Input, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import axios from 'axios';
import config from '../../config';
import AttachmentDeleteButton from '../Button/AttachmentDeleteButton';

const base_url = config.base_url;

const PurchaseItemFileListCard = ({ purchase_order_header_uid }) => {
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [detail, setDetail] = useState('')
    const [fileType, setFileType] = useState('')
    const token = localStorage.getItem('token');
    const decodedToken = token ? jwtDecode(token) : null;

    useEffect(() => {
        fetchFileList();
    }, [purchase_order_header_uid]);

    const fetchFileList = async () => {
        try {
            const response = await fetch(`${base_url}/file?attach_uid=${purchase_order_header_uid}`);
            const data = await response.json();
            setUploadedFiles(Array.isArray(data) ? data : []);
        } catch (error) {
            console.error('Error fetching file list:', error);
        }
    };

    const handleFileDownload = (record) => {
        const url = `${base_url}/file/download-file/${record.uid}`;

        fetch(url, { method: 'GET' })
            .then((response) => {
                if (!response.ok) throw new Error(response.status);
                return response.blob();
            })
            .then((blob) => {
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = record.file_name;
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((error) => console.error('Error:', error));
    };

    const handleFileChange = ({ fileList }) => {
        setFileList(fileList);
    };

    const uploadFile = async () => {
        if (fileList.length > 0) {
            for (const file of fileList) {
                const formData = new FormData();
                formData.append('file', file.originFileObj);
                const queryParams = new URLSearchParams();
                queryParams.append('attach_type', 'Purchase Order');
                queryParams.append('file_type', fileType);
                queryParams.append('owner_role', decodedToken.role.name);
                queryParams.append('detail', detail);

                try {
                    await axios.post(`${base_url}/file/attach-file/${purchase_order_header_uid}?${queryParams.toString()}`, formData);
                    message.success('File uploaded successfully');
                } catch (error) {
                    message.error('File upload failed');
                }
                setFileList([]);
            };
            fetchFileList();
            setUploading(false);
            closeModal();
        } else {
            // All files uploaded, set uploading to false
            setUploading(false);
        }
    };

    const handleUpload = async () => {
        setUploading(true);
        uploadFile();
    };

    const openModal = () => {
        setModalVisible(true);
        if (decodedToken.role.name === 'Vendor') {
            setFileType('Order Acknowledge')
        }
    };

    const closeModal = () => {
        setModalVisible(false);
        setFileList([]);
        setDetail('');
        setFileType('');
    };

    return (
        <div>
            <Card
                title={
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>Attachments</span>
                        <Button
                            hidden={decodedToken.role.name !== 'Admin'}
                            type="primary" icon={<UploadOutlined />}
                            onClick={openModal}
                        // onClick={handleAttachFile}
                        >
                            Attach
                        </Button>
                    </div>
                }
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>Total Attachments: {uploadedFiles.length}</span>
                </div>
                {uploadedFiles.length === 0 ? (
                    <p>No files attached</p>
                ) : (
                    <div style={{ maxHeight: '250px', overflow: 'auto' }}   >
                        {uploadedFiles.map((item) => (
                            <div key={item.id} style={{ marginBottom: '20px' }}>
                                <a onClick={() => handleFileDownload(item)}>
                                    {item.file_name}
                                </a>
                                <div>
                                    <span>Date: {moment(item.attach_date).format('DD-MM-YYYY')} / </span>
                                    <span>Type: {item.file_type ? item.file_type : '-'} / </span>
                                    <span>Detail: {item.detail}</span>
                                </div>
                                <AttachmentDeleteButton uid={item.uid} delete_role={item.owner_role} refresh={fetchFileList}></AttachmentDeleteButton>
                                <hr></hr>
                            </div>
                        ))}
                    </div>
                )}
            </Card>
            <Modal
                title="Attach File"
                visible={modalVisible}
                onCancel={closeModal}
                footer={null}
            >
                <Upload
                    fileList={fileList}
                    onChange={handleFileChange}
                    beforeUpload={() => false}
                    multiple
                >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
                File Type:
                <Input disabled={decodedToken.role.name === 'Vendor'} value={fileType} onChange={(v) => setFileType(v.target.value)} />
                Detail (Availabe When Upload Single File):
                <Input.TextArea placeholder='Detail' rows={4} value={detail} onChange={(v) => setDetail(v.target.value)} />
                <hr></hr>
                <Button
                    type="primary"
                    onClick={handleUpload}
                    disabled={fileList.length === 0 || uploading}
                >
                    {uploading ? 'Uploading...' : 'Upload'}
                </Button>
            </Modal>
        </div>
    );
};

export default PurchaseItemFileListCard;
