import React from 'react';
import { Button, List, message } from 'antd';
import config from '../../config'

const base_url = config.base_url

const ToolsPage = () => {
  const handleGetDailyFtp = async () => {
    try {
      const response = await fetch(`${base_url}/utils/daily-ftp`);
      const data = await response.json();
      // Display the response data as a success message
      message.success(JSON.stringify(data));
    } catch (error) {
      console.error('Error:', error);
      message.error('Failed to fetch daily FTP.');
    }
  };

  const handleGetDailyUpdate = async () => {
    try {
      const response = await fetch(`${base_url}/utils/daily-update`);
      const data = await response.json();
      // Display the response data as a success message
      message.success("Update "+JSON.stringify(data)+" Purchese Order");
    } catch (error) {
      console.error('Error:', error);
      message.error('Failed to fetch daily update.');
    }
  };

  const commandData = [
    {
      title: 'Retrieve daily FTP data: Get SAP data from ccs',
      action: handleGetDailyFtp,
    },
    {
      title: 'Retrieve daily update: Update Delay and late today',
      action: handleGetDailyUpdate,
    },
  ];

  const listHeader = <h3>Commands</h3>;
  return (
    <div>
      <h2>Tools</h2>
      <List
        dataSource={commandData}
        header={listHeader}
        renderItem={(item) => (
          <List.Item>
            <p>{item.title}</p>
            <Button type="primary" onClick={item.action}>Execute</Button>
          </List.Item>
        )}
      />
    </div>
  );
};

export default ToolsPage;
