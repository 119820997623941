// ReportPage.js
import React, { useState, useEffect } from 'react';
import { Card, Pagination, Row, Col, Statistic } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../config'

const base_url = config.base_url

const ReportPage = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0)
  const pageSize = 5;

  useEffect(() => {
    fetchData();
  }, [currentPage]); // The empty dependency array ensures this effect runs only once on mount

  const fetchData = async () => {
    try {
      // Retrieve the token from local storage
      const token = localStorage.getItem('token'); // Replace 'your_token_key' with your actual key
      const skip = (currentPage - 1) * pageSize;
      let params = {
        skip: skip,
        limit: pageSize,
      };
      // Replace the following with your actual API endpoint
      const response = await axios.get(`${base_url}/report/pagination`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
        });

      setData(response.data.data);
      setTotalData(response.data.total_count);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const columns = [
    { title: 'Created Date', dataIndex: 'created_date', key: 'created_date' },
    { title: 'PO Unassign Count', dataIndex: 'po_unassign_count', key: 'po_unassign_count' },
    // Add more columns for other counts
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <h2>Report Page</h2>
      <Row gutter={16}>
        <Col span={4}></Col>
        <Col span={12}><h3>Purchase Order</h3></Col>
        <Col span={8}><h3>Shipment</h3></Col>
      </Row>
      <div>
        {data.map((item, index) => (
          <div>
            <Row gutter={16}>
              <Col span={4} >
                <Card bordered={false} size='small'>
                  <Statistic
                    title="Date"
                    value={new Date(item.created_date).toLocaleDateString()}
                    valueStyle={{ color: '#3f8600' }}
                  />
                </Card>
              </Col>
              <Col span={2} >
                <Card bordered={false} size='small'>
                  <Statistic
                    title="Unassign"
                    value={item.po_unassign_count}
                  />
                </Card>
              </Col>
              <Col span={2}>
                <Card bordered={false} size='small'>
                  <Statistic
                    title="Assign"
                    value={item.po_assign_count}
                  />
                </Card>
              </Col>
              <Col span={2}>
                <Card bordered={false} size='small'>
                  <Statistic
                    title="Commit"
                    value={item.po_commit_count}
                  />
                </Card>
              </Col>
              <Col span={2}>
                <Card bordered={false} size='small'>
                  <Statistic
                    title="Packed"
                    value={item.po_packed_count}
                  />
                </Card>
              </Col>
              <Col span={2}>
                <Card bordered={false} size='small'>
                  <Statistic
                    title="Delay"
                    value={item.po_delay_count}
                    valueStyle={{ color: '#cf1322' }}
                  />
                </Card>
              </Col>
              <Col span={2}>
                <Card bordered={false} size='small'>
                  <Statistic
                    title="Late"
                    value={item.po_late_count}
                    valueStyle={{ color: '#cf1322' }}
                  />
                </Card>
              </Col>
              <Col span={2}>
                <Card bordered={false} size='small'>
                  <Statistic
                    title="Packed"
                    value={item.shp_packed_count}
                  />
                </Card>
              </Col>
              <Col span={2}>
                <Card bordered={false} size='small'>
                  <Statistic
                    title="Flagged"
                    value={item.shp_flagged_count}
                  />
                </Card>
              </Col>
              <Col span={2}>
                <Card bordered={false} size='small'>
                  <Statistic
                    title="Pickup"
                    value={item.shp_pickup_count}
                  />
                </Card>
              </Col>
              <Col span={2}>
                <Card bordered={false} size='small'>
                  <Statistic
                    title="Complete"
                    value={item.shp_complete_count}
                  />
                </Card>
              </Col>
            </Row>
          </div>

        ))}
      </div >
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
      <Pagination
        current={currentPage}
        total={totalData}
        pageSize={pageSize}
        onChange={handlePageChange}
        style={{ marginTop: '16px' }}
      />
      </div>
    </div>
  );
};

export default ReportPage;
