import React, { useState, useEffect } from 'react';
import { Table, Card, Button, Modal, InputNumber, Alert, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';
import config from '../../config';

const base_url = config.base_url;

const DeliveryScheduleCard = ({ shipment_uid, editable }) => {
    const [deliverySchedule, setDeliverySchedule] = useState([]);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editUid, setEditUid] = useState(null);
    const [maxQty, setMaxQty] = useState(0);
    const [numberValue, setNumberValue] = useState(0);

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async () => {
        const deliveryResponse = await fetch(`${base_url}/delivery-schedule?shipment_uid=${shipment_uid}`);
        const deliveryData = await deliveryResponse.json();
        setDeliverySchedule(deliveryData);
    }

    const handleNumberChange = (value) => {
        setNumberValue(value);
    };

    const showEditModal = (uid, quantity) => {
        setEditUid(uid)
        setMaxQty(quantity)
        setEditModalVisible(true);
    };

    const handleEditModalOk = () => {
        axios.put(`${base_url}/delivery-schedule/edit-qty/${editUid}?quantity=${numberValue}`)
            .then(() => {
                message.success('Update Quantity successfully');
                setEditModalVisible(false);
                fetchData();
            })
            .catch(error => {
                message.error('Error Update Quantity');
            });

    };

    const handleEditModalCancel = () => {
        setEditModalVisible(false);
        setNumberValue(0)
        console.log(editModalVisible)
    };

    function renderQuantity(quantity) {
        if (Number.isInteger(quantity)) {
          return quantity.toString(); // If it's an integer, no decimal places
        } else {
          return quantity.toFixed(3); // If it's a float, round to 3 decimal places
        }
      }

    const deliveryScheduleColumns = [
        {
            title: 'PO. Number',
            dataIndex: 'purchase_order_item',
            key: 'purchase_order_item',
            width: 110,
            render: (data) => data.purchase_order_no
        },
        {
            title: 'Line No',
            dataIndex: 'purchase_order_item',
            key: 'purchase_order_item',
            width: 70,
            render: (data) => data.line_no
        },
        {
            title: 'Material Code',
            dataIndex: 'purchase_order_item',
            key: 'purchase_order_item',
            width: 110,
            ellipsis: true,
            render: (data) => data.material_code
        },
        {
            title: 'Description',
            dataIndex: 'purchase_order_item',
            key: 'purchase_order_item',
            width: 110,
            ellipsis: true,
            render: (data) => data.note
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 75,
            render: (quantity) => renderQuantity(quantity),
        },
        {
            title: 'Assign Date',
            dataIndex: 'supplier_delivery_date',
            key: 'supplier_delivery_date',
            width: 100,
            render: text => moment(text).format('DD-MM-YYYY'),
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
            render: (text) => text || '-',
        },
        {
            title: 'Remark',
            dataIndex: 'remark',
            key: 'remark',
            render: (text) => text || '-',
        }
    ];

    return (
        <div>
            <Card title="Delivery Schedule" >
                {deliverySchedule.length === 0 ? (
                    <p>No delivery schedule available</p>
                ) : (
                    <Table dataSource={deliverySchedule} columns={editable ? [...deliveryScheduleColumns, {
                        title: '-',
                        width: 50,
                        render: (data) => (
                            <Button
                                type='primary'
                                size='small'
                                icon={<EditOutlined />} // Replace with the icon you prefer
                                onClick={() => showEditModal(data.uid, data.quantity)}
                            />
                        ),
                    }] : deliveryScheduleColumns} rowKey="uid" pagination={false} size="small" />
                )}
            </Card>
            <Modal
                title="Edit Quantity"
                visible={editModalVisible}
                onOk={handleEditModalOk}
                onCancel={handleEditModalCancel}
            >
                {numberValue == 0 && (
                    <Alert
                        message="This item is going to be deleted."
                        type="warning"
                        showIcon
                    />
                )}
                <br></br>
                Quantity: <InputNumber min={0} max={maxQty} value={numberValue} onChange={handleNumberChange} />
            </Modal>
        </div>
    );
};

export default DeliveryScheduleCard;

