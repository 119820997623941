import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Select, message, Row, Col } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../config'
import '../../css/style.css'; // Import Style

const customStyle = 'custom-table';

const base_url = config.base_url
const { Option } = Select;
const { confirm } = Modal;

const UserManagementPage = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [freightForwarders, setFreightForwarders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedRoleUid, setSelectedRoleUid] = useState(null);
  const [searchNameValue, setSearchNameValue] = useState(null);
  const [form] = Form.useForm();
  const [resetPasswordVisible, setResetPasswordVisible] = useState(false);
  const [resetPasswordForm] = Form.useForm();
  const [userToResetPassword, setUserToResetPassword] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Number of items per page
  const [editForm] = Form.useForm();
  const [editVisible, setEditVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null);

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    fetchUsers();
  }, [currentPage, pageSize, selectedRoleUid, searchNameValue]);

  useEffect(() => {
    fetchUsers();
    fetchRoles();
    fetchFreightForwarders();
    fetchVendors();
  }, []);

  const fetchUsers = async () => {
    const token = localStorage.getItem('token');

    try {
      setLoading(true);
      const skip = (currentPage - 1) * pageSize;
      const response = await axios.get(`${base_url}/user/pagination`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        params: {
          skip,
          limit: pageSize,
          role_uid: selectedRoleUid,
          username_name: searchNameValue,
        }
      });
      setUsers(response.data.data);
      setTotalItems(response.data.total_count)
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRoles = async () => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get(`${base_url}/role`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
      });
      setRoles(response.data);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const fetchVendors = async (searchValue) => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get(`${base_url}/vendor`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        params: {
          name: searchValue,
        },
      });
      setVendors(response.data);
    } catch (error) {
      console.error('Error fetching vendors:', error);
    }
  };

  const fetchFreightForwarders = async (searchValue) => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.get(`${base_url}/freight-forwarder`, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        params: {
          name: searchValue,
        },
      });
      setFreightForwarders(response.data);
    } catch (error) {
      console.error('Error fetching vendors:', error);
    }
  };

  const handleAddUser = async (values) => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(`${base_url}/user/register`, values, {
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
      });
      setUsers((prevUsers) => [...prevUsers, response.data]);
      setVisible(false);
      fetchUsers();
      form.resetFields();
      message.success('User added successfully!');
    } catch (error) {
      console.error('Error adding user:', error);
      message.error('Error adding user.');
    }
  };

  const handleDeleteUser = async (userUid) => {
    confirm({
      title: 'Are you sure you want to delete this user?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: async () => {
        const token = localStorage.getItem('token');

        try {
          await axios.delete(`${base_url}/user/${userUid}`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
          });
          setUsers((prevUsers) => prevUsers.filter((user) => user.uid !== userUid));
          message.success('User deleted successfully!');
        } catch (error) {
          console.error('Error deleting user:', error);
          message.error('Error deleting user.');
        }
      }
    });
  };

  const handleResetPassword = async (values) => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(`${base_url}/user/change-password-admin`,
        { uid: userToResetPassword, new_password: values.new_password },
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } }
      );

      message.success('Password reset successfully!');
      setResetPasswordVisible(false);
      resetPasswordForm.resetFields();
    } catch (error) {
      console.error('Error resetting password:', error);
      message.error('Error resetting password.');
    }
  };

  const handleEdit = async (values) => {
    const token = localStorage.getItem('token');

    try {
      const response = await axios.put(`${base_url}/user/${editingUser}`,
        { name: values.name, email: values.email },
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } }
      );

      message.success('Edit successfully!');
      setEditVisible(false);
      fetchUsers();
      editForm.resetFields();
    } catch (error) {
      console.error('Error editing:', error);
      message.error('Error Editing.');
    }
  };

  const columns = [
    { title: 'Username', dataIndex: 'username', key: 'username' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Vendor Name', dataIndex: ['vendor', 'name'], key: 'vendorName', render: (name) => (name ? name : '-') },
    { title: 'Role Name', dataIndex: ['role', 'name'], key: 'roleName' },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <span>
          <Button type="link" onClick={() => {
            setEditVisible(true);
            setEditingUser(record.uid);
            editForm.setFieldValue('name', record.name)
            editForm.setFieldValue('email', record.email)
          }}>
            Edit
          </Button>
          <Button type="link" onClick={() => {
            setUserToResetPassword(record.uid);
            setResetPasswordVisible(true);
          }}>
            Reset Password
          </Button>
          <Button type="link" danger onClick={() => handleDeleteUser(record.uid)}>
            Delete
          </Button>
        </span>
      ),
    },
  ];

  return (
    <div>
      <h2>Users</h2>
      <div style={{ marginBottom: '16px' }}>
        <Row>
          <Col span={16}>
            <Button type="primary" onClick={() => setVisible(true)}>
              Add User
            </Button>
          </Col>
          <Col span={4}>
            <Input
              style={{ width: '90%' }}
              placeholder='Search'
              allowClear
              value={searchNameValue}
              onClear={() => {
                setSearchNameValue(null);
              }}
              onChange={(event) => {
                const value = event.target.value;
                setSearchNameValue(value);
              }}
            />
          </Col>
          <Col span={4}>
            <Select
              style={{ width: '90%' }}
              placeholder="Select a role"
              allowClear
              onClear={() => { setSelectedRoleUid(null); }}
              onChange={(value) => {
                setSelectedRoleUid(value);
              }}
            >
              {roles.map((role) => (
                <Option key={role.uid} value={role.uid}>
                  {role.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      </div>
      <Table
        size="small"
        dataSource={users}
        columns={columns}
        loading={loading}
        rowKey="uid"
        pagination={{
          current: currentPage,
          total: totalItems,
          pageSize,
          onChange: handlePaginationChange,
          onShowSizeChange: handlePaginationChange,
          showSizeChanger: true, // Enable changing page size
          pageSizeOptions: ['10', '20', '50'], // Define available page sizes
        }}
      />

      <Modal
        title="Add User"
        visible={visible}
        onCancel={() => { setVisible(false); form.resetFields() }}
        onOk={() => form.submit()}
      >
        <Form form={form} onFinish={handleAddUser}>
          <Form.Item
            name="username"
            label="Username"
            rules={[{ required: true, message: 'Please enter the username' }, { min: 3, message: 'Username must be at least 4 characters long' },]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Please enter the password' }, { min: 6, message: 'Password must be at least 6 characters long' },]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="name"
            label="Name"
            rules={[]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[{ type: 'email', message: 'Please enter a valid email' },]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="role_uid"
            label="Role"
            rules={[{ required: true, message: 'Please select a role' }]}
          >
            <Select
              onChange={(value) => {
                const selectedRole = roles.find(role => role.uid === value);
                setSelectedRole(selectedRole ? selectedRole.name : null);
              }}
              placeholder="Select a role"
            >
              {roles.map((role) => (
                <Option key={role.uid} value={role.uid}>{role.name}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="vendor_uid"
            label="Vendor"
            rules={[{ required: selectedRole === 'Vendor', message: 'Please select a vendor' }]}
          >
            <Select
              placeholder="Select a vendor"
              onSearch={fetchVendors}
              showSearch
              disabled={selectedRole !== 'Vendor'}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {vendors.map((vendor) => (
                <Option key={vendor.uid} value={vendor.uid} label={vendor.name}>
                  {vendor.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="freight_forwarder_uid"
            label="Freight Forwarder"
            rules={[{ required: selectedRole === 'Freight Forwarder', message: 'Please select a freight forwarder' }]}
          >
            <Select
              placeholder="Select a freight forwarder"
              onSearch={fetchFreightForwarders}
              showSearch
              disabled={selectedRole !== 'Freight Forwarder'}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {freightForwarders.map((freightForwarder) => (
                <Option key={freightForwarder.uid} value={freightForwarder.uid} label={freightForwarder.name}>
                  {freightForwarder.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Reset Password"
        visible={resetPasswordVisible}
        onCancel={() => setResetPasswordVisible(false)}
        onOk={() => resetPasswordForm.submit()}
      >
        <Form form={resetPasswordForm} onFinish={handleResetPassword}>
          <Form.Item
            name="new_password"
            label="New Password"
            rules={[{ required: true, message: 'Please enter the new password' }, { min: 6, message: 'Password must be at least 6 characters long' }]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit User Info"
        visible={editVisible}
        onCancel={() => setEditVisible(false)}
        onOk={() => editForm.submit()}
      >
        <Form form={editForm} onFinish={handleEdit}>
          <Form.Item
            name="name"
            label="Name"
            rules={[]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[{ type: 'email', message: 'Please enter a valid email' },]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserManagementPage;
