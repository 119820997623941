import React, { useState, useEffect } from 'react';
import { Table, Button, Tag, Card } from 'antd'; // Import Button from 'antd'
import { useNavigate, Link } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../config';
import CopyToClipboardButton from '../Button/CopyToClipBoardButton';

const base_url = config.base_url;

const StatusTableWithPurchaseName = (params) => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [header, setHeader] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10); // Number of items per page
    const [purchaseGroup, setPurchaseGroup] = useState([])

    useEffect(() => {
        fetchData();
    }, [currentPage, pageSize, params.vendor_uid, params.purchase_order_no, params.filter_purchase_group, params.is_delay, params.is_late]);

    useEffect(() => {
        setPurchaseGroup(params.purchase_group)
    }, [params.purchase_group])

    useEffect(() => {
        params.setCount(header)
    }, [header])

    const fetchData = async () => {
        try {
            setLoading(true);
            const skip = (currentPage - 1) * pageSize;
            const response = await axios.get(`${base_url}/purchase-order-item/pagination`, {
                params: {
                    skip,
                    limit: pageSize,
                    vendor_uid: params.vendor_uid,
                    status: params.status,
                    purchase_order_no: params.purchase_order_no,
                    purchase_group: params.filter_purchase_group,
                    is_delay: params.is_delay,
                    is_late: params.is_late,
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    // Add other headers if needed
                },
            });
            setData(response.data.data);
            setTotalItems(response.data.total_count);
            setHeader(response.data.header);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const handlePurchaseOrderClick = (uid) => {
        navigate(`/purchase-order/${uid}`);
    };


    const handlePaginationChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const getChildRow = (record) => {
        const { delivery_schedules } = record;
        if (delivery_schedules && delivery_schedules.length > 0) {
            return (
                <tr>
                    <td style={{ padding: 0 }}>
                        <div style={{ display: 'flex', justifyContent: 'center', gap: '16px', flexWrap: 'wrap' }}>
                            {delivery_schedules.map((schedule) => (
                                <Card key={schedule.id} size="small" style={{ width: '200px' }}>
                                    <p style={{ marginBottom: '4px' }}>Assign Date: {new Date(schedule.supplier_delivery_date).toLocaleDateString()}</p>
                                    <p style={{ marginBottom: '4px' }}>Request Date: {schedule.request_delivery_date ? new Date(schedule.request_delivery_date).toLocaleDateString(): '-'}</p>
                                    <p style={{ marginBottom: '4px' }}>Quantity: {renderQuantity(schedule.quantity)}</p>
                                    <p style={{ marginBottom: '4px' }}>Commit: {schedule.confirmation_type ? 'Yes' : 'No'}</p>
                                    <p style={{ marginBottom: '4px' }}>Shipped: {schedule.shipment_uid ? 'Yes' : 'No'}</p>
                                </Card>
                            ))}
                        </div>
                    </td>
                </tr>
            );
        }
        return null;
    };

    const mapPurchaseGroupName = (purchaseGroupData, purchaseGroup) => {
        const matchingGroup = purchaseGroupData.find(group => group.purchase_group === purchaseGroup);
        if (matchingGroup) {
            const nameParts = matchingGroup.name.split(' ');
            if (nameParts.length >= 2) {
                const firstName = nameParts[0];
                const lastName = nameParts.slice(1).join(' ');
                return `${firstName} ${lastName.charAt(0)}.`;
            } else {
                return matchingGroup.name; // Return the original name if it doesn't have a last name.
            }
        } else {
            return '-';
        }
    };
    function renderQuantity(quantity) {
        if (Number.isInteger(quantity)) {
          return quantity.toString(); // If it's an integer, no decimal places
        } else {
          return quantity.toFixed(3); // If it's a float, round to 3 decimal places
        }
      }

    const columns = [
        {
            title: 'PO. No',
            dataIndex: 'purchase_order_no',
            key: 'purchase_order_no',
            width: 120,
            render: (_, record) => (
                <Button type="link" style={{ padding: '0' }}>
                    <Link to={`/purchase-order/${record.uid}`}>{record.purchase_order_no}</Link>
                    <CopyToClipboardButton content={record.purchase_order_no} />
                </Button>
            ),
        },
        {
            title: 'Line No',
            dataIndex: 'line_no',
            key: 'line_no',
            width: 70,
        },
        {
            title: 'Material Code',
            dataIndex: 'material_code',
            key: 'material_code',
            width: 180,
        },
        {
            title: 'Description',
            dataIndex: 'note',
            ellipsis: true,
            key: 'note',
            width: 120,
        },
        {
            title: `${params.status} Quantity`,
            dataIndex: 'quantity',
            width: 80,
            render: (_, data) => {
                let sum = 0
                if (params.status === 'Unassign'){
                    if (data.delivery_schedules){
                        sum = data.quantity - data.delivery_schedules.reduce((acc, schedule) => acc + schedule, 0);
                    } else {
                        sum = data.quantity;
                    }
                }
                else if (params.status === 'Assign'){
                    sum = sum = data.delivery_schedules.reduce((acc, schedule) => {
                        if (schedule.confirmation_type === null) {
                          return acc + schedule.quantity;
                        }
                        return acc;
                      }, 0);
                }
                else if (params.status === 'Commit'){
                    sum = sum = data.delivery_schedules.reduce((acc, schedule) => {
                        if (schedule.confirmation_type !== null && schedule.shipment_uid === null) {
                          return acc + schedule.quantity;
                        }
                        return acc;
                      }, 0);
                }
                else if (params.status === 'Packed'){
                    sum = sum = data.delivery_schedules.reduce((acc, schedule) => {
                        if (schedule.shipment_uid !== null) {
                          return acc + schedule.quantity;
                        }
                        return acc;
                      }, 0);
                }
                sum = renderQuantity(sum);
                return <span>{`${sum}`}</span>;
            },
        },
        {
            title: 'Full Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 80,
            render: (quantity) => renderQuantity(quantity),
        },
        {
            title: 'Request Date',
            dataIndex: 'request_delivery_date',
            key: 'request_delivery_date',
            width: 120,
            render: (date) => new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }),
        },
        {
            title: 'Vendor',
            dataIndex: 'vendor',
            key: 'vendor',
            ellipsis: true,
            width: 150,
            render: (vendor) => `${vendor.code} ${vendor.name}`,
        },
        {
            title: 'Assignee',
            dataIndex: 'purchase_order_header',
            key: 'purchase_order_header',
            width: 130,
            render: (purchase_order_header) => `${mapPurchaseGroupName(purchaseGroup, purchase_order_header.purchase_group)} (${purchase_order_header.purchase_group})`,
        },
        {
            title: 'Delay',
            dataIndex: 'is_delay',
            key: 'is_delay',
            width: 50,
            render: (is_delay) => is_delay? <Tag color='yellow'>Yes</Tag> : <Tag color='green'>No</Tag>,
        },
        {
            title: 'Late',
            dataIndex: 'is_late',
            key: 'is_late',
            width: 50,
            render: (is_late) => is_late? <Tag color='red'>Yes</Tag> : <Tag color='green'>No</Tag>,
        },
        // {
        //     title: 'Action',
        //     dataIndex: 'uid',
        //     key: 'action',
        //     render: (uid) => (
        //         <Popconfirm
        //             title="Are you sure you want to delete this item?"
        //             onConfirm={() => handleDelete(uid)}
        //             okText="Yes"
        //             cancelText="No"
        //         >
        //             <Button type="link" danger icon={<DeleteOutlined />} />
        //         </Popconfirm>
        //     ),
        // },
    ];

    return (
        <div>
            <Table
                dataSource={data}
                columns={columns}
                loading={loading}
                size='small'
                rowKey="uid"
                pagination={{
                    current: currentPage,
                    total: totalItems,
                    pageSize,
                    onChange: handlePaginationChange,
                    onShowSizeChange: handlePaginationChange,
                    showSizeChanger: true, // Enable changing page size
                    pageSizeOptions: ['10', '20', '50', '100'], // Define available page sizes
                }}
                expandable={{
                    expandedRowRender: (record) => getChildRow(record),
                    rowExpandable: (record) => record.delivery_schedules && record.delivery_schedules.length > 0,
                }}
            />
        </div>
    );
};

export default StatusTableWithPurchaseName;

