import React, { useEffect, useState } from 'react';
import { Button, Card, List, Skeleton, Tag, Pagination, Row, Col, Select, Modal, Form, message } from 'antd';
import { FilePptOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import config from '../../config'
import TicketCreateButton from '../../components/Button/TicketCreateButton';

const base_url = config.base_url

const TicketPage = () => {
  const [ticketData, setTicketData] = useState([])
  const [totalTicketData, setTotalTicketData] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState('Open');
  const [purchaseGroup, setPurchaseGroup] = useState([]);
  const [searchValuePG, setSearchValuePG] = useState(null)
  const [ticketUID, setTicketUID] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const token = localStorage.getItem('token');
  const decodedToken = token ? jwtDecode(token) : null;

  useEffect(() => {
    if (decodedToken.role.name === 'Admin' || decodedToken.role.name === 'Purchaser') {
      fetchPurchaseGroup();
    }
  }, []);

  useEffect(() => {
    fetchPaginatedTickets();
  }, [currentPage, selectedStatus, searchValuePG]);

  const fetchPaginatedTickets = async () => {
    try {
      const token = localStorage.getItem('token');
      const skip = (currentPage - 1) * 5;
      let params = {
        status: selectedStatus,
        skip: skip,
        purchase_group_uid: searchValuePG,
        limit: 5,
      };
      const response = await axios.get(`${base_url}/ticket/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params,
      });

      // Check if the response status is OK (200)
      if (response.status === 200) {
        const paginatedTickets = response.data;
        setTicketData(paginatedTickets.data)
        setTotalTicketData(paginatedTickets.total_count)
      } else {
        console.error('Failed to fetch paginated tickets. Status:', response.status);
      }
    } catch (error) {
      console.error('Error fetching paginated tickets:', error);
    }
  };

  const fetchPurchaseGroup = async () => {
    try {
      const response = await axios.get(`${base_url}/purchase-group`, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        }
      });
      setPurchaseGroup(response.data);
    } catch (error) {
      console.error('Error fetching purchase group:', error);
      // Handle fetch error here
    }
  };

  const handleSubmit = async (values) => {
    try {
      const requestData = {
        purchase_group_uid: values.purchase_group_uid
      };
      // console.log(requestData)
      // Perform the PUT request using Axios
      await axios.put(`${base_url}/ticket/${ticketUID}`, requestData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      // Close the modal and reset the form
      displayModalCancel();
      fetchPaginatedTickets();
      form.resetFields();
      message.success('Update Success');
    } catch (error) {
      console.error('Error updating purchase group:', error);
    }
  };

  const handlePurchaseGroupChange = (value) => {
    setSearchValuePG(value);
  };

  const handlePurchaseGroupClear = () => {
    setSearchValuePG(null);
  };

  const getStatusColor = (status) => {
    if (status === 'Open') {
      return 'green';
    }
    else {
      return 'red';
    }
  }

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const displayModal = (uid) => {
    setTicketUID(uid);
    setShowModal(true);
  };

  const displayModalCancel = () => {
    setShowModal(false);
    setTicketUID(null);
  };

  const getPurchaseName = (uid) => {
    if (uid !== null) {
      try{
        const purchase = purchaseGroup.find((pg) => pg.uid === uid);
        return purchase.name;
      }
      catch (error){
        return 'Unassign';
      }
    }
    else {
      return 'Unassign';
    }
  }

  return (
    <div>
      <h2>Ticket</h2>
      <Row>
        <Col span={2}>
          <Select value={selectedStatus} onChange={handleStatusChange}>
            <Select.Option value="Open">Open</Select.Option>
            <Select.Option value="Close">Close</Select.Option>
          </Select>
        </Col>
        <Col span={3}>
          <Select
            placeholder="Purchase Group"
            onSelect={handlePurchaseGroupChange}
            onClear={handlePurchaseGroupClear}
            style={{ width: '90%' }}
            hidden={decodedToken.role.name !== "Admin" && decodedToken.role.name !== "Purchaser"}
            allowClear
            showSearch
          >
            {purchaseGroup.map((pg) => (
              <Select.Option key={pg.uid} value={pg.uid} label={pg.name}>
                {pg.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={16}>
        </Col>
        <Col span={3}>
          <TicketCreateButton refresh={fetchPaginatedTickets} />
        </Col>
      </Row>
      <List
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={ticketData}
        renderItem={(item) => (
          <Card size='small' style={{ 'margin': '10px' }}>
            <List.Item
              actions={[
                <Button size='small' hidden={decodedToken.role.name !== "Admin" && decodedToken.role.name !== "Purchaser"} type='primary' onClick={() => displayModal(item.uid)}>Assign</Button>,
                <Button size='small' type='primary' icon={<FilePptOutlined />}><Link to={`/ticket/${item.uid}`}>Post</Link></Button>,
              ]} //<Button size='small' type='primary' icon={<EditOutlined />}>Edit</Button>,
            >
              <Skeleton title={false} loading={item.loading} active>
                <List.Item.Meta
                  title={<p>
                    {decodedToken.role.name !== 'Admin' && decodedToken.role.name !== 'Purchaser'
                      ? <div><Tag color={getStatusColor(item.status)}>{item.status}</Tag> {item.number}</div>
                      : <div><Tag color={getStatusColor(item.status)}>{item.status}</Tag> {item.number} ({getPurchaseName(item.purchase_group_uid)})</div>
                    }
                  </p>}
                  description={<div>{item.topic} | Own by {item.owner_name} | Open by {decodedToken.role.name === item.open_by ? "Self" : item.open_by}</div>}
                />
              </Skeleton>
            </List.Item>
          </Card>
        )}
      />
      <Pagination style={{ display: 'flex', justifyContent: 'flex-end' }} defaultCurrent={currentPage} onChange={setCurrentPage} pageSize={5} total={totalTicketData}></Pagination>
      <Modal
        title="Assign purchase group"
        visible={showModal}
        onOk={displayModal}
        onCancel={displayModalCancel}
        footer={null}
      >
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item
            name="purchase_group_uid"
            label="Purchase Group"
            rules={[{ required: true, message: 'Please select freight forwarder' }]}
          >
            <Select>
              {purchaseGroup.length > 0 &&
                purchaseGroup.map(pg => (
                  <Select.Option key={pg.uid} value={pg.uid}>{pg.name} ({pg.purchase_group})</Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TicketPage;
